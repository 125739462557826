import React from 'react'

function Footer(props) {
    const styles = {
        div: {
            marginTop: '3px',
            width: '100%',
            height: '80px',
            border: '1px solid rgba(153, 153, 153, 0.3)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        circle: {
            border: '1px solid rgba(153, 153, 153, 0.3)',
            height: '44px',
            width: '44px',
            borderRadius: '100px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer'
        },
        numbers: {
            display: 'flex',
            marginLeft: '54px',
            marginRight: '54px'
        },
        paginatorItem: {
            marginLeft: '6px',
            marginRight: '6px',
            cursor: 'pointer',
            color: 'rgb(153, 153, 153)'
        }
    }
    return (
        <div style={styles.div}>
            <div style={styles.circle} onClick={() => {
                if (props.paginatorValue > 1) {
                    props.setPaginatorValue(props.paginatorValue - 1)
                }
            }}>
                <i className="fa-solid fa-angle-left" style={{ color: 'rgba(153, 153, 153, 0.3)', fontSize: '20px' }}></i>
            </div>

            <div style={styles.numbers}>
                {Array.from({ length: props.totalPaginatorNum }, (_, index) => index + 1).map((number) => (
                    <div
                        
                        style={styles.paginatorItem}
                        onClick={() => props.setPaginatorValue(number)}
                    >
                        <div style={{color: props.paginatorValue === number && '#B4307D'}}>{number}</div>
                    </div>
                ))}
            </div>

            <div style={styles.circle} onClick={() => {
                if (props.totalPaginatorNum > props.paginatorValue) {
                    props.setPaginatorValue(props.paginatorValue + 1)
                }
            }}>
                <i className="fa-solid fa-angle-right" style={{ color: 'rgba(153, 153, 153, 0.3)', fontSize: '20px' }}></i>
            </div>
        </div>
    )
}

export default Footer
