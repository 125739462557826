import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { updateUserSettings } from '../../actions/userActions'

import AccountSettingsComponent from '../../components/AccountSettingsComponent'
import AccountSettingsSubscriptionComponent from '../../components/AccountSettingsSubscriptionComponent'

import './AccountSettingsScreen.css'

function AccountSettingsScreen(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const userDetail = useSelector(state => state.userSubscriptionDetail)
  const { userSubscriptionDetail } = userDetail

  const [accountSettingsState, setAccountSettingsState] = useState(true)

  const [avatarImgInput, setAvatarImgInput] = useState()
  const [username, setUsername] = useState()
  const [email, setEmail] = useState()
  const [firstName, setFirstName] = useState()
  const [lastName, setLastName] = useState()
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [changePasswordRequest, setChangePasswordRequest] = useState(false)

  const [updateErrorMessage, setUpdateErrorMessage] = useState()

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('app') === 'subscription') {
      setAccountSettingsState(false)
    } else {
      setAccountSettingsState(true)
    }
  }, [searchParams])

  useEffect(() => {
      if (!userInfo) {
          navigate('/login')
      } else  {
          setUsername(userSubscriptionDetail && userSubscriptionDetail.user_data.username)
          setEmail(userSubscriptionDetail && userSubscriptionDetail.user_data.email)
          setFirstName(userSubscriptionDetail && userSubscriptionDetail.user_data.firstName)
          setLastName(userSubscriptionDetail && userSubscriptionDetail.user_data.lastName)
      }
  }, [userInfo, userSubscriptionDetail, navigate]);

  const handleSaveSettings = () => {
    if (changePasswordRequest && oldPassword.length === 0) {
      setUpdateErrorMessage('Enter current password')
    } else if (changePasswordRequest && newPassword.length === 0) {
      setUpdateErrorMessage('Enter new password')
    } else if (changePasswordRequest && newPassword !== confirmPassword) {
      setUpdateErrorMessage('New password and confirm password do not macth')
    } else {
      dispatch(updateUserSettings(
        userInfo.token,
        avatarImgInput,
        username,
        email,
        firstName,
        lastName,
        changePasswordRequest && oldPassword,
        changePasswordRequest && newPassword
      ))
    }
  }

  return (
    <div className='account-settings-container'>
      <div className='account-settings-navbar'>
        <div className='account-settings-navbar-title'>My Account</div>

        {accountSettingsState && (
          <div className='account-settings-navbar-btn'>
            <button>Cancel</button>
            <button onClick={handleSaveSettings}>Save</button>
          </div>
        )}
        
      </div>

      <div className='account-settings-body'>
        <div className='account-settings-body-container'>
          <div className='account-settings-body-navbar'>
            <div className='account-settings-body-navbar-btn' style={{color: accountSettingsState && '#B4307D', marginRight: '28px'}} onClick={() => navigate('/my-page/account-settings')}>Account Settings</div>
            <div className='account-settings-body-navbar-btn' style={{color: !accountSettingsState && '#B4307D'}} onClick={() => navigate('/my-page/account-settings?app=subscription')}>Manage Subscription</div>
          </div>

          {accountSettingsState ? (
            <AccountSettingsComponent
              username={username} setUsername={setUsername}
              avatar={(userInfo && userSubscriptionDetail) && userSubscriptionDetail.user_data.avatar}
              avatarImgInput={avatarImgInput} setAvatarImgInput={setAvatarImgInput}
              firstName={firstName} setFirstName={setFirstName}
              lastName={lastName} setLastName={setLastName}
              email={email} setEmail={setEmail}
              oldPassword={oldPassword} setOldPassword={setOldPassword}
              newPassword={newPassword} setNewPassword={setNewPassword}
              confirmPassword={confirmPassword} setConfirmPassword={setConfirmPassword}
              changePasswordRequest={changePasswordRequest} setChangePasswordRequest={setChangePasswordRequest}
              updateErrorMessage={updateErrorMessage}
            />
          ) : (
            <AccountSettingsSubscriptionComponent userSubscriptionDetail={userSubscriptionDetail} />
          )
          }
        </div>
      </div>

    </div>
  )
}

export default AccountSettingsScreen
