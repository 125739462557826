import axios from 'axios'
import { USER_LOGOUT } from '../constants/userConstants'

import {
    UPSCALING_GET_REQUEST,
    UPSCALING_GET_SUCCESS,
    UPSCALING_GET_FAIL,

    UPSCALING_POST_REQUEST,
    UPSCALING_POST_SUCCESS,
    UPSCALING_POST_FAIL,

    UPSCALING_DELETE_REQUEST,
    UPSCALING_DELETE_SUCCESS,
    UPSCALING_DELETE_FAIL,
} from '../constants/upscalingConstants'

export const upscalingHistory = (username, paginatorValue, itemsPerPage=10) => async (dispatch, getState) => {
    try {
        dispatch({
            type: UPSCALING_GET_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const formData = new FormData();
        formData.append('username', username);
        formData.append('itemsPerPage', itemsPerPage);
        formData.append('pageNumber', paginatorValue);

        const { data } = await axios.post(
            `upscaling/api/history/`,
            formData,
            {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${userInfo.token}`
                }
            }
        )

        dispatch({
            type: UPSCALING_GET_SUCCESS,
            payload: data
        })

    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('userInfo')
            dispatch({type: USER_LOGOUT})
        } else {
            dispatch({
                type: UPSCALING_GET_FAIL,
                payload: error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
            })
        }
    }
}

export const upscalingGeneration = (
    username,
    input_image,
    model_type,
    paginatorValue,
    itemsPerPage
) => async (dispatch, getState) => {
    try {
        dispatch({
            type: UPSCALING_POST_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data; charset=utf-8',
                Authorization: `Bearer ${userInfo.token}`
            }
        }
        var currentdate = new Date();

        const formData = new FormData();
        formData.append('username', username);
        formData.append('input_image', input_image);
        formData.append('date', currentdate / 1000);
        formData.append('model_type', model_type);

        const { data } = await axios.post(
            `upscaling/api/generate/`,
            formData,
            config
        )

        dispatch({
            type: UPSCALING_POST_SUCCESS
        })

        dispatch(upscalingHistory(username, paginatorValue, itemsPerPage))

    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('userInfo')
            dispatch({type: USER_LOGOUT})
        } else {
            dispatch({
                type: UPSCALING_POST_FAIL,
                payload: error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
            })
        }
    }
}

export const upscalingDelete = (api_request, image_id, username, paginatorValue, itemsPerPage) => async (dispatch, getState) => {
    try {
        dispatch({
            type: UPSCALING_DELETE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const { data } = await axios.delete(
            api_request,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`
                },
                data: {
                    'image_id': image_id,
                }
            }
        )

        dispatch({
            type: UPSCALING_DELETE_SUCCESS
        })

        dispatch(upscalingHistory(username, paginatorValue, itemsPerPage))

    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('userInfo')
            dispatch({type: USER_LOGOUT})
        } else {
            dispatch({
                type: UPSCALING_DELETE_FAIL,
                payload: error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
            })
        }
    }
}