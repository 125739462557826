import React from 'react'
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import logoSmall from '../../images/logo-small.jpg'
import closeBtnImg from '../../images/close-btn.svg'

function PrivacyScreen() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    return (
        <div>
            <div className='gnrt-img-navbar'>
                <img src={logoSmall} alt='logo' />
                <div className='gnrt-img-navbar-title' style={{marginLeft: '10px'}}>
                    Privacy Policy
                </div>

                <div className='gnrt-img-navbar-close'>
                    <img src={closeBtnImg} alt='' onClick={() => navigate(-1)}/>
                </div>
            </div>

            <div style={{marginTop: '30px', marginLeft: '20px', marginRight: '20px', fontSize: '14px', lineHeight: '26px'}}>
                <div>{parse(t('privacy_article_1'))}</div>
                <div>{parse(t('privacy_article_2'))}</div>
                <div>{parse(t('privacy_article_5'))}</div>
                <div>{parse(t('privacy_article_6'))}</div>
                <div>{parse(t('privacy_article_7'))}</div>
                <div>{parse(t('privacy_article_8'))}</div>
                <div>{parse(t('privacy_article_9'))}</div>
                <div>{parse(t('privacy_article_10'))}</div>
                <div>{parse(t('privacy_article_11'))}</div>
                <div>{parse(t('privacy_article_12'))}</div>
                <div>{parse(t('privacy_article_13'))}</div>
            </div>
        </div>
    )
}

export default PrivacyScreen
