import React, { useEffect, useState } from 'react'
import SidebarItem from './SidebarItem'
import { Outlet, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getUserSubscriptionDetail } from '../actions/userActions'

import AccountPopup from './AccountPopup'

import profileImg from '../images/profile.svg'
import dandycvLogoImg from '../images/dandycv-logo.png'

import myPageSidebarHomeImg from '../images/my-page-sidebar-home.svg'
import myPageSidebarToolsImg from '../images/my-page-sidebar-tools.svg'
import myPageSidebarArrowImg from '../images/my-page-sidebar-arrow.svg'
import myPageSidebarAdminImg from '../images/admin/admin-sidebar.svg'

import './Sidebar.css'

function Sidebar() {
    const [isOpen, setIsOpen] = useState(false);
    const [accountSettingsPopup, setAccountSettingsPopup] = useState(false)
    const toggle = () => setIsOpen(!isOpen);

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userDetail = useSelector(state => state.userSubscriptionDetail)
    const { userSubscriptionDetail } = userDetail


    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        showSidebar();
    }, []);

    useEffect(() => {
        if (!userInfo) {
            navigate('/login', {replace: true})
        } else {
            dispatch(getUserSubscriptionDetail(userInfo.token))
        }
    }, [userInfo, navigate, dispatch]);

    const showSidebar = () => {
        if (window.innerWidth <= 1100) {
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
    }

    window.addEventListener('resize', showSidebar)

    const menuItem = [
        {
            parent: 'Home',
            src: myPageSidebarHomeImg,
            path: '/my-page',
        },
        {
            parent: 'Tools',
            src: myPageSidebarToolsImg,        
            child: [
                {
                    name: 'Image data',
                    src: myPageSidebarArrowImg,
                    children: [
                        {
                            name: 'Rbg & Composite',
                            path: '/my-page/remove-background'
                        },
                        {
                            name: 'Generate images',
                            path: '/my-page/txt2img'
                        },
                        {
                            name: 'Edit images',
                            path: '/my-page/img2img'
                        },
                        {
                            name: 'Quality improvement',
                            path: '/my-page/upscaling'
                        },
                        {
                            name: 'Multiple requests',
                            path: '/my-page/multi-prompting'
                        },
                    ]
                },
                {
                    name: 'Video data',
                    src: myPageSidebarArrowImg,
                    children: [
                        {
                            name: 'Generate videos',
                            path: '/my-page'
                        }
                    ]
                }
            ],
        },
        userInfo && (
            userInfo.isAdmin && {
                parent: 'Admin Page',
                src: myPageSidebarAdminImg,
                child: [
                    {
                        name: 'Posts',
                        src: myPageSidebarArrowImg,
                        children: [
                            {
                                name: 'Edit',
                                path: '/admin-posts'
                            }
                        ]
                    }
                ]
            }
        )
    ]

    return (
        <div>
            <div>
                <div className='top-section'>
                    <i className='fas fa-bars' onClick={toggle} style={{fontSize: '32px'}}></i>
                </div>
                <div className='low-section'>
                    <div style={{display: isOpen ? '' : 'none'}} className='sidebar'>
                        <div className='sidebar-container' style={{marginBottom: '30px'}}>
                            <div className='sidebar-profile'>
                                <div className='sidebar-profile-info'>
                                    <img src={(userSubscriptionDetail && userInfo && userSubscriptionDetail.user_data.avatar)? `https://www.dandycv.com/media/${userSubscriptionDetail.user_data.avatar}` : profileImg} alt='profileImg' style={{width: '50px', height: '50px', borderRadius: '100px'}}/>
                                    <div className='sidebar-login'>
                                        <div className='sidebar-profile-username'>
                                            Hi, { userSubscriptionDetail && userInfo && userSubscriptionDetail.user_data.username }
                                        </div>
                                        {userSubscriptionDetail && userInfo &&
                                            <div className='sidebar-profile-email'>
                                                {userSubscriptionDetail.user_data.subscriptionDetail.credits}&copy;
                                            </div>
                                        }
                                    </div>
                                </div>
                                

                                
                                <div className='sidebar-settings-div' onClick={() => setAccountSettingsPopup(!accountSettingsPopup)}>
                                    Settings
                                </div>
                            </div>


                            <AccountPopup trigger={accountSettingsPopup} setTrigger={setAccountSettingsPopup}
                            />

                            <div className='sidebar-navs'>
                                {
                                    menuItem.map((item, index) => <SidebarItem key={index} item={item} />)
                                }
                            </div>

                            <div className='sidebar-navs-logo'>
                                <img src={dandycvLogoImg} alt='logo' style={{width: '148px', height: '32px'}}/>
                            </div>
                        </div>

                        {/* <div className='sidebar-info'>
                            <button className='sidebar-info-price-btn'>
                                View Price
                            </button>
                        </div> */}

                    </div>

                    <div className={isOpen ? 'sidebar-main open' : 'sidebar-main'}><Outlet /></div>
                </div>

                <div className='overlay' style={{display: isOpen ? 'block' : 'none'}} onClick={toggle}></div>
            </div>

        </div>
    )
}

export default Sidebar
