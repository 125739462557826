import axios from 'axios'
import { 
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,

    USER_LOGOUT,

    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,

    USER_SUBSCRIPTION_DETAIL_REQUEST,
    USER_SUBSCRIPTION_DETAIL_SUCCESS,
    USER_SUBSCRIPTION_DETAIL_FAIL,

    USER_UPDATE_DETAIL_REQUEST,
    USER_UPDATE_DETAIL_SUCCESS,
    USER_UPDATE_DETAIL_FAIL,

} from '../constants/userConstants'

export const login = (username, password) => async (dispatch) => {
    try {
        dispatch({
            type: USER_LOGIN_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const { data } = await axios.post(
            '/base/api/users/login/',
            { 'username': username, 'password': password },
            config
        )

        dispatch({
            type: USER_LOGIN_SUCCESS,
            payload: data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: USER_LOGIN_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const logout = () => (dispatch) => {
    localStorage.removeItem('userInfo')
    dispatch({type: USER_LOGOUT})
}

export const register = (email, password, username, firstName, lastName, organization=null) => async (dispatch) => {
    try {
        dispatch({
            type: USER_REGISTER_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const { data } = await axios.post(
            '/base/api/users/register/',
            {
                'email': email,
                'password': password,
                'username': username,
                'firstName': firstName,
                'lastName': lastName,
                'organization': organization,
            },
            config
        )

        dispatch({
            type: USER_REGISTER_SUCCESS,
            payload: data
        })

        // localStorage.setItem('userInfo', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: USER_REGISTER_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const getUserSubscriptionDetail = (userToken) => async (dispatch) => {
    try {
        dispatch({
            type: USER_SUBSCRIPTION_DETAIL_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userToken}`
            }
        }

        const { data } = await axios.get(
            `base/api/users/profile/settings/`,
            config
        )

        dispatch({
            type: USER_SUBSCRIPTION_DETAIL_SUCCESS,
            payload: data
        })

        // localStorage.setItem('userInfo', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: USER_SUBSCRIPTION_DETAIL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const updateUserSettings = (userToken, avatarImg, username, email, firstName, lastName, oldPassword, newPassword) => async (dispatch) => {
    try {
        dispatch({
            type: USER_UPDATE_DETAIL_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userToken}`
            }
        }        

        const formData = new FormData();
        formData.append('username', username);

        if (email) {
            formData.append('email', email)
        }

        if (avatarImg) {
            formData.append('avatar_image', avatarImg);
        }

        if (oldPassword && newPassword) {
            formData.append('old_password', oldPassword);
            formData.append('new_password', newPassword);
        }

        formData.append('first_name', firstName);
        formData.append('last_name', lastName);

        const { data } = await axios.post(
            `base/api/users/update/`,
            formData,
            config
        )

        dispatch({
            type: USER_UPDATE_DETAIL_SUCCESS,
            payload: data
        })

        dispatch(getUserSubscriptionDetail(userToken))

    } catch (error) {
        dispatch({
            type: USER_UPDATE_DETAIL_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}