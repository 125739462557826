import React, { useRef, useState } from 'react'

import img0 from '../images/generate-images/img0.jpg'
import img1 from '../images/generate-images/img1.jpg'
import img2 from '../images/generate-images/img2.jpg'
import img3 from '../images/generate-images/img3.jpg'
import img4 from '../images/generate-images/img4.jpg'
import img5 from '../images/generate-images/img5.jpg'
import img6 from '../images/generate-images/img6.jpg'
import img7 from '../images/generate-images/img7.jpg'
import img8 from '../images/generate-images/img8.jpg'
import img9 from '../images/generate-images/img9.jpg'
import img10 from '../images/generate-images/img10.jpg'
import img11 from '../images/generate-images/img11.jpg'
import img12 from '../images/generate-images/img12.jpg'
import img13 from '../images/generate-images/img13.jpg'
import img14 from '../images/generate-images/img14.jpg'
import img15 from '../images/generate-images/img15.jpg'
import img16 from '../images/generate-images/img16.jpg'
import img17 from '../images/generate-images/img17.jpg'

import addImg from '../images/img2img_add.jpg'
import closeBtnImg from '../images/close-btn.svg'

function ImageToImageSidebar(props) {
    const inputRef = useRef()

    const styles = [
        { value: img0, title: 'No Style' },
        { value: img1, title: 'Anime' },
        { value: img2, title: 'Photographic' },
        { value: img3, title: 'Digital Art' },
        { value: img4, title: 'Comic Book' },
        { value: img5, title: 'Fantasy Art' },
        { value: img6, title: 'Analog Film' },
        { value: img7, title: 'Neon Punk' },
        { value: img8, title: 'Isometric' },
        { value: img9, title: 'Low Poly' },
        { value: img10, title: 'Origami' },
        { value: img11, title: 'Line Art' },
        { value: img12, title: 'Cinematic' },
        { value: img13, title: '3D Model' },
        { value: img14, title: 'Pixel Art' },
        { value: img15, title: 'Enhance' },
        { value: img16, title: 'Tile Texture' },
        { value: img17, title: 'Compound' },
    ]

    const [promptOpen, setPromptOpen] = useState(true)
    const [negativePromptOpen, setNegativePromptOpen] = useState(true)
    const [inputImageOpen, setInputImageOpen] = useState(true)
    const [styleOpen, setStyleOpen] = useState(true)
    const [imageCountOpen, setImageCountOpen] = useState(true)
    const [CFGScaleOpen, setCFGScaleOpen] = useState(true)

    const [isDraggingOver, setDraggingOver] = useState(false);

    const [imageErrorMessage, setImageErrorMessage] = useState('')

    const handleCFGScaleChange = (event) => {
        var cfgRegex = /^[+\-]?[1-9][0-9]*$/;
        event.target.value = event.target.value.replace(/^0+/, '');
        if (event.target.value === '') {
            props.setCFGScale(0)
        } else if (cfgRegex.test(event.target.value)) {
            props.setCFGScale(event.target.value);
        }
    }

    const handleDragOver = (event) => {
        event.preventDefault();

        setDraggingOver(true);
    }

    const handleDragLeave = () => {
        setDraggingOver(false);
    };

    async function handleOnDrop(event, drop=false) {
        event.preventDefault();
        setImageErrorMessage('')

        const file = drop ? event.target.files[0] : event.dataTransfer.files[0]

        var res = await getImageResolution(file)

        if ((res.height < 320 || res.height > 1536) || (res.width < 320 || res.width > 1536)) {
            setImageErrorMessage(`No dimension can be less than 320 or greater than 1536 (current ${res.width}*${res.height})`)
        } else {
            props.setImageInput(file)
        }

        setDraggingOver(false);
    }

    function getImageResolution(file) {
        return new Promise((resolve, reject) => {
            const image = new Image();

            image.onload = () => {
                const width = image.width;
                const height = image.height;
                resolve({width, height})
            }

            image.onerror = () => {
                reject(new Error('Failed to load the image'));
            };
    
            image.src = URL.createObjectURL(file);
        })
    }

    return (
        <>
            <div className={promptOpen ? 'gnrt-img-main-sidebar-item open' : 'gnrt-img-main-sidebar-item'}>
                <div className='gnrt-img-main-sidebar-item-title' onClick={() => setPromptOpen(!promptOpen)}>
                    <div>
                        <i className="fa-solid fa-chevron-down toggle-btn"></i>
                        Prompt (Essential)
                    </div>
                </div>

                {promptOpen && <textarea className='gnrt-img-main-sidebar-item-prompt' value={props.prompt} onChange={(e) => props.setPrompt(e.target.value)}/>}

            </div>

            <div className={negativePromptOpen ? 'gnrt-img-main-sidebar-item open' : 'gnrt-img-main-sidebar-item'}>
                <div className='gnrt-img-main-sidebar-item-title' onClick={() => setNegativePromptOpen(!negativePromptOpen)}>
                    <div>
                        <i className="fa-solid fa-chevron-down toggle-btn"></i>
                        Negative prompt (Optional)
                    </div>
                </div>
                
                {negativePromptOpen && <textarea className='gnrt-img-main-sidebar-item-negative-prompt' value={props.negativePrompt} onChange={(e) => props.setNegativePrompt(e.target.value)} />}

            </div>

            <div className={inputImageOpen ? 'gnrt-img-main-sidebar-item open' : 'gnrt-img-main-sidebar-item'}>
                <div className='gnrt-img-main-sidebar-item-title' onClick={() => setInputImageOpen(!inputImageOpen)}>
                    <div>
                        <i className="fa-solid fa-chevron-down toggle-btn"></i>
                        Input image
                    </div>
                </div>
                
                {inputImageOpen && (
                    <>
                        {imageErrorMessage && <div style={{color: 'red', marginBottom: '10px'}}>{imageErrorMessage}</div>}
                        <div style={{
                                border: '1px solid rgba(153, 153, 153, 0.3)',
                                borderColor: isDraggingOver ? '#B4307D' : 'rgba(153, 153, 153, 0.3)',
                                borderRadius: '5px',
                                height: '132px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative'
                            }}
                            onDragOver={(e) => handleDragOver(e)}
                            onDragLeave={() => handleDragLeave()}
                            onDrop={(event) => handleOnDrop(event)}
                        >
                            <input
                                type='file'
                                onChange={(event) => handleOnDrop(event, true)}
                                hidden
                                accept="image/png, image/jpeg"
                                ref={inputRef}
                            />
                            {props.imageInput ? (
                                <>
                                    <img src={closeBtnImg} alt='' style={{position: 'absolute', right: '0', top: '0', margin: '5px', cursor: 'pointer'}} onClick={() => props.setImageInput()}/>
                                    <img src={URL.createObjectURL(props.imageInput)} alt='' style={{maxHeight: '132px', maxWidth: '100%', opacity: props.imageStrength}} />
                                </>
                            ) : (
                                <>
                                    <img src={addImg} alt='' style={{height: '20px', width: '20px', cursor: 'pointer'}} onClick={() => inputRef.current.click()}/>
                                    <div style={{marginTop: '3px'}}>Upload an image</div>
                                </>
                            )}
                            
                            
                        </div>
                        <div className='gnrt-img-main-sidebar-item-title' style={{width: '100%', marginTop: '20px'}} onClick={() => setImageCountOpen(!imageCountOpen)}>
                            <div>
                                Strength
                            </div>

                            {props.imageStrength}
                        </div>
                        <div className='gnrt-img-main-sidebar-image-count'>
                            <input type='range' min={'0'} max={'1'} step={'0.01'} value={props.imageStrength} onChange={(e) => props.setImageStrength(e.target.value)} />
                        </div>
                    </>
                )}

            </div>

            <div className={styleOpen ? 'gnrt-img-main-sidebar-item open' : 'gnrt-img-main-sidebar-item'}>
                <div className='gnrt-img-main-sidebar-item-title'  onClick={() => setStyleOpen(!styleOpen)}>
                    <div>
                        <i className="fa-solid fa-chevron-down toggle-btn"></i>
                        Style
                    </div>
                </div>

                {styleOpen &&
                    <div className='gnrt-img-main-sidebar-styles'>
                        {styles.map((style, idx) => (
                            <div className='gnrt-img-main-sidebar-styles-item'>
                                {idx === 0 ? (
                                    <div className='gnrt-img-main-sidebar-styles-item-img-initial' onClick={() => props.setSelectedStyle(idx)} style={{border: props.selectedStyle === idx && '1px solid #B4307D'}}>
                                        <img src={style.value} alt='' style={{width: '30px', height: '30px'}}/>
                                    </div>
                                ) : (
                                    <div className='gnrt-img-main-sidebar-styles-item-img' onClick={() => props.setSelectedStyle(idx)}>
                                        <img src={style.value} alt='' style={{border: props.selectedStyle === idx && '1px solid #B4307D'}}/>
                                    </div>
                                )}
                                
                                <div style={{color: props.selectedStyle === idx && '#B4307D', fontWeight: props.selectedStyle === idx && 'bold'}}>
                                    {style.title}
                                </div>
                            </div>
                        ))}
                    </div>
                }

            </div>

            <div className={imageCountOpen ? 'gnrt-img-main-sidebar-item open' : 'gnrt-img-main-sidebar-item'}>
                <div className='gnrt-img-main-sidebar-item-title' style={{width: '100%'}} onClick={() => setImageCountOpen(!imageCountOpen)}>
                    <div>
                        <i className="fa-solid fa-chevron-down toggle-btn"></i>
                        Image count
                    </div>

                    {props.imageCount}
                </div>

                {imageCountOpen &&
                    <div className='gnrt-img-main-sidebar-image-count'>
                        <input type='range' min={'1'} max={'4'} step={'1'} value={props.imageCount} onChange={(e) => props.setImageCount(e.target.value)} />
                    </div>
                }
            </div>

            <div className={CFGScaleOpen ? 'gnrt-img-main-sidebar-item open' : 'gnrt-img-main-sidebar-item'}>
                <div className='gnrt-img-main-sidebar-item-title' onClick={() => setCFGScaleOpen(!CFGScaleOpen)}>
                    <div>
                        <i className="fa-solid fa-chevron-down toggle-btn"></i>
                        CFG scale
                    </div>
                </div>

                {CFGScaleOpen && (
                    <textarea className='gnrt-img-main-sidebar-item-negative-prompt' style={{paddingTop: '8px', paddingBottom: '9px', height: '16px'}} value={props.CFGScale} onChange={handleCFGScaleChange} />
                )}

            </div>

        </>
    )
}

export default ImageToImageSidebar
