import React, { useState } from 'react'

import closeBtnImg from '../images/close-btn.svg'

function DownloadPopup(props) {

    const [increaseResolution, setIncreaseResolution] = useState(false)
    const [categoryJPG, setCategoryJPG] = useState(true)

    const donwloadHandler = () => {
        fetch('https://www.dandycv.com/media/' + props.imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
            // Create a Blob object and generate a URL for it
            const url = window.URL.createObjectURL(blob);

            // Create an anchor element for downloading
            const a = document.createElement('a');
            a.href = url;
            a.download = props.imageFileName + (categoryJPG ? '.jpg' : '.png'); // Specify the desired file name
            document.body.appendChild(a);

            // Trigger a click event on the anchor element to initiate the download
            a.click();

            // Remove the anchor element from the DOM
            document.body.removeChild(a);
        });
    }

    return (props.trigger) ? (
        <div className='popup-color-picker-overlay' onClick={() => props.setTrigger(false)}>
                <div className='popup-downloader-content' onClick={(e) => e.stopPropagation()}>
                  <div className='popup-downloader-header'>
                    <div className="popup-downloader-title">Download</div>
    
                    <img src={closeBtnImg} alt='' style={{cursor: 'pointer'}} onClick={() => props.setTrigger(false)}/>
                  </div>
                  <div className='hr' style={{marginLeft: '16px', marginRight: '16px'}} />
    
                  <div style={{marginTop: '16px', marginLeft: '16px'}}>
                    Name
                  </div>
    
                  <input type='text' style={{marginTop: '10px', marginLeft: '16px'}} value={props.imageFileName} onChange={(e) => props.setImageFileName(e.target.value)}/>
    
                  <div className='popup-downloader-resolution'>
    
                    <div>
                      Increase resolution
                    </div>
    
                    <div style={{display: 'flex'}}>
                      <div className={!increaseResolution ? 'popup-downloader-selector-active' : 'popup-downloader-selector'} style={{marginRight: '5px'}} onClick={() => setIncreaseResolution(false)}>x1</div>
                      <div className={increaseResolution ? 'popup-downloader-selector-active' : 'popup-downloader-selector'}  onClick={() => setIncreaseResolution(true)}>x2</div>
                    </div>
    
                  </div>
    
                  <div className='popup-downloader-resolution'>
    
                    <div>
                      Category
                    </div>
    
                    <div style={{display: 'flex'}}>
                      <div className={categoryJPG ? 'popup-downloader-selector-active' : 'popup-downloader-selector'} style={{marginRight: '5px'}} onClick={() => setCategoryJPG(true)}>JPG</div>
                      <div className={!categoryJPG ? 'popup-downloader-selector-active' : 'popup-downloader-selector'} onClick={() => setCategoryJPG(false)}>PNG</div>
                    </div>
    
                  </div>
    
                  <button>
                    Free (Low resolution)
                  </button>
    
                  <button style={{marginTop: '8px'}} onClick={() => donwloadHandler()}>
                    Original
                  </button>
    
                    
                    
                </div>
            </div>
      ) : "";
}

export default DownloadPopup
