import React from 'react'
import axios from 'axios'

function RemoveBackgroundDeletePopup(props) {

    const handleDeleteAPI = async () => {
        try {
            const config = {
                headers: {
                    'Content-type': 'application/json',
                }
            }
    
            for (const id of props.selectedRows) {
              const api_request = `/remove_background/delete/${props.data[id].id}`
              const { res } = await axios.delete(
                api_request,
                config
              )
            }
    
            handleDelete();
            props.setSelectedRows([]);
            props.setSelectAll(false);
            props.setTrigger(false)
        } catch (error) {
            console.log(error)
        }
        
    }

    const handleDelete = () => {
        const filteredData = props.data.filter((item, idx) => !props.selectedRows.includes(idx))

        props.setData(filteredData);
    }

    return (props.trigger) ? (
        <div className='popup-color-picker-overlay' onClick={() => props.setTrigger(false)}>
          <div className='popup-deleter-content' onClick={(e) => e.stopPropagation()}>
            <div className='popup-downloader-header'>
              <div className="popup-downloader-title">Delete</div>

              <i className="fa-solid fa-x" style={{cursor: 'pointer'}} onClick={() => props.setTrigger(false)}></i>
            </div>
            <div className='hr' style={{marginLeft: '16px', marginRight: '16px'}} />

            <div className='popup-deleter-content-txt'>
              Are you sure you want to delete the selected images?
            </div>

            <div className='popup-deleter-buttons'>
              <button style={{border: '1px solid #B4307D', color: '#B4307D', marginRight: '10px'}} onClick={() => props.setTrigger(false)}>
                  Cancel
              </button>
  
              <button onClick={() => handleDeleteAPI()}>
                  OK
              </button>
            </div>  
          </div>
        </div>
    ) : "";
}

export default RemoveBackgroundDeletePopup
