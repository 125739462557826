import React, { useState } from 'react'

import img0 from '../../images/generate-images/img0.jpg'
import img1 from '../../images/generate-images/img1.jpg'
import img2 from '../../images/generate-images/img2.jpg'
import img3 from '../../images/generate-images/img3.jpg'
import img4 from '../../images/generate-images/img4.jpg'
import img5 from '../../images/generate-images/img5.jpg'
import img6 from '../../images/generate-images/img6.jpg'
import img7 from '../../images/generate-images/img7.jpg'
import img8 from '../../images/generate-images/img8.jpg'
import img9 from '../../images/generate-images/img9.jpg'
import img10 from '../../images/generate-images/img10.jpg'
import img11 from '../../images/generate-images/img11.jpg'
import img12 from '../../images/generate-images/img12.jpg'
import img13 from '../../images/generate-images/img13.jpg'
import img14 from '../../images/generate-images/img14.jpg'
import img15 from '../../images/generate-images/img15.jpg'
import img16 from '../../images/generate-images/img16.jpg'
import img17 from '../../images/generate-images/img17.jpg'

function PostsAdminSidebar(props) {
    const aspectRatios = {
            sdxl: [
                { ratio: '21:9', size: { width: 1536, height: 640 }},
                // { ratio: '16:9', size: { width: 1344, height: 768 }},
                // { ratio: '3:2', size: { width: 1216, height: 832 }},
                { ratio: '5:4', size: { width: 1152, height: 896 }},
                { ratio: '4:5', size: { width: 896, height: 1152 }},
                // { ratio: '2:3', size: { width: 832, height: 1216 }},
                // { ratio: '9:16', size: { width: 768, height: 1344 }},
                { ratio: '9:21', size: { width: 640, height: 1536 }},
                { ratio: '1:1', size: { width: 1024, height: 1024 }},
                { ratio: '1:1', size: { width: 1536, height: 1536 }},
                { ratio: '3:2', size: { width: 2432, height: 1408 }},
                { ratio: '2:3', size: { width: 1408, height: 2432 }},
            ]
    }

    const styles = [
        { value: img0, title: 'No Style' },
        { value: img1, title: 'Anime' },
        { value: img2, title: 'Photographic' },
        { value: img3, title: 'Digital Art' },
        { value: img4, title: 'Comic Book' },
        { value: img5, title: 'Fantasy Art' },
        { value: img6, title: 'Analog Film' },
        { value: img7, title: 'Neon Punk' },
        { value: img8, title: 'Isometric' },
        { value: img9, title: 'Low Poly' },
        { value: img10, title: 'Origami' },
        { value: img11, title: 'Line Art' },
        { value: img12, title: 'Cinematic' },
        { value: img13, title: '3D Model' },
        { value: img14, title: 'Pixel Art' },
        { value: img15, title: 'Enhance' },
        { value: img16, title: 'Tile Texture' },
        { value: img17, title: 'Compound' },
    ]

    const [modelSelectionOpen, setModelSelectionOpen] = useState(true)
    const [promptOpen, setPromptOpen] = useState(true)
    const [negativePromptOpen, setNegativePromptOpen] = useState(true)
    const [styleOpen, setStyleOpen] = useState(true)
    const [aspectRatioOpen, setAspectRatioOpen] = useState(true)
    const [imageCountOpen, setImageCountOpen] = useState(true)
    const [iterationCountOpen, setIterationCountOpen] = useState(true)
    const [CFGScaleGeneratorOpen, setCFGScaleGeneratorOpen] = useState(true)
    const [CFGScaleDecoderOpen, setCFGScaleDecoderOpen] = useState(true)

    return (
        <>
            <div className={modelSelectionOpen ? 'gnrt-img-main-sidebar-item open' : 'gnrt-img-main-sidebar-item'}>
                <div className='gnrt-img-main-sidebar-item-title' onClick={() => setModelSelectionOpen(!modelSelectionOpen)}>
                    <div>
                        <i className="fa-solid fa-chevron-down toggle-btn"></i>
                        Model
                    </div>
                </div>

                {modelSelectionOpen && 
                    <div className='gnrt-img-main-sidebar-item-model-selection'>
                        <div
                            className='gnrt-img-main-sidebar-item-model-selection-button'
                            style={{borderColor: !props.turboSelected && '#B4307D', width: '100%'}}
                            onClick={() => {
                                props.setTurboSelected(false)
                                props.setImageCount(1)
                            }}
                        >
                            Stable Cascade
                        </div>
                        {/* <div
                            className='gnrt-img-main-sidebar-item-model-selection-button'
                            style={{marginLeft: '4px', borderColor: props.turboSelected && '#B4307D'}}
                            onClick={() => {
                                props.setSelectedAspectRatio(0)
                                props.setTurboSelected(true)
                                props.setImageCount(1)
                            }}
                        >
                            SDXL Turbo
                        </div> */}
                    </div>
                }

            </div>

            <div className={promptOpen ? 'gnrt-img-main-sidebar-item open' : 'gnrt-img-main-sidebar-item'}>
                <div className='gnrt-img-main-sidebar-item-title' onClick={() => setPromptOpen(!promptOpen)}>
                    <div>
                        <i className="fa-solid fa-chevron-down toggle-btn"></i>
                        Prompt (Essential)
                    </div>
                </div>

                {promptOpen && <textarea className='gnrt-img-main-sidebar-item-prompt' value={props.prompt} onChange={(e) => props.setPrompt(e.target.value)}/>}

            </div>

            {!props.turboSelected && 
                <div className={negativePromptOpen ? 'gnrt-img-main-sidebar-item open' : 'gnrt-img-main-sidebar-item'}>
                    <div className='gnrt-img-main-sidebar-item-title' onClick={() => setNegativePromptOpen(!negativePromptOpen)}>
                        <div>
                            <i className="fa-solid fa-chevron-down toggle-btn"></i>
                            Negative prompt (Optional)
                        </div>
                    </div>
                    
                    {negativePromptOpen && <textarea className='gnrt-img-main-sidebar-item-negative-prompt' value={props.negativePrompt} onChange={(e) => props.setNegativePrompt(e.target.value)} />}

                </div>
            }

            <div className={styleOpen ? 'gnrt-img-main-sidebar-item open' : 'gnrt-img-main-sidebar-item'}>
                <div className='gnrt-img-main-sidebar-item-title'  onClick={() => setStyleOpen(!styleOpen)}>
                    <div>
                        <i className="fa-solid fa-chevron-down toggle-btn"></i>
                        Style
                    </div>
                </div>

                {styleOpen &&
                    <div className='gnrt-img-main-sidebar-styles'>
                        {styles.map((style, idx) => (
                            <div className='gnrt-img-main-sidebar-styles-item'>
                                {idx === 0 ? (
                                    <div className='gnrt-img-main-sidebar-styles-item-img-initial' onClick={() => props.setSelectedStyle(idx)} style={{border: props.selectedStyle === idx && '1px solid #B4307D'}}>
                                        <img style={{width: '24px', height: '24px'}} src={style.value} alt=''/>
                                    </div>
                                ) : (
                                    <div className='gnrt-img-main-sidebar-styles-item-img' onClick={() => props.setSelectedStyle(idx)}>
                                        <img src={style.value} alt='' style={{border: props.selectedStyle === idx && '1px solid #B4307D'}}/>
                                    </div>
                                )}
                                
                                <div style={{color: props.selectedStyle === idx && '#B4307D', fontWeight: props.selectedStyle === idx && 'bold'}}>
                                    {style.title}
                                </div>
                            </div>
                        ))}
                    </div>
                }

            </div>

            <div className={aspectRatioOpen ? 'gnrt-img-main-sidebar-item open' : 'gnrt-img-main-sidebar-item'}>
                <div className='gnrt-img-main-sidebar-item-title'  onClick={() => setAspectRatioOpen(!aspectRatioOpen)}>
                    <div>
                        <i className="fa-solid fa-chevron-down toggle-btn"></i>
                        Aspect ratio
                    </div>
                </div>

                {aspectRatioOpen &&
                    <div className='gnrt-img-main-sidebar-aspect-ratio'>
                        {props.turboSelected ? aspectRatios.turbo.map((ratio, idx) => (
                            <div className='gnrt-img-main-sidebar-aspect-ratio-div' style={{borderColor: props.selectedAspectRatio === idx && '#B4307D'}} onClick={() => props.setSelectedAspectRatio(idx)} >
                                <div className='gnrt-img-main-sidebar-aspect-ratio-div-content'>
                                    <div style={{fontSize: '14px'}}>
                                        {ratio.ratio}
                                    </div>
                                    <div style={{fontSize: '12px'}}>
                                        {ratio.size.width}x{ratio.size.height}
                                    </div>
                                </div>
                                
                            </div>
                        )) : aspectRatios.sdxl.map((ratio, idx) => (
                            <div className='gnrt-img-main-sidebar-aspect-ratio-div' style={{borderColor: props.selectedAspectRatio === idx && '#B4307D'}} onClick={() => props.setSelectedAspectRatio(idx)} >
                                <div className='gnrt-img-main-sidebar-aspect-ratio-div-content'>
                                    <div style={{fontSize: '14px'}}>
                                        {ratio.ratio}
                                    </div>
                                    <div style={{fontSize: '12px'}}>
                                        {ratio.size.width}x{ratio.size.height}
                                    </div>
                                </div>
                                
                            </div>
                        ))
                        }
                    </div>
                }

            </div>

            <div className={imageCountOpen ? 'gnrt-img-main-sidebar-item open' : 'gnrt-img-main-sidebar-item'}>
                <div className='gnrt-img-main-sidebar-item-title' style={{width: '100%'}} onClick={() => setImageCountOpen(!imageCountOpen)}>
                    <div>
                        <i className="fa-solid fa-chevron-down toggle-btn"></i>
                        Image count
                    </div>

                    {props.imageCount}
                </div>

                {imageCountOpen &&
                    <div className='gnrt-img-main-sidebar-image-count'>
                        <input type='range' min={'1'} max={props.turboSelected ? '14' : '4'} step={'1'} value={props.imageCount} onChange={(e) => props.setImageCount(e.target.value)} />
                    </div>
                }
            </div>

            {props.turboSelected &&
                <div className={iterationCountOpen ? 'gnrt-img-main-sidebar-item open' : 'gnrt-img-main-sidebar-item'}>
                    <div className='gnrt-img-main-sidebar-item-title' style={{width: '100%'}} onClick={() => setIterationCountOpen(!iterationCountOpen)}>
                        <div>
                            <i className="fa-solid fa-chevron-down toggle-btn"></i>
                            Steps
                        </div>

                        {props.iterationCount}
                    </div>

                    {iterationCountOpen &&
                        <div className='gnrt-img-main-sidebar-image-count'>
                            <input type='range' min={'1'} max={'10'} step={'1'} value={props.iterationCount} onChange={(e) => props.setIterationCount(e.target.value)} />
                        </div>
                    }
                </div>
            }

            <div className={CFGScaleGeneratorOpen ? 'gnrt-img-main-sidebar-item open' : 'gnrt-img-main-sidebar-item'}>
                <div className='gnrt-img-main-sidebar-item-title' onClick={() => setCFGScaleGeneratorOpen(!CFGScaleGeneratorOpen)}>
                    <div>
                        <i className="fa-solid fa-chevron-down toggle-btn"></i>
                        CFG generator scale
                    </div>
                </div>

                {CFGScaleGeneratorOpen && (
                    <input  type='number' className='gnrt-img-main-sidebar-item-negative-prompt' style={{paddingTop: '8px', paddingBottom: '9px', height: '16px'}} step={0.1} value={props.CFGScaleGenerator} onChange={(e) => props.setCFGScaleGenerator(e.target.value)}/>
                )}

            </div>

            <div className={CFGScaleDecoderOpen ? 'gnrt-img-main-sidebar-item open' : 'gnrt-img-main-sidebar-item'}>
                <div className='gnrt-img-main-sidebar-item-title' onClick={() => setCFGScaleDecoderOpen(!CFGScaleDecoderOpen)}>
                    <div>
                        <i className="fa-solid fa-chevron-down toggle-btn"></i>
                        CFG decoder scale
                    </div>
                </div>

                {CFGScaleDecoderOpen && (
                    <input  type='number' className='gnrt-img-main-sidebar-item-negative-prompt' style={{paddingTop: '8px', paddingBottom: '9px', height: '16px'}} step={0.1} value={props.CFGScaleDecoder} onChange={(e) => props.setCFGScaleDecoder(e.target.value)}/>
                )}
            </div>
        </>
    )
}

export default PostsAdminSidebar

