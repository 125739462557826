import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

import './HomeScreen.css'
import HomeNavbar from '../../components/HomeNavbar'

import home_info_img_1 from '../../images/home-info-1.jpg'
import home_info_img_2 from '../../images/home-info-2.jpg'
import home_info_img_3 from '../../images/home-info-3.jpg'
import home_info_img_4 from '../../images/home-info-4.jpg'

import home_img_paginator_1 from '../../images/home-img-paginator-1.jpg'
import home_img_paginator_2 from '../../images/home-img-paginator-2.jpg'
import home_img_paginator_3 from '../../images/home-img-paginator-3.jpg'
import home_img_paginator_4 from '../../images/home-img-paginator-4.jpg'
import home_img_paginator_5 from '../../images/home-img-paginator-5.jpg'

import home_service_intro_img_1 from '../../images/home-service-intro-1.jpg'
// import home_service_intro_img_2 from '../images/home-service-intro-2.jpg'
import home_service_intro_vid_2 from '../../images/home-service-intro-2.gif'

import dandycv_logo from '../../images/dandycv-logo.png'

import main_video from '../../videos/main_page.mp4'

import main_background_video_gif from '../../images/main_page_background.gif'
import main_background_video_mobile_gif from '../../images/main_page_background_video.gif'

import downloadWinImg from '../../images/download-windows-logo.svg'
import downloadMacImg from '../../images/download-apple-logo.svg'

function HomeScreen() {
    const currentLanguageCode = Cookies.get('i18next') || 'en';
    const { t } = useTranslation();

    const [mobileState, setMobileState] = useState(false)

    const navigate = useNavigate();

    const handleDownload = async (mac) => {
        try {
            const a = document.createElement('a');
            a.href = mac ? 'https://www.dandycv.com/media/downloaders/remove_background/dandycv-0.1.0.dmg' : 'https://www.dandycv.com/media/downloaders/remove_background/dandycv-0.1.0.exe';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const setWindowWidth = () => {
        if (window.innerWidth <= 1100) {
            setMobileState(true)
        } else {
            setMobileState(false)
        }
    }

    useEffect(() => {
        setWindowWidth();
    }, [setMobileState]);

    window.addEventListener('resize', setWindowWidth)

    return (
        <div style={{overflow: 'hidden'}}>
            <div className='home-div1'>
                {/* <video autoplay muted loop>
                    <source src={mobileState ? main_background_video_mobile : main_background_video} type="video/mp4" />
                    Your browser does not support the video tag.
                </video> */}
                
                <img src={mobileState ? main_background_video_mobile_gif : main_background_video_gif} alt='' className='home-div1-background' />

                <HomeNavbar />

                <div className='home-container'>
                    <div className='home-div1-txt1'>
                        {parse(t('home_welcome_message'))}
                    </div>
                    <div className='home-div1-txt2'>
                        {mobileState ? parse(t('home_welcome_info_mobile')) : parse(t('home_welcome_info'))}
                    </div>
                </div>
            </div>

            {/* <div className='home-div2'>
                <div className='home-div2-grids' onClick={() => navigate('/remove-background')}>
                    <div className='home-div2-grids-wrapper'>
                        <div>Tools</div>
                        <div className='home-div2-grids-wrapper-title'>Image data</div>
                        <span>{parse(t('home_div2_nav1'))}</span>
                    </div>
                </div>
                <div className='home-div2-grids' onClick={() => navigate('/generate-images')}>
                    <div className='home-div2-grids-wrapper'>
                        <div>Tools</div>
                        <div className='home-div2-grids-wrapper-title'>Image data</div>
                        <span>{parse(t('home_div2_nav2'))}</span>
                    </div>
                </div>
                <div className='home-div2-grids' onClick={() => navigate('/posts')}>
                    <div className='home-div2-grids-wrapper'>
                        <div>Posts</div>
                        <div className='home-div2-grids-wrapper-title'>AI content</div>
                        <span>{parse(t('home_div2_nav3'))}</span>
                    </div>
                </div>
            </div> */}

            <div className='home-body'>
                <div className='home-body-container'>

                    <div className='home-body-container-intro'>
                        <div>{parse(t('home_body_container_intro'))}</div>
                        <button onClick={() => navigate('/my-page')}>
                            {parse(t('home_body_container_intro_btn'))} <i class="fa-solid fa-arrow-right" style={{marginLeft: '20px'}}></i>
                        </button>
                    </div>
                </div>
            </div>

            <div className='home-body' style={{background: 'rgba(153, 153, 153, 0.30)'}}>
                <div className='home-body-container'>
                    <div className='home-body-container-video-title'>
                        <div style={{fontSize: '30px', fontWeight: '700'}}>Tools</div>
                        <div className='home-body-container-video-title-vl'></div>
                        <div className='home-body-container-video-title-txt'>{parse(t('home_body_container_video_title_txt'))}</div>
                    </div>
                    <div className='home-body-container-video-title-body'>
                        {parse(t('home_body_container_video_title_body'))}
                    </div>
                    <div className='home-body-container-video-main'>
                        <video controls autoPlay muted loop playsInline>
                            <source src={main_video} type="video/mp4"/>
                        </video>
                    </div>
                </div>
            </div>

            
            <div className='home-body'>
                <div className='home-body-container'>
                    <div className='home-body-container-download'>
                        <div>
                            {parse(t('home_body_container_download_txt'))}
                        </div>

                        {!mobileState && 
                            <div className='home-body-container-download-btn'>
                                <button onClick={() => handleDownload(false)} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <img src={downloadWinImg} alt='' style={{marginRight: '10px'}}/>
                                    <div>Windows</div>
                                </button>
                                <button style={{marginLeft: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => handleDownload(true)}>
                                    <img src={downloadMacImg} alt='' style={{marginRight: '10px', marginTop: '-4px'}}/>
                                    <div>MacOS</div>
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div className='home-body'>
                <div className='home-body-hr' style={{marginLeft: mobileState && '30px', marginRight: mobileState && '30px'}}></div>
            </div>

            <div className='home-body'>
                <div className='home-body-container'>
                    <div className='home-body-container-grids-main'>
                        <div className='home-body-container-grids-main-title'>
                            dandycv A.I.
                        </div>
                        <div className='home-body-container-grids-main-body'>
                            {parse(t('home_body_container_grids_main_body'))}
                        </div>
                        
                        <div className='home-body-container-info-grids'>
                            <div className='home-body-container-info-grids-fill'>
                                <img src={home_info_img_1} alt='' />
                                <div className='home-body-container-info-grids-title'>
                                    {parse(t('home_body_container_info_grids_title_1'))}
                                </div>
                                <div className='home-body-container-info-grids-body-txt'>
                                    {parse(t('home_body_container_info_grids_body_txt_1'))}
                                </div>
                            </div>
                            <div className='home-body-container-info-grids-fill'>
                                <img src={home_info_img_2} alt='' />
                                <div className='home-body-container-info-grids-title'>
                                    {parse(t('home_body_container_info_grids_title_2'))}
                                </div>
                                <div className='home-body-container-info-grids-body-txt'>
                                    {parse(t('home_body_container_info_grids_body_txt_2'))}
                                </div>
                            </div>
                            <div className='home-body-container-info-grids-fill'>
                                <img src={home_info_img_3} alt='' />
                                <div className='home-body-container-info-grids-title'>
                                    {parse(t('home_body_container_info_grids_title_3'))}
                                </div>
                                <div className='home-body-container-info-grids-body-txt'>
                                    {parse(t('home_body_container_info_grids_body_txt_3'))}
                                </div>
                            </div>
                            <div className='home-body-container-info-grids-fill'>
                                <img src={home_info_img_4} alt='' />
                                <div className='home-body-container-info-grids-title'>
                                    {parse(t('home_body_container_info_grids_title_4'))}
                                </div>
                                <div className='home-body-container-info-grids-body-txt'>
                                    {parse(t('home_body_container_info_grids_body_txt_4'))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='home-body' style={{background: 'rgba(153, 153, 153, 0.10)', marginTop: '170px', flexDirection: 'column'}}>
                    <div className='home-body-img-paginator'>
                        <img src={home_img_paginator_1} alt=''></img>
                        <img src={home_img_paginator_2} alt=''></img>
                        <img src={home_img_paginator_3} alt=''></img>
                        <img src={home_img_paginator_4} alt='' className='home-body-img-paginator-hide'></img>
                        <img src={home_img_paginator_5} alt=''></img>
                    </div>
                    <div className='home-body'>
                        <div className='home-body-container'>
                            <div className='home-body-container-info-iter'>
                                <div className='home-body-container-info-iter-title'>
                                    {parse(t('home_body_container_info_iter_title'))}
                                </div>
                                <div className='home-body-container-info-iter-body'>
                                <div style={{display: 'flex'}}>
                                        <div>
                                            <li></li>
                                        </div>
                                        <div>
                                            {parse(t('home_body_container_info_iter_body_1'))}
                                        </div>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <div>
                                            <li></li>
                                        </div>
                                        <div>
                                            {parse(t('home_body_container_info_iter_body_2'))}
                                        </div>
                                    </div>
                                    <div style={{display: 'flex'}}>
                                        <div>
                                            <li></li>
                                        </div>
                                        <div>
                                            {parse(t('home_body_container_info_iter_body_3'))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>

            <div className='home-body'>
                <div className='home-body-container'>
                    <div className='home-body-container-service-intro'>
                        <div className='home-body-container-service-intro-title-1'>
                            {parse(t('home_body_container_service_intro_title_1'))}
                        </div>
                        <div className='home-body-container-service-intro-title-2'>
                            {parse(t('home_body_container_service_intro_title_2'))}
                        </div>

                        <div className='home-body-container-info-grids'>
                            <div className='home-body-container-info-grids-fill'>
                                <img src={home_service_intro_img_1} alt='' />
                                <div className='home-body-container-info-grids-fill-img-txt'>
                                    <div className='home-body-container-info-grids-fill-img-txt-title'>
                                        {parse(t('home_body_container_info_grids_fill_img_txt_title_1'))}
                                    </div>
                                    <div className='home-body-container-info-grids-fill-img-txt-body'>
                                        {parse(t('home_body_container_info_grids_fill_img_txt_body_1'))}
                                    </div>
                                </div>
                            </div>

                            <div className='home-body-container-info-grids-fill'>
                                <img src={home_service_intro_vid_2} alt='' />

                                <div className='home-body-container-info-grids-fill-img-txt'>
                                    <div className='home-body-container-info-grids-fill-img-txt-title'>
                                        {parse(t('home_body_container_info_grids_fill_img_txt_title_2'))}
                                    </div>
                                    <div className='home-body-container-info-grids-fill-img-txt-body'>
                                        {parse(t('home_body_container_info_grids_fill_img_txt_body_2'))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

            <div className='home-footer'>
                <div className='home-footer-img-container'>
                    <div className='home-footer-img-container-txt'>
                        {parse(t('home_footer_img_container_title'))}
                    </div>
                </div>

                <div className='home-footer-info-container'>

                    <div className='home-footer-container'>
                        <div>
                            <img src={dandycv_logo} alt='' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth'})}/>
                        </div>

                        <div className='home-footer-info-container-txt'>
                            <div> {parse(t('home_footer_info_container_txt_1'))} </div>
                            <div> {parse(t('home_footer_info_container_txt_2'))} </div>
                            <div> {parse(t('home_footer_info_container_txt_3'))} </div>
                            <div> {parse(t('home_footer_info_container_txt_4'))} </div>
                            <div>
                                {currentLanguageCode === 'en' ? (
                                    <><span style={{cursor: 'pointer'}} onClick={() => navigate('/term-of-service')}>Terms</span> | <span style={{cursor: 'pointer'}} onClick={() => navigate('/privacy-policy')}>Privacy</span></>
                                ) : (
                                    <><span style={{cursor: 'pointer'}} onClick={() => navigate('/term-of-service')}>이용약관</span> | <span style={{cursor: 'pointer'}} onClick={() => navigate('/privacy-policy')}>개인정보</span></>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default HomeScreen
