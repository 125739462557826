import {
    MULTI_PROMPTING_GET_REQUEST,
    MULTI_PROMPTING_GET_SUCCESS,
    MULTI_PROMPTING_GET_FAIL,

    MULTI_PROMPTING_POST_REQUEST,
    MULTI_PROMPTING_POST_SUCCESS,
    MULTI_PROMPTING_POST_FAIL,

    MULTI_PROMPTING_DELETE_REQUEST,
    MULTI_PROMPTING_DELETE_SUCCESS,
    MULTI_PROMPTING_DELETE_FAIL,
} from '../constants/multiPromptingConstants'

export const getMultiPromptingDataReducers = (state = { multiPromptingData: [] }, action) => {
    switch (action.type) {
        case MULTI_PROMPTING_GET_REQUEST:
            return { ...state, loading: true }

        case MULTI_PROMPTING_GET_SUCCESS:
            return { ...state, loading: false, multiPromptingData: action.payload.data }

        case MULTI_PROMPTING_GET_FAIL:
            return { ...state, loading: false, error: action.payload }

        case MULTI_PROMPTING_POST_REQUEST:
            return { ...state, loading: true }

        case MULTI_PROMPTING_POST_SUCCESS:
            return { ...state, loading: false }

        case MULTI_PROMPTING_POST_FAIL:
            return { ...state, loading: false, error: action.payload }

        case MULTI_PROMPTING_DELETE_REQUEST:
            return { ...state, loading: true}

        case MULTI_PROMPTING_DELETE_SUCCESS:
            return { ...state, loading: false }

        case MULTI_PROMPTING_DELETE_FAIL:
            return { ...state, loading: false, error: action.payload }

        default:
            return state
    }
}