import {
    TXT_2_IMG_GET_REQUEST,
    TXT_2_IMG_GET_SUCCESS,
    TXT_2_IMG_GET_FAIL,

    TXT_2_IMG_POST_REQUEST,
    TXT_2_IMG_POST_SUCCESS,
    TXT_2_IMG_POST_FAIL,

    TXT_2_IMG_DELETE_REQUEST,
    TXT_2_IMG_DELETE_SUCCESS,
    TXT_2_IMG_DELETE_FAIL,
} from '../constants/txt2imgConstants'

export const getTxt2ImgDataReducers = (state = { txt2imgData: [] }, action) => {
    switch (action.type) {
        case TXT_2_IMG_GET_REQUEST:
            return { ...state, loading: true }

        case TXT_2_IMG_GET_SUCCESS:
            return { ...state, loading: false, txt2imgData: action.payload.data }

        case TXT_2_IMG_GET_FAIL:
            return { ...state, loading: false, error: action.payload }

        case TXT_2_IMG_POST_REQUEST:
            return { ...state, loading: true }

        case TXT_2_IMG_POST_SUCCESS:
            return { ...state, loading: false }

        case TXT_2_IMG_POST_FAIL:
            return { ...state, loading: false, error: action.payload }

        case TXT_2_IMG_DELETE_REQUEST:
            return { ...state, loading: true}

        case TXT_2_IMG_DELETE_SUCCESS:
            return { ...state, loading: false }

        case TXT_2_IMG_DELETE_FAIL:
            return { ...state, loading: false, error: action.payload }

        default:
            return state
    }
}