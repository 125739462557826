import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { getPostsData, searchPostData } from '../../actions/postActions'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser'
import Cookies from 'js-cookie'
import axios from 'axios'
import { getUserSubscriptionDetail } from '../../actions/userActions'

import './PostsScreen.css'
import HomeNavbar from '../../components/HomeNavbar'

import searchImg from '../../images/post-search-icon.svg'
import postEmptyImg from '../../images/post-empty.jpg'

import PostImageViewPopup from '../../components/PostImageViewPopup'
import Footer from '../../components/Footer'

function PostsScreen() {
    const { t } = useTranslation();
    const currentLanguageCode = Cookies.get('i18next') || 'en';
    const [imageViewerPopup, setImageViewerPopup] = useState(false)
    const [mobileFileViewer, setMobileFileViewer] = useState(false)
    const [paginatorValue, setPaginatorValue] = useState(1)
    const [totalPostImages, setTotalPostImages] = useState('')

    const [searchBook, setSearchBook] = useState('');
    const [searchChapter, setSearchChapter] = useState('');
    const [searchStartVerse, setSearchStartVerse] = useState('');
    const [searchEndVerse, setSearchEndVerse] = useState('');

    const displayTemplate = [
        '1', '21', '12', '1',
        '21', '22', '1',
        '1', '21',
        '12', '21', '1',
        '22', '12',
        '12', '21',
        '12', '21', '1',
        '1', '1', '22',
        '21', '12',
        '1', '1', '12',
        '22', '1', '21',
        '1',
        '1', '21', '12', '1',
        '21', '22', '1',
        '1', '21',
        '12', '21', '1',
        '22', '12',
        '12', '21',
        '12', '21', '1',
        '1', '1', '22',
        '21', '12',
        '1', '1', '12',
        '22', '1', '21',
        '1',
    ]

    const displayTemplateClassMap = {
        '1': '',
        '21': 'posts-gallery-v-stretch',
        '12': 'posts-gallery-h-stretch',
        '22': 'posts-gallery-big-stretch'
    }

    const [mobileState, setMobileState] = useState(false)
    const [searchFilterState, setSearchFilterState] = useState(false)

    // Image Popup states
    const [selectedPostId, setSelectedPostId] = useState(0)
    const [selectedPostViewContentPage, setSelectedPostViewContentPage] = useState(true)
    const [selectedFileId, setSelectedFileId] = useState(0)

    const setWindowWidth = () => {
        if (window.innerWidth <= 1100) {
            setMobileState(true)
            setMobileFileViewer(false)
        } else {
            setMobileState(false)
        }
    }

    useEffect(() => {
        setWindowWidth();
    }, [setMobileState]);

    useEffect(() => {
        const fetchData = async () =>{
            try {
              const { data: response } = await axios.get('admin_generations/api/posts/totalImages/');
              setTotalPostImages(response);
            } catch (error) {
              console.error(error.message);
            }
        }
        fetchData();
    }, [])

    window.addEventListener('resize', setWindowWidth)

    const dispatch = useDispatch()

    const getPostData = useSelector(state => state.getPostData)
    const { loading, error, postData } = getPostData

    const handlePostImageClick = (idx) => {
        setSelectedPostId(idx)
        setSelectedFileId(0)
        setImageViewerPopup(true)
        // navigate(`/posts/${postData[idx].id}`)
    }

    const handleSearchClick = () => {
        if (searchBook.length > 0 || searchChapter.length > 0 || searchStartVerse.length > 0 || searchEndVerse > 0) {
            dispatch(searchPostData(searchBook, searchChapter, searchStartVerse, searchEndVerse));
        } else {
            dispatch(getPostsData(paginatorValue, 66))
        }
    }

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    useEffect(() => {
        if (userInfo) {
            dispatch(getUserSubscriptionDetail(userInfo.token))
        }
    }, [dispatch, userInfo]);

    useEffect(() => {
        dispatch(getPostsData(paginatorValue, 66))
    }, [dispatch, paginatorValue])

    return (
        <div className='posts-container' onClick={() => searchFilterState && setSearchFilterState(false)}>

            <HomeNavbar posts={true}/>

            <div className='posts-info'>
                <div className='posts-info-title'>
                    {parse(t('posts_info_title'))}
                </div>

                <div className='posts-info-we-do'>
                    WHAT WE DO
                </div>

                <div className='posts-info-we-do-info'>
                    {mobileState ? parse(t('posts_info_we_do_info_mobile')) : parse(t('posts_info_we_do_info'))}
                </div>

                <div className='posts-info-we-do-info' style={{marginTop: '50px', fontSize: '25px'}}>{totalPostImages && totalPostImages.data + String('+')}</div>
            </div>

            <form className='posts-search-container' onSubmit={() => handleSearchClick()}>
                
                <div className='posts-search-container-textarea'>
                    <div className='posts-search-container-textarea-title'>{parse(t('posts_info_search_title_1'))}</div>
                    <input style={{borderRight: '1px solid #999'}} placeholder={parse(t('posts_info_search_placeholder_1'))} type='text' value={searchBook} onChange={(e) => setSearchBook(e.target.value)} />
                </div>
                <div className='posts-search-container-textarea'>
                    <div className='posts-search-container-textarea-title'>{parse(t('posts_info_search_title_2'))}</div>
                    <input style={{borderRight: '1px solid #999'}} placeholder={parse(t('posts_info_search_placeholder_2'))} type='number' min={1} value={searchChapter} onChange={(e) => setSearchChapter(e.target.value)} />
                </div>
                <div className='posts-search-container-textarea'>
                    <div className='posts-search-container-textarea-title'>{parse(t('posts_info_search_title_3'))}</div>
                    <input style={{borderRight: '1px solid #999'}} placeholder={parse(t('posts_info_search_placeholder_3'))} type='number' min={1} value={searchStartVerse} onChange={(e) => setSearchStartVerse(e.target.value)} />
                </div>
                <div className='posts-search-container-textarea'>
                    <div className='posts-search-container-textarea-title'>{parse(t('posts_info_search_title_4'))}</div>
                    <input style={{borderRight: '1px solid #999'}} placeholder={parse(t('posts_info_search_placeholder_4'))} type='number' min={1} value={searchEndVerse} onChange={(e) => setSearchEndVerse(e.target.value)} />
                </div>
                

                <div className='posts-search-container-btn' onClick={() => handleSearchClick()}>
                    <img src={searchImg} alt='' />
                </div>
                
            </form>
            {/* {console.log('sadfsadf', postData.data)} */}

            {loading ? (
                <div style={{height: '300px'}}>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '250px'}}>
                        <span class="loader"></span>
                    </div>
                </div>
            ) : (
                <>
                    {error ? (
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '500px'}}>
                            <img src={postEmptyImg} alt='' style={{width: '90px', height: '70px', cursor: 'default'}}/>

                            <div style={{marginTop: '30px', color: 'rgba(153, 153, 153, 0.30', fontSize: '20px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal'}}>
                                {parse(t('post_empty'))}
                            </div>
                        </div>
                    ) : (
                        postData.data && postData.data.length === 0 ? (
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '500px'}}>
                                <img src={postEmptyImg} alt='' style={{width: '90px', height: '70px', cursor: 'default'}}/>
        
                                <div style={{marginTop: '30px', color: 'rgba(153, 153, 153, 0.30', fontSize: '20px', fontStyle: 'normal', fontWeight: '400', lineHeight: 'normal'}}>
                                    {parse(t('post_empty'))}
                                </div>
                            </div>
                        ) : (
                            <div className='posts-gallery'>
                            {postData.data && postData.data.map((post, idx) => (
                                <div onClick={() => handlePostImageClick(idx)} className={displayTemplateClassMap[displayTemplate[idx]]} style={{cursor: 'pointer'}}>
                                    {currentLanguageCode === 'en' ? (
                                        <img src={`https://www.dandycv.com${post.thumbnail_en}`} alt='' style={{pointerEvents: 'none'}}></img>
                                    ) : (
                                        <img src={`https://www.dandycv.com${post.thumbnail_kr}`} alt='' style={{pointerEvents: 'none'}}></img>
                                    )}
                                </div>
                            ))}
                            </div>
                        )
                    )}

                    <Footer
                        totalPaginatorNum={postData.total_pages} paginatorValue={paginatorValue} setPaginatorValue={setPaginatorValue}
                    />
                </>
            )}

            <PostImageViewPopup trigger={imageViewerPopup} setTrigger={setImageViewerPopup} mobileState={mobileState}
                mobileFileViewer={mobileFileViewer} setMobileFileViewer={setMobileFileViewer}
                selectedPostId={selectedPostId} setSelectedPostId={setSelectedPostId}
                selectedPostViewContentPage={selectedPostViewContentPage} setSelectedPostViewContentPage={setSelectedPostViewContentPage}
                selectedFileId={selectedFileId} setSelectedFileId={setSelectedFileId}
            />
            
        </div>
    )
}

export default PostsScreen
