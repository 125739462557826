import {
    IMG_2_IMG_GET_REQUEST,
    IMG_2_IMG_GET_SUCCESS,
    IMG_2_IMG_GET_FAIL,

    IMG_2_IMG_POST_REQUEST,
    IMG_2_IMG_POST_SUCCESS,
    IMG_2_IMG_POST_FAIL,

    IMG_2_IMG_DELETE_REQUEST,
    IMG_2_IMG_DELETE_SUCCESS,
    IMG_2_IMG_DELETE_FAIL,
} from '../constants/img2imgConstants'

export const getImg2ImgDataReducers = (state = { img2imgData: [] }, action) => {
    switch (action.type) {
        case IMG_2_IMG_GET_REQUEST:
            return { ...state, loading: true }

        case IMG_2_IMG_GET_SUCCESS:
            return { ...state, loading: false, img2imgData: action.payload.data }

        case IMG_2_IMG_GET_FAIL:
            return { ...state, loading: false, error: action.payload }

        case IMG_2_IMG_POST_REQUEST:
            return { ...state, loading: true }

        case IMG_2_IMG_POST_SUCCESS:
            return { ...state, loading: false }

        case IMG_2_IMG_POST_FAIL:
            return { ...state, loading: false, error: action.payload }

        case IMG_2_IMG_DELETE_REQUEST:
            return { ...state, loading: true}

        case IMG_2_IMG_DELETE_SUCCESS:
            return { ...state, loading: false }

        case IMG_2_IMG_DELETE_FAIL:
            return { ...state, loading: false, error: action.payload }

        default:
            return state
    }
}