import { useState } from 'react'
import i18next from 'i18next'
import Cookies from 'js-cookie'

import './Dropdown.css'

function Dropdown (props) {
    const currentLanguageCode = Cookies.get('i18next') || 'en';
    // const currentLanguageCode = 'kr';
    const [isActive, setIsActive] = useState(false)

    const languages = [
        {
            code: 'kr',
            name: '한국',
        },
        {
            code: 'en',
            name: 'England',
        }
    ]

    return (
        <div className="dropdown" style={{borderColor: props.posts && '#000', color: props.posts && '#000'}}>
            <div className="dropdown-btn" onClick={(e) => setIsActive(!isActive)} style={{color: props.posts && '#000'}}>
                {currentLanguageCode}
                <i className={isActive ? 'fa-solid fa-chevron-down toggle-btn' : 'fa-solid fa-chevron-down'}></i>
            </div>
            {isActive && (
                <div className="dropdown-content" style={{border: props.posts && '1px solid #000'}}>
                    {languages.map((item, index) => {
                        return (
                            <>
                                <div className="dropdown-item" onClick={(e) => {
                                    i18next.changeLanguage(item.code)
                                    setIsActive(false)
                                }}>
                                    {item.code}
                                </div>
                                {index < languages.length-1 && 
                                    <div style={{width: '60px', height: '1px', borderRadius: '100px', background: 'rgba(0, 0, 0, 0.05)'}}></div>
                                }
                            </>
                        )
                    })}
                </div>
            )}

        </div>
    )
}

export default Dropdown