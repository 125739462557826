import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { getPostsDataId } from '../actions/postActions'
import Cookies from 'js-cookie'

import PostsImageMobileFileViewer from '../components/PostsImageMobileFileViewer'

import logoSmall from '../images/logo-small.jpg'
import closeBtnImg from '../images/close-btn.svg'
import imageSwitchImg from '../images/full-arrow.svg'
import postShareImg from '../images/icons/posts-share.svg'
import postYoutubeImg from '../images/icons/post-youtube.svg'
import postInstagramImg from '../images/icons/post-instagram.svg'
import postTiktokImg from '../images/icons/post-tiktok.svg'
import postBlogImg from '../images/icons/post-blog.svg'

function PostsImageViewer() {
    const currentLanguageCode = Cookies.get('i18next') || 'en';
    const [mobileFileViewer, setMobileFileViewer] = useState(false)

    let { id: selectedPostId } = useParams();
    const [selectedPostViewContentPage, setSelectedPostViewContentPage] = useState(true)
    const [selectedFileId, setSelectedFileId] = useState(0)

    const getPostDataWithId = useSelector(state => state.getPostDataWithId)
    const { loading, error, postDataWithId } = getPostDataWithId

    const [mobileState, setMobileState] = useState(false)

    const setWindowWidth = () => {
        if (window.innerWidth <= 1100) {
            setMobileState(true)
        } else {
            setMobileState(false)
        }
    }

    useEffect(() => {
        setWindowWidth();
    }, [setMobileState]);

    window.addEventListener('resize', setWindowWidth)

    const navigate = useNavigate();

    const handleFileClick = (idx) => {
        setSelectedFileId(idx)
        if (mobileState) {
            setMobileFileViewer(true)
        }
    }

    const handleFileSwitchClick = (val) => {
        if (postDataWithId.video_file_name_list.length + postDataWithId.image_file_name_list.length > 0) {
            const counter = postDataWithId.video_file_name_list.length + postDataWithId.image_file_name_list.length  - 1

            if (counter < selectedFileId + val) {
                setSelectedFileId(0)
            } else if (selectedFileId + val < 0) {
                setSelectedFileId(counter)
            } else {
                setSelectedFileId(selectedFileId + val)
            }
        }
    }

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getPostsDataId(selectedPostId))
    }, [dispatch, selectedPostId])

    return (
        <div className='post-image-viewer-overlay' onClick={() => navigate('/posts')}>
            <div className='post-image-viewer-content' onClick={(e) => e.stopPropagation()}>
                <div className='post-image-viewer-content-navbar'>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {
                            mobileState && (
                                <>
                                    <img src={logoSmall} alt='logo' style={{width: '24px', height: '24px'}}/>
                                    <NavLink className='post-image-viewer-content-navbar-title' to={'/'} style={{marginLeft: '10px'}}>
                                        dandycv
                                    </NavLink>
                                </>
                            )
                        }
                    </div>

                    <img src={closeBtnImg} alt='' style={{cursor: 'pointer'}} onClick={() => navigate('/posts')}/>

                </div>

                
                <div
                    className='hr'
                    style={
                        {
                            marginTop: '15px',
                            marginLeft: !mobileState && '20px',
                            marginRight: !mobileState && '20px'
                        }
                    }
                />

                {
                    loading ? (
                        <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <div
                                style={
                                    {
                                        width: 'calc(100% - 10px)',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'absolute',
                                        overflow: 'hidden'
                                    }
                                }
                            >
                                <span className='loader'></span>
                            </div>
                        </div>
                    ) : (
                        error ? (
                            'Error'
                        ) : (
                            postDataWithId && (
                                <div className='post-image-view-content-helper'>
                                    <div className='post-image-view-content-left'>
                                        <div className='post-image-view-content-left-scroll' style={{height: '100%'}}>
                                            <div className='post-image-view-content-left-title'>
                                                {currentLanguageCode === 'en' ? postDataWithId.title_en : postDataWithId.title_kr}
                                            </div>

                                            <div className='post-image-view-content-left-avatar'>
                                                <img className='post-image-view-content-left-avatar-img' src={`https://www.dandycv.com${postDataWithId.profile_image}`} alt='' />

                                                <div className='post-image-view-content-left-avatar-info'>
                                                    <div className='post-image-view-content-left-avatar-info-content'>
                                                        <div style={{marginRight: '20px'}}>
                                                            <span>
                                                                {postDataWithId.video_file_name_list && postDataWithId.video_file_name_list.length}
                                                            </span> Videos
                                                        </div>
                                                        <div style={{marginRight: '30px'}}>
                                                            <span>
                                                                {postDataWithId.image_file_name_list && postDataWithId.image_file_name_list.length}
                                                            </span> Images
                                                        </div>
                                                        <img src={postShareImg} alt='' style={{cursor: 'pointer'}} onClick={() => navigator.clipboard.writeText(`https://www.dandycv.com/#/posts/${selectedPostId}`)}/>
                                                    </div>
                                                    <div className='post-image-view-content-left-avatar-info-social'>
                                                        <img src={postYoutubeImg} alt='' style={{cursor: 'pointer'}} onClick={() => postDataWithId.youtube_url && window.open(postDataWithId.youtube_url, '_blank', 'noreferrer')}/>
                                                        <img src={postInstagramImg} alt='' style={{cursor: 'pointer'}} onClick={() => postDataWithId.instagram_url && window.open(postDataWithId.instagram_url, '_blank', 'noreferrer')}/>
                                                        <img src={postTiktokImg} alt='' style={{cursor: 'pointer'}} onClick={() => postDataWithId.tiktok_url && window.open(postDataWithId.tiktok_url, '_blank', 'noreferrer')}/>
                                                        <img src={postBlogImg} alt='' style={{cursor: 'pointer'}} onClick={() => postDataWithId.naver_blog_url && window.open(postDataWithId.naver_blog_url, '_blank', 'noreferrer')}/>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='post-image-view-content-left-username'>
                                                @ai.dandycv
                                            </div>

                                            <div className='post-image-view-content-left-switch-page-btn'>
                                                <div
                                                    className='post-image-view-content-left-switch-page-btn-div'
                                                    style={
                                                        {
                                                            borderBottomColor: selectedPostViewContentPage && '#B4307D',
                                                            color: selectedPostViewContentPage && '#B4307D',
                                                            fontWeight: selectedPostViewContentPage && '700',
                                                        }
                                                    }
                                                    onClick={() => setSelectedPostViewContentPage(true)}
                                                >
                                                    Content
                                                </div>
                                                <div
                                                    className='post-image-view-content-left-switch-page-btn-div'
                                                    style={
                                                        {
                                                            borderBottomColor: !selectedPostViewContentPage && '#B4307D',
                                                            color: !selectedPostViewContentPage && '#B4307D',
                                                            fontWeight: !selectedPostViewContentPage && '700',
                                                        }
                                                    }
                                                    onClick={() => setSelectedPostViewContentPage(false)}
                                                >
                                                    Video & Image
                                                </div>
                                            </div>

                                            {
                                                selectedPostViewContentPage ? (
                                                    <>
                                                        <div className='post-image-view-content-left-summary'>
                                                            {currentLanguageCode === 'en' ? postDataWithId.subheading_en : postDataWithId.subheading_kr}
                                                        </div>

                                                        <div className='hr' style={{marginTop: '15px', marginLeft: '20px', marginRight: '20px'}}></div>

                                                        <div className='post-image-view-content-left-summary'>
                                                            <div>
                                                                {currentLanguageCode === 'en' ? postDataWithId.summary_en : postDataWithId.summary_kr}
                                                            </div>
                                                        </div>

                                                        <div className='hr' style={{marginTop: '15px', marginLeft: '20px', marginRight: '20px'}}></div>

                                                        <div className='post-image-view-content-left-summary'>
                                                            <div>
                                                                {currentLanguageCode === 'en' ? postDataWithId.background_en : postDataWithId.background_kr}
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <div className='post-image-view-content-left-image-display'>
                                                        {postDataWithId.video_file_name_list.map((file, idx) => (
                                                            <div
                                                                className='post-image-view-content-left-image-display-div'
                                                                style={{borderColor: selectedFileId === idx && !mobileState && '#B4307D'}}
                                                                onClick={() => handleFileClick(idx)}
                                                            >
                                                                <img key={idx} src={`https://www.dandycv.com/media/${file.thumbnail}`} alt=''/>
                                                            </div>
                                                        ))}
                                                        {postDataWithId.image_file_name_list.map((file, idx) => (
                                                            <div
                                                                className='post-image-view-content-left-image-display-div'
                                                                style={{borderColor: selectedFileId === idx + postDataWithId.video_file_name_list.length && !mobileState && '#B4307D'}}
                                                                onClick={() => handleFileClick(idx + postDataWithId.video_file_name_list.length)}
                                                            >
                                                                <img key={idx} src={`https://www.dandycv.com/media/${file.file_name}`} alt=''/>
                                                            </div>
                                                            
                                                        ))}
                                                    </div>
                                                )
                                            }

                                        </div>
                                    </div>

                                    {!mobileState && (
                                        <div className='post-image-view-content-right'>
                                            <div className='popup-generate-image-view-content-right-wrapper'>
                                                {
                                                    postDataWithId.video_file_name_list && (
                                                        selectedFileId < postDataWithId.video_file_name_list.length ? (
                                                            <React.Fragment key={postDataWithId.video_file_name_list[selectedFileId].file_name}>
                                                                <video controls autoPlay>
                                                                    <source src={`https://www.dandycv.com/media/${postDataWithId.video_file_name_list[selectedFileId].file_name}`} type="video/mp4" />
                                                                    Your browser does not support the video tag.
                                                                </video>
                                                            </React.Fragment>
                                                        ) : (
                                                            postDataWithId.image_file_name_list.length > 0 && (
                                                                <img
                                                                    src={`https://www.dandycv.com/media/${postDataWithId.image_file_name_list[selectedFileId - postDataWithId.video_file_name_list.length].file_name}`}
                                                                    alt=''
                                                                />
                                                            )
                                                        )
                                                    )
                                                }
                                            </div>

                                            <div className='popup-generate-image-view-content-right-slide-container' style={{left: '0', transform: 'rotate(180deg)'}} onClick={() => handleFileSwitchClick(-1)}>
                                                <img src={imageSwitchImg} alt='' />
                                            </div>

                                            <div className='popup-generate-image-view-content-right-slide-container' style={{right: '0'}} onClick={() => handleFileSwitchClick(1)}>
                                                <img src={imageSwitchImg} alt='' />
                                            </div>

                                        </div>
                                    )}
                                </div>
                            )
                        )
                    )
                }

            </div>

            <PostsImageMobileFileViewer viewerTrigger={mobileFileViewer && mobileState} setViewerTrigger={setMobileFileViewer}
                selectedFileId={selectedFileId} selectedPostId={selectedPostId}
                normalState={false}
            />
        </div>
    ) 
}

export default PostsImageViewer
