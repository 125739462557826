export const POST_GET_REQUEST = 'POST_GET_REQUEST'
export const POST_GET_SUCCESS = 'POST_GET_SUCCESS'
export const POST_GET_FAIL = 'POST_GET_FAIL'

export const POST_ID_GET_REQUEST = 'POST_ID_GET_REQUEST'
export const POST_ID_GET_SUCCESS = 'POST_ID_GET_SUCCESS'
export const POST_ID_GET_FAIL = 'POST_ID_GET_FAIL'

export const POST_SEARCH_REQUEST = 'POST_SEARCH_REQUEST'
export const POST_SEARCH_SUCCESS = 'POST_SEARCH_SUCCESS'
export const POST_SEARCH_FAIL = 'POST_SEARCH_FAIL'