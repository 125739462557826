import React from 'react'

function LoaderTool() {
    const styles = {
        background: {
            position: 'fixed',
            overflow: 'hidden',
            top: '0',
            left: '0',
            width: '100%',
            height: '100vh',
            background: 'rgba(0, 0, 0, 0.2)',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 100,
        }
    }
    return (
        <div style={styles.background}>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <span class="loader"></span>
            </div>
        </div>
    )
}

export default LoaderTool
