import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from "react-router-dom";

import './MyPageScreen.css'

import main_1_Img from '../../images/main-1.jpg'
import main_1_mobile_Img from '../../images/main-1-mobile.jpg'
import image_data_1_Img from '../../images/image-data-1.jpg'
import image_data_2_Img from '../../images/image-data-2.jpg'
import image_data_3_Img from '../../images/image-data-3.jpg'
import image_data_4_Img from '../../images/image-data-4.jpg'
import image_data_5_Img from '../../images/image-data-5.jpg'
import video_data_1_Img from '../../images/video-data-1.gif'

function MyPageScreen() {

  const navigate = useNavigate();

  const [mobileState, setMobileState] = useState(false)
    const setWindowWidth = () => {
        if (window.innerWidth <= 1100) {
            setMobileState(true)
        } else {
            setMobileState(false)
        }
  }

  useEffect(() => {
      setWindowWidth();
  }, [setMobileState]);


  window.addEventListener('resize', setWindowWidth)

  return (
    <>
      <div className='my-page-main'>
        <div className='my-page-main-fill-1'>
          <NavLink to={'/'}>
            <img src={mobileState ? main_1_mobile_Img : main_1_Img} alt='main-1' />
          </NavLink>
          <div className='my-page-main-fill-1-txt'>Home</div>
        </div>

        <div className='my-page-main-title-parent'>
          <div>
            Tools
          </div>
          <div className='hr' style={{flex: '1', marginLeft: '30px', marginTop: '8px'}}></div>
        </div>

        <div className='my-page-main-title' style={{marginTop: '30px'}}>
          Image data
        </div>

        <div className='my-page-main-image-data'>
          <NavLink className='my-page-main-fill' to='/my-page/remove-background'>
            <div style={{position: 'relative'}}>
              <img src={image_data_1_Img} alt='main-1' />
              <div className='my-page-main-img-title'>
                Remove background & Composite
              </div>
            </div>

            <div className='my-page-main-fill-title'>
              <div>
                Replace image background
              </div>

              <div style={{fontSize: '16px', fontWeight: '400'}}>
                Quickly remove, blur, and composite the background of the image.
              </div>
            </div>
          </NavLink>

          <NavLink className='my-page-main-fill' to='/my-page/txt2img'>
            <div style={{position: 'relative'}}>
              <img src={image_data_2_Img} alt='main-2' />
              <div className='my-page-main-img-title'>
                Generate images
              </div>
            </div>

            <div className='my-page-main-fill-title'>
              <div>
                Text to images
              </div>

              <div style={{fontSize: '16px', fontWeight: '400'}}>
                Create images that are not in the world with text.
              </div>
            </div>
          </NavLink>

          <NavLink className='my-page-main-fill' to='/my-page/img2img'>
            <div style={{position: 'relative'}}>
              <img src={image_data_3_Img} alt='main-2' />
              <div className='my-page-main-img-title'>
                Edit images
              </div>
            </div>

            <div className='my-page-main-fill-title'>
              <div>
                Image to image
              </div>

              <div style={{fontSize: '16px', fontWeight: '400'}}>
                Remix the creation you want with various types of filters.
              </div>
            </div>
          </NavLink>

          <NavLink className='my-page-main-fill' to='/my-page/upscaling'>
            <div style={{position: 'relative'}}>
              <img src={image_data_4_Img} alt='main-2' />
              <div className='my-page-main-img-title'>
                Quality improvement
              </div>
            </div>

            <div className='my-page-main-fill-title'>
              <div>
                Upscaling
              </div>

              <div style={{fontSize: '16px', fontWeight: '400'}}>
                Increase image resolution.
              </div>
            </div>
          </NavLink>

          <NavLink className='my-page-main-fill' to='/my-page/multi-prompting'>
            <div style={{position: 'relative'}}>
              <img src={image_data_5_Img} alt='main-2' />
              <div className='my-page-main-img-title'>
                Multiple requests
              </div>
            </div>

            <div className='my-page-main-fill-title'>
              <div>
                Multi-Prompting
              </div>

              <div style={{fontSize: '16px', fontWeight: '400'}}>
                Generate images from multiple prompts
              </div>
            </div>
          </NavLink>
          
        </div>

        <div className='my-page-main-title'>
          Video data
        </div>

        <div className='my-page-main-image-data'>
          <div className='my-page-main-fill' >
            <div style={{position: 'relative'}}>
              <img src={video_data_1_Img} alt='main-1' />
              <div className='my-page-main-img-title'>
                Generate videos
              </div>
            </div>

            <div className='my-page-main-fill-title'>
              <div>
                Text to videos
              </div>

              <div style={{fontSize: '16px', fontWeight: '400'}}>
                Use words and images to generate new videos out of existing ones.
              </div>
            </div>
          </div>

        </div>

      </div>

      <div className='my-page-footer'>
        <div className='my-page-footer-content'>
          <div className='my-page-footer-content-item' onClick={() => navigate('/')}>
            Home
          </div>
          <div className='my-page-footer-content-item' onClick={() => navigate('/posts')}>
            Posts
          </div>
          <div className='my-page-footer-content-item' onClick={() => navigate('/my-page/account-settings?app=subscription')}>
            Pricing
          </div>
          <div className='my-page-footer-content-item' onClick={() => navigate('/term-of-service')}>
            Terms
          </div>
          <div className='my-page-footer-content-item' onClick={() => navigate('/privacy-policy')}>
            Privacy
          </div>
        </div>
      </div>
    </>
  )
}

export default MyPageScreen
