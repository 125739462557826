import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { register } from '../../actions/userActions'

import './LoginScreen.css'

import googleImg from '../../images/icon-google.jpg'
import appleImg from '../../images/icon-mac.jpg'
import loginImg from '../../images/login.jpg'
import logoSmallImg from '../../images/logo-small.jpg'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser';
import Cookies from 'js-cookie'

function RegisterScreen() {
    const { t } = useTranslation();
    const currentLanguageCode = Cookies.get('i18next') || 'en';

    const [email, setEmail] = useState('')
    const [validEmail, setValidEmail] = useState(true)
    const [emailExists, setEmailExists] = useState(false)

    const [username, setUsername] = useState('')
    const [validUsername, setValidUsername] = useState(true)
    const [usernameExists, setUsernameExists] = useState(false)

    const [password, setPassword] = useState('')
    const [validPassword, setValidPassword] = useState(true)

    const [confirmPassword, setConfirmPassword] = useState('')
    const [validConfirmPassword, setValidConfirmPassword] = useState(true)

    const [firstName, setFirstName] = useState('')
    const [validFirstName, setValidFirstName] = useState(true)
    const [lastName, setLastName] = useState('')
    const [validLastName, setValidLastName] = useState(true)

    const [organization, setOrganization] = useState('')

    const [progressCounter, setProgressCounter] = useState(1)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userRegister = useSelector(state => state.userRegister)
    const { loading, registerComplete } = userRegister

    const handleClick = () => {
        if (progressCounter === 1) {

            if (validEmail) {
                fetch(`/base/api/check-email/${email}/`)
                .then((response) => response.json())
                .then((data) => {
                    if (!data.exists) {
                        setProgressCounter(progressCounter + 1)
                    } else {
                        setEmailExists(true);
                    }
                })
                .catch((error) => console.error(error));
            }

        } 
        else if (progressCounter === 2) {

            if (username.length === 0 || password.length === 0 || confirmPassword === 0) {
                setValidUsername(username.length !== 0)
                setValidPassword(password.length !== 0)
                setValidConfirmPassword(confirmPassword.length !== 0)
            }
            else if (validUsername && validPassword && validConfirmPassword) {
                fetch(`/base/api/check-username/${username}/`)
                .then((response) => response.json())
                .then((data) => {
                    if (!data.exists) {
                        setProgressCounter(progressCounter + 1)
                    } else {
                        setUsernameExists(true);
                    }
                })
                .catch((error) => console.error(error));
            }

        }
        // setProgressCounter(progressCounter + 1)
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        if (lastName.length === 0 || firstName.length === 0) {
            setValidLastName(lastName.length !== 0)
            setValidFirstName(firstName.length !== 0)
        }
        else if (validLastName && validFirstName) {
            dispatch(register(email, password, username, firstName, lastName, organization))
        }
    }

    const handleEmailChange = (e) => {
        e.preventDefault()

        setEmail(e.target.value)

        setValidEmail(
            e.target.value.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        )

        setEmailExists(false)

    }

    const handleUsernameChange = (e) => {
        e.preventDefault()

        setUsername(e.target.value)

        setValidUsername(
            !/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(e.target.value)
        )

        setUsernameExists(false)

    }

    const handlePasswordChange = (e) => {
        e.preventDefault()

        setPassword(e.target.value)

        setValidPassword(
            e.target.value.length > 5
        )

        setValidConfirmPassword(
            e.target.value === confirmPassword
        )
    }

    const handleConfirmPasswordChange = (e) => {
        e.preventDefault()

        setConfirmPassword(e.target.value)

        setValidConfirmPassword(
            e.target.value === password
        )
    }

    const handleFirstNameChange = (e) => {
        e.preventDefault()

        setFirstName(e.target.value)

        setValidFirstName(
            e.target.value.length > 0
        )
    }

    const handleLastNameChange = (e) => {
        e.preventDefault()

        setLastName(e.target.value)

        setValidLastName(
            e.target.value.length > 0
        )
    }

    return (
        <div className='login'>
            <div className='login-image'>
                <img src={loginImg} alt='login-img'/>
            </div>

            <div className='login-form'>
                <div className='login-logo'>
                    <NavLink to={'/'}>
                        <img src={logoSmallImg} alt='logo' style={{width: '40px', height: '40px'}}/>
                    </NavLink>
                </div>

                {loading &&
                    <div className='loader-login-wrapper' style={{zIndex: '10'}}>
                        <span className='loader-login'></span>
                    </div>
                }
                    
                {registerComplete ? 
                    <>
                        <div className='register-form-confirm'>
                            {parse(t('signup_confirm'))}
                        </div>
                        <div className='register-form-confirm' style={{fontSize: '18px', marginTop: '18px'}}>
                            {parse(t('signup_confirm_spam'))}
                        </div>
                        <div className='register-form-confirm-navigate'>
                            {parse(t('signup_confirm_msg'))} <NavLink to='/login'>&nbsp; {parse(t('signup_switch_login_link'))}</NavLink>
                        </div>
                    </>
                        : 
                    <div className='login-form-wrapper'>
                        <div className='login-form-welcome'>
                            {parse(t('signup_welcome'))}
                        </div>
                        <div className='login-form-signup'>
                            {parse(t('signup_switch_login'))} <NavLink to='/login'><span>&nbsp; {parse(t('signup_switch_login_link'))}</span></NavLink>
                        </div>

                        <div className='login-form-container'>
                            {progressCounter === 1 && 
                                <div className='input-with-alert'>
                                    <input type='email' placeholder={parse(t('signup_email_placeholder'))} value={email} onChange={handleEmailChange}></input>
                                    {emailExists && <div className='input-alert-form'>Email address is already registered</div>}
                                    {!validEmail && <div className='input-alert-form'>Should be a valid email address!</div>}
                                </div>
                            }
                            {progressCounter === 2 && 
                                <div className='input-with-alert'>
                                    <input type='text' placeholder={parse(t('signup_username_placeholder'))} maxlength={10} value={username} onChange={handleUsernameChange}></input>
                                    {usernameExists && <div className='input-alert-form'>Username already exists</div>}
                                    {!validUsername && <div className='input-alert-form'>Username shouldn't contain special characters!</div>}
                                    <input type='password' placeholder={parse(t('signup_password_placeholder'))} value={password} onChange={handlePasswordChange} style={{marginTop: '26px'}}></input>
                                    {!validPassword && <div className='input-alert-form'>At least 6 characters!</div>}
                                    <input type='password' placeholder={parse(t('signup_password_confirm_placeholder'))} value={confirmPassword} onChange={handleConfirmPasswordChange}></input>
                                    {!validConfirmPassword && <div className='input-alert-form'>Passwords do not match!</div>}
                                </div>
                            }
                            {progressCounter === 3 &&
                                <>
                                    <input type='text' placeholder={parse(t('signup_first_name_placeholder'))} value={firstName} onChange={handleFirstNameChange}></input>
                                    {!validFirstName && <div className='input-alert-form'>Provide first name!</div>}
                                    <input type='text' placeholder={parse(t('signup_last_name_placeholder'))} value={lastName} onChange={handleLastNameChange}></input>
                                    {!validLastName && <div className='input-alert-form'>Provide last name!</div>}
                                    <input type='text' placeholder={parse(t('signup_organization_placeholder'))} value={organization} onChange={(e) => setOrganization(e.target.value)}></input>
                                </>
                            }

                            {progressCounter === 3 ?
                                <button onClick={handleSubmit}>{parse(t('signup_register'))}</button>
                                :
                                <button onClick={handleClick}>{parse(t('signup_next'))}</button>
                            }
                        </div>

                        <div className='login-form-help'>
                            <div className={progressCounter === 1 ? 'register-progress-bar active' : 'register-progress-bar'} style={{cursor: progressCounter > 1 && 'pointer'}} onClick={progressCounter > 1 ? () => setProgressCounter(1) : undefined}></div>
                            <div className={progressCounter === 2 ? 'register-progress-bar active' : 'register-progress-bar'} style={{cursor: progressCounter > 2 && 'pointer'}} onClick={progressCounter > 2 ? () => setProgressCounter(2) : undefined}></div>
                            <div className={progressCounter === 3 ? 'register-progress-bar active' : 'register-progress-bar'}></div>
                        </div>
                        {/* <div className='login-form-login-methods'>
                            <button style={{position: 'relative'}}>
                                <div style={{position: 'absolute', marginLeft: '30px'}} >
                                <img src={googleImg} alt='' style={{width: '24px', height: '24px'}} />
                                </div>
                                <div>{parse(t('login_google'))}</div>
                            </button>
                            <button style={{position: 'relative'}}>
                                <div style={{position: 'absolute', marginLeft: '30px'}} >
                                <img src={appleImg} alt='' style={{width: '24px', height: '24px'}} />
                                </div>
                                <div>{parse(t('login_apple'))}</div>
                            </button>
                        </div> */}
                        <div className='login-form-agreement'>
                            {currentLanguageCode === 'en' ? (
                                <div>
                                    By clicking "Next", "Sign up with Google" or "Sign up with Apple" you agree to our <span onClick={() => navigate('/term-of-service')}>Terms of use</span> and acknowledge that you have read and understand our <span onClick={() => navigate('/privacy-policy')}>Privacy Policy</span>.
                                </div>
                            ) : (
                                <div>
                                    "Google 가입" 또는 "Apple 가입"을 클릭하면 귀하는 당사의 <span onClick={() => navigate('/term-of-service')}>이용 약관</span>에 동의하고 당사의 <span onClick={() => navigate('/privacy-policy')}>개인 정보 보호 정책</span>을 읽고 이해했음을 인정합니다.
                                </div>
                            )}
                        </div>
                    </div>
                }
                
                
            </div>
        </div>
    )
}

export default RegisterScreen
