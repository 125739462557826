import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { upscalingHistory, upscalingGeneration } from '../actions/upscalingActions'
import { getUserSubscriptionDetail } from '../actions/userActions'

import DownloadPopup from '../components/DownloadPopup'
import DeletePopup from '../components/DeletePopup'
import ViewPopup from '../components/ViewPopup'
import UpscalingSidebar from '../components/UpscalingSidebar'

import MobileImageViewer from '../components/MobileImageViewer'

import logoSmall from '../images/logo-small.jpg'

import downloadImg from '../images/drag&drop_download.svg'
import deleteImg from '../images/drag&drop_delete.svg'
import closeBtnImg from '../images/close-btn.svg'
import downloadGenerateImg from '../images/generate_download.svg'
import deleteGenerateImg from '../images/generate_delete.svg'

import arrowImg from '../images/generate-image-arrow.svg'
import arrowNavImg from '../images/generate-image-arrow-nav.svg'
import LoaderTool from '../components/LoaderTool'

function UpscalingScreen() {
    const modelType = ['Real-ESRGAN x2']

    const [downloaderPopup, setDownloaderPopup] = useState(false);
    const [viewPopup, setViewPopup] = useState(false);
    const [deleterPopup, setDeleterPopup] = useState(false);
    const [selectedPromptId, setSelectedPromptId] = useState(0);
    const [selectedImageId, setSelectedImageId] = useState(0);
    const [deleteTarget, setDeleteTarget] = useState();

    const [mobileSidebarState, setMobileSidebarState] = useState(false);

    const [mobileState, setMobileState] = useState(false)
    const setWindowWidth = () => {
        if (window.innerWidth <= 1100) {
            setMobileState(true)
            setViewPopup(false)
        } else {
            setMobileState(false)
        }
    }

    useEffect(() => {
        setWindowWidth();
    }, [setMobileState]);


    window.addEventListener('resize', setWindowWidth)

    const [imageFileName, setImageFileName] = useState();
    const [imageUrl, setImageUrl] = useState();

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const userDetail = useSelector(state => state.userSubscriptionDetail)
    const { userSubscriptionDetail } = userDetail

    const upscalingData = useSelector(state => state.getUpscalingData)
    const {loading, error, upscalingData: data} = upscalingData

    const navigate = useNavigate()

    const [imageInput, setImageInput] = useState()
    const [modelTypeState, setModelTypeState] = useState(0)

    const [paginatorValue, setPaginatorValue] = useState(1);
    const [totalPaginatorNum, setTotalPaginatorNum] = useState(1);

    const dispatch = useDispatch()

    useEffect(() => {
        if (!userInfo) {
            navigate('/login')
        } else {
            dispatch(upscalingHistory(userInfo.username, paginatorValue, 10))
            dispatch(getUserSubscriptionDetail(userInfo.token))
        }
    }, [dispatch, navigate, userInfo, paginatorValue]);

    async function handleGenerateClick() {
        if (prompt && imageInput) {

            dispatch(upscalingGeneration(
                userInfo.username,
                imageInput,
                modelType[modelTypeState],
                paginatorValue,
                10
            ))
        }
    }

    const handleDownloadAllImages = (prompt_id) => {
        data[prompt_id].image_name_list.forEach(url => {
            fetch('https://www.dandycv.com/media/' + url.image_name)
            .then((response) => response.blob())
            .then((blob) => {
                // Create a Blob object and generate a URL for it
                const url = window.URL.createObjectURL(blob);

                // Create an anchor element for downloading
                const a = document.createElement('a');
                a.href = url;
                a.download = data[prompt_id].prompt + '.jpg'; // Specify the desired file name
                document.body.appendChild(a);

                // Trigger a click event on the anchor element to initiate the download
                a.click();

                // Remove the anchor element from the DOM
                document.body.removeChild(a);
            });
        })
    }

    const handleDownloadImage = (name, url) => (event) => {
        event.stopPropagation();
        setImageFileName(name);
        setImageUrl(url);
        setDownloaderPopup(true);
    }

    const handleDelete = (prompt_id, image_id) => (event) => {
        event.stopPropagation();
        setSelectedPromptId(prompt_id);

        if (image_id !== -1) {
            setSelectedImageId(image_id)
            setDeleteTarget(1)
        } else {
            setDeleteTarget(0)
        }

        setDeleterPopup(true);
    }

    const handleViewPopup = (prompt_id, image_id) => {
        setSelectedPromptId(prompt_id);
        setSelectedImageId(image_id)
        setViewPopup(true)
    }

    return (
        <div className='gnrt-img-container'>
            <div className='gnrt-img-navbar'>
                <img src={logoSmall} alt='logo' />
                <NavLink className='gnrt-img-navbar-title' to={'/my-page'} style={{marginLeft: '10px'}}>
                    Image data
                </NavLink>
                <div className='gnrt-img-navbar-topic' style={{marginLeft: '4px', marginRight: '4px'}}>
                    /
                </div>
                <div className='gnrt-img-navbar-topic' >
                    Quality improvement
                </div>
                {userSubscriptionDetail && userInfo && (
                    <>
                        <div className='rmv-bg-navbar-title' style={{marginLeft: '4px', marginRight: '4px', fontWeight: 'normal'}}>
                            /
                        </div>
                        <div className='rmv-bg-navbar-title' style={{fontWeight: 'normal'}}>
                            {userSubscriptionDetail.user_data.subscriptionDetail.credits}&copy;
                        </div>

                    </>
                )}

                <div className='gnrt-img-navbar-close'>
                    <img src={closeBtnImg} alt='' onClick={() => navigate('/my-page')}/>
                </div>
            </div>

            <div className='gnrt-img-main'>
                <div className='gnrt-img-main-sidebar'>
                    <button onClick={() => handleGenerateClick()}>
                        Upscale
                    </button>

                    <UpscalingSidebar
                        imageInput={imageInput} setImageInput={setImageInput}
                        modelType={modelType} modelTypeState={modelTypeState} setModelTypeState={setModelTypeState}
                    />

                </div>

                <div className='gnrt-img-main-display'>
                    {loading && (
                        <LoaderTool />
                    )}
                    {(
                        error ? (
                            <div>Error</div>
                        ) : (
                            data && (
                                Array.from(data).map((prompt, prompt_idx) => (
                                    <>
                                        <div className='gnrt-img-main-display-header'>
                                            <div>
                                                {prompt.date.slice(0, 19).replace('T', ' ')}
                                            </div>
                                            <div className='gnrt-img-main-display-header-hr'></div>
                                        </div>
                                        <div className='gnrt-img-main-display-header-prompt'>
                                            <div>
                                                {prompt.prompt}
                                            </div>
            
                                            <div style={{marginLeft: '30px', minWidth: '73px'}}>
                                                <img src={downloadImg} alt='download' style={{marginRight: '30px', cursor: 'pointer'}} onClick={() => handleDownloadAllImages(prompt_idx)} />
                                                <img src={deleteImg} alt='delete' style={{cursor: 'pointer'}} onClick={handleDelete(prompt_idx, -1)} />
                                            </div>
                                        </div>
            
                                        <div className='gnrt-img-main-display-header-img'>
                                            {
                                                Array.from(prompt.image_name_list).map((img_url, img_idx) => (
                                                    <div className='gnrt-img-main-display-header-img-container' onClick={() => handleViewPopup(prompt_idx, img_idx)}>
                                                        <img
                                                            key={img_idx}
                                                            src={`https://www.dandycv.com/media/${img_url.image_name}`}
                                                            alt=''
                                                        />
                                                        <div className='gnrt-img-main-display-header-img-container-dwn-rmv'>
                                                            <img src={downloadGenerateImg} alt='download' style={{marginRight: '30px', cursor: 'pointer'}} onClick={handleDownloadImage(prompt.prompt, img_url.image_name)} />
                                                            <img src={deleteGenerateImg} alt='delete' style={{cursor: 'pointer'}} onClick={handleDelete(prompt_idx, img_idx)} />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
            
                                        <div className='gnrt-img-main-display-header-img-mobile'>
                                            <MobileImageViewer prompt={prompt} prompt_idx={prompt_idx}
                                                setDeleterPopup={setDeleterPopup}
                                                setSelectedPromptId={setSelectedPromptId}
                                                setSelectedImageId={setSelectedImageId}
                                                setDeleteTarget={setDeleteTarget}
                                                img2img={true}
                                            />
                                        </div>
                                    </>
                                ))
                            )                            
                        )
                    )}

                    <div className='rmv-bg-history-table-paginator'>
                        {'<'}
                        {Array.from({ length: totalPaginatorNum }, (_, index) => index + 1).map((number) => (
                            <div
                                className='rmv-bg-history-table-paginator-item'
                                style={{color: paginatorValue === number && '#000'}}
                                onClick={() => setPaginatorValue(number)}
                            >
                            {number}
                            </div>
                        ))}
                        {'>'}
                    </div>
                </div>
                
                {mobileSidebarState ?(
                    <div className='gnrt-img-main-sidebar-mobile'>
                        <div className='gnrt-img-main-sidebar-mobile-img-wrapper' onClick={() => setMobileSidebarState(!mobileSidebarState)}>
                            <img src={arrowImg} alt='' className='gnrt-img-main-sidebar-mobile-img' />
                        </div>
                        

                        <button className='gnrt-img-main-sidebar-mobile-btn'
                            onClick={() => {
                                handleGenerateClick()
                                setMobileSidebarState(!mobileSidebarState)
                            }}
                        >
                            Upscale
                        </button>

                        <div className='gnrt-img-main-sidebar-mobile-content'>
                            <UpscalingSidebar
                                imageInput={imageInput} setImageInput={setImageInput}
                                modelType={modelType} modelTypeState={modelTypeState} setModelTypeState={setModelTypeState}
                            />
                        </div>
                    </div>
                ) : (
                    <div className='gnrt-img-main-sidebar-mobile-nav'>
                        <img src={arrowNavImg} alt=''
                            onClick={() => setMobileSidebarState(!mobileSidebarState)}
                        />
                    </div>
                )}
            </div>

            <DownloadPopup trigger={downloaderPopup} setTrigger={setDownloaderPopup}
                imageFileName={imageFileName} setImageFileName={setImageFileName}
                imageUrl={imageUrl} setImageUrl={setImageUrl}
            />

            <ViewPopup trigger={viewPopup} setTrigger={setViewPopup}
                deleterPopup={deleterPopup} setDeleterPopup={setDeleterPopup}
                data={data}
                setDeleteTarget={setDeleteTarget}
                selectedPromptId={selectedPromptId} setSelectedPromptId={setSelectedPromptId}
                selectedImageId={selectedImageId} setSelectedImageId={setSelectedImageId}
                upscaling={true}
            />

            <DeletePopup trigger={deleterPopup} setTrigger={setDeleterPopup} setViewPopup={setViewPopup}
                data={data}
                upscaling={true}
                deleteTarget={deleteTarget} selectedPromptId={selectedPromptId} selectedImageId={selectedImageId}
                paginatorValue={paginatorValue} itemsPerPage={10}
            />
            
        </div>
    )
}

export default UpscalingScreen
