import React, { useRef } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux';

import addImgBtn from '../images/add-image.svg'
import refreshImg from '../images/generate_refresh.svg'
import downloadImg from '../images/generate_download.svg'

function RemoveBackgroundComponent(props) {
    const inputRef = useRef();

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const handleDragOver = (event) => {
        event.preventDefault()
    }

    async function handleOnDrop(event, drop=false) {
        event.preventDefault()
        const img = drop ? event.target.files[0] : event.dataTransfer.files[0]

        if (isImageFile(img)) {
            var res = await getImageResolution(img)

            props.setImageFileResolution(res.width + 'x' + res.height)

            props.setImageFile(img)
            props.setImageFileName(img.name.substring(0, img.name.lastIndexOf('.')))
    
            // send to backend
            processImage(img)
        }
    }

    const resetImages = () => {
        props.setImageFile();
        props.setImageFileName();
        props.setImageFileResolution();
        props.setResultImageFile();
    }

    function isImageFile(file) {
        const acceptedImageTypes = ['image/png', 'image/jpeg']
        return file && acceptedImageTypes.includes(file['type'])
    }

    function getImageResolution(file) {
        return new Promise((resolve, reject) => {
            const image = new Image();
    
            image.onload = () => {
                const width = image.width;
                const height = image.height;
                resolve({width, height})
            }
    
            image.onerror = () => {
                reject(new Error('Failed to load the image'));
            };
    
            image.src = URL.createObjectURL(file);
        })
    }

    function processImage(image) {
        return new Promise((resolve, reject) => {    
            const formData = new FormData();
            formData.append('username', userInfo.username);
            formData.append('image', image);

            axios.post(
              'remove_background/api/',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': `Bearer ${userInfo.access}`
                }
              }
            )
            .then(response => {
                const modifiedImage = response.data.modifiedImage;
                props.setResultImageFile(modifiedImage);
    
                resolve()
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    return (
        <div
            className='rmv-bg-main-drag-and-drop-zone'
            onDragOver={handleDragOver}
            onDrop={(event) => handleOnDrop(event, false)}
            onClick={() => inputRef.current.click()}
        >
            {!props.imageFile ? (
                <div className='rmv-bg-main-drag-and-drop-main'>
                    <input
                        type='file'
                        onChange={(event) => handleOnDrop(event, true)}
                        hidden
                        ref={inputRef}
                    />
                    <img
                        className='rmv-bg-main-drag-and-drop-main-img'
                        src={addImgBtn}
                        alt='add'
                    />
                    <div className='rmv-bg-main-drag-and-drop-main-txt'>
                        {props.dragAndDropTxt}
                    </div>
                </div>
            ) : (
                <div className='rmv-bg-main-drag-and-drop-main-display'>
                    <div className='rmv-bg-main-drag-and-drop-main-display-preview-main'>
                        <div className='rmv-bg-main-drag-and-drop-main-display-preview-container'>
                            <img src={URL.createObjectURL(props.imageFile)} alt=''/>
                        </div>
                    </div>
                    <div className='rmv-bg-main-drag-and-drop-main-display-preview'>
                        {props.resultImageFile ? (
                            <div className='rmv-bg-main-drag-and-drop-main-display-preview-container'
                                style={
                                    props.backgroundPickerState === 0 ? {
                                        background: props.backgroundColorPicker
                                    } : {
                                        backgroundImage: `url(${props.backgroundImagePicker})`, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat'
                                    }
                                }
                            >
                                <img src={`data:image/jpeg;base64,${props.resultImageFile}`} alt='' />

                                
                            </div>
                        ) : (
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <span class="loader"></span>
                            </div>
                        )}

                        <div className='rmv-bg-main-drag-and-drop-main-display-preview-btns'>
                            <img src={refreshImg} alt='' style={{marginRight: '20px', cursor: 'pointer'}} onClick={resetImages} />
                            <img src={downloadImg} alt='' style={{marginRight: '30px', cursor: 'pointer'}} onClick={() => props.setDownloaderPopup(props.imageFile && true)}/>
                            {/* <img src={downloadImg} alt='' style={{cursor: 'pointer'}} onClick={() => setDownloaderPopup(imageFile && true)}/> */}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default RemoveBackgroundComponent
