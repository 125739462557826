import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { multiPromptingHistory, multiPromptingGeneration} from '../../actions/multiPromptingActions'
import { getUserSubscriptionDetail } from '../../actions/userActions'
import { useDispatch, useSelector } from 'react-redux'

import LoaderTool from '../../components/LoaderTool'
import ViewPopup from '../../components/ViewPopup'
import DownloadPopup from '../../components/DownloadPopup'
import DeletePopup from '../../components/DeletePopup'
import MultiPromptingSidebar from '../../components/MultiPromptingSidebar'
import MobileImageViewer from '../../components/MobileImageViewer'

import logoSmall from '../../images/logo-small.jpg'
import closeBtnImg from '../../images/close-btn.svg'
import downloadImg from '../../images/drag&drop_download.svg'
import deleteImg from '../../images/drag&drop_delete.svg'
import downloadGenerateImg from '../../images/generate_download.svg'
import deleteGenerateImg from '../../images/generate_delete.svg'
import arrowImg from '../../images/generate-image-arrow.svg'
import arrowNavImg from '../../images/generate-image-arrow-nav.svg'
import img0 from '../../images/generate-images/img0.jpg'
import img1 from '../../images/generate-images/img1.jpg'
import img2 from '../../images/generate-images/img2.jpg'
import img3 from '../../images/generate-images/img3.jpg'
import img4 from '../../images/generate-images/img4.jpg'
import img5 from '../../images/generate-images/img5.jpg'
import img6 from '../../images/generate-images/img6.jpg'
import img7 from '../../images/generate-images/img7.jpg'
import img8 from '../../images/generate-images/img8.jpg'
import img9 from '../../images/generate-images/img9.jpg'
import img10 from '../../images/generate-images/img10.jpg'
import img11 from '../../images/generate-images/img11.jpg'
import img12 from '../../images/generate-images/img12.jpg'
import img13 from '../../images/generate-images/img13.jpg'
import img14 from '../../images/generate-images/img14.jpg'
import img15 from '../../images/generate-images/img15.jpg'
import img16 from '../../images/generate-images/img16.jpg'
import img17 from '../../images/generate-images/img17.jpg'

function MultiPromptingScreen() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [downloaderPopup, setDownloaderPopup] = useState(false);
    const [viewPopup, setViewPopup] = useState(false);
    const [deleterPopup, setDeleterPopup] = useState(false);
    const [selectedPromptId, setSelectedPromptId] = useState(0);
    const [selectedImageId, setSelectedImageId] = useState(0);
    const [deleteTarget, setDeleteTarget] = useState();
    const [imageFileName, setImageFileName] = useState();
    const [imageUrl, setImageUrl] = useState();

    const [prompts, setPrompts] = useState([''])
    const [weights, setWeights] = useState([1])

    const [selectedAspectRatio, setSelectedAspectRatio] = useState(0)
    const [selectedStyle, setSelectedStyle] = useState(0)
    const [imageCount, setImageCount] = useState(1)
    const [CFGScale, setCFGScale] = useState(5)

    const [mobileSidebarState, setMobileSidebarState] = useState(false);

    const [mobileState, setMobileState] = useState(false)

    const [paginatorValue, setPaginatorValue] = useState(1);
    const [totalPaginatorNum, setTotalPaginatorNum] = useState(1);

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const userDetail = useSelector(state => state.userSubscriptionDetail)
    const { userSubscriptionDetail } = userDetail

    const multiPromptingData = useSelector(state => state.getMultiPromptingData)
    const {loading, error, multiPromptingData: data} = multiPromptingData

    const aspectRatios = {
        sdxl: [
            { ratio: '21:9', size: { width: 1536, height: 640 }},
            { ratio: '16:9', size: { width: 1344, height: 768 }},
            { ratio: '3:2', size: { width: 1216, height: 832 }},
            { ratio: '5:4', size: { width: 1152, height: 896 }},
            { ratio: '4:5', size: { width: 896, height: 1152 }},
            { ratio: '2:3', size: { width: 832, height: 1216 }},
            { ratio: '9:16', size: { width: 768, height: 1344 }},
            { ratio: '9:21', size: { width: 640, height: 1536 }},
            { ratio: '1:1', size: { width: 1024, height: 1024 }}
        ]
    }

    const styles = [
        { value: img0, title: 'No Style', passParam: null },
        { value: img1, title: 'Anime', passParam: 'anime' },
        { value: img2, title: 'Photographic', passParam: 'photographic' },
        { value: img3, title: 'Digital Art', passParam: 'digital-art' },
        { value: img4, title: 'Comic Book', passParam: 'comic-book' },
        { value: img5, title: 'Fantasy Art', passParam: 'fantasy-art' },
        { value: img6, title: 'Analog Film', passParam: 'analog-film' },
        { value: img7, title: 'Neon Punk', passParam: 'neon-punk' },
        { value: img8, title: 'Isometric', passParam: 'isometric' },
        { value: img9, title: 'Low Poly', passParam: 'low-poly' },
        { value: img10, title: 'Origami', passParam: 'origami' },
        { value: img11, title: 'Line Art', passParam: 'line-art' },
        { value: img12, title: 'Cinematic', passParam: 'cinematic' },
        { value: img13, title: '3D Model', passParam: '3d-model' },
        { value: img14, title: 'Pixel Art', passParam: 'pixel-art' },
        { value: img15, title: 'Enhance', passParam: 'enhance' },
        { value: img16, title: 'Tile Texture', passParam: 'tile-texture' },
        { value: img17, title: 'Compound', passParam: 'modeling-compound' },
    ]

    useEffect(() => {
        if (!userInfo) {
            navigate('/login')
        } else {
            dispatch(multiPromptingHistory(userInfo.token, paginatorValue, 10))
            dispatch(getUserSubscriptionDetail(userInfo.token))
        }
    }, [dispatch, navigate, userInfo, paginatorValue]);

    async function handleGenerateClick() {
        dispatch(multiPromptingGeneration(
            userInfo.token,
            prompts,
            weights,
            selectedStyle === 0 ? '' : styles[selectedStyle].passParam,
            aspectRatios.sdxl[selectedAspectRatio].ratio,
            imageCount,
            aspectRatios.sdxl[selectedAspectRatio].size.width,
            aspectRatios.sdxl[selectedAspectRatio].size.height,
            50,
            CFGScale,
            paginatorValue,
            10
        ))
    }

    const handleDownloadAllImages = (prompt_id) => {
        data[prompt_id].image_name_list.forEach(url => {
            fetch('https://www.dandycv.com/media/' + url.image_name)
            .then((response) => response.blob())
            .then((blob) => {
                // Create a Blob object and generate a URL for it
                const url = window.URL.createObjectURL(blob);

                // Create an anchor element for downloading
                const a = document.createElement('a');
                a.href = url;
                a.download = data[prompt_id].prompt + '.jpg'; // Specify the desired file name
                document.body.appendChild(a);

                // Trigger a click event on the anchor element to initiate the download
                a.click();

                // Remove the anchor element from the DOM
                document.body.removeChild(a);
            });
        })
    }

    const handleDownloadImage = (name, url) => (event) => {
        event.stopPropagation();
        setImageFileName(name);
        setImageUrl(url);
        setDownloaderPopup(true);
    }

    const handleDelete = (prompt_id, image_id) => (event) => {
        event.stopPropagation();
        setSelectedPromptId(prompt_id);

        if (image_id !== -1) {
            setSelectedImageId(image_id)
            setDeleteTarget(1)
        } else {
            setDeleteTarget(0)
        }

        setDeleterPopup(true);
    }

    const handleViewPopup = (prompt_id, image_id) => {
        setSelectedPromptId(prompt_id);
        setSelectedImageId(image_id)
        setViewPopup(true)
    }

    return (
        <div className='gnrt-img-container'>
            <div className='gnrt-img-navbar'>
                <img src={logoSmall} alt='logo' />
                <NavLink className='gnrt-img-navbar-title' to={'/my-page'} style={{marginLeft: '10px'}}>
                    Image data
                </NavLink>
                <div className='gnrt-img-navbar-topic' style={{marginLeft: '4px', marginRight: '4px'}}>
                    /
                </div>
                <div className='gnrt-img-navbar-topic' >
                    Multi-Prompting
                </div>
                {userSubscriptionDetail && userInfo && (
                    <>
                        <div className='rmv-bg-navbar-title' style={{marginLeft: '4px', marginRight: '4px', fontWeight: 'normal'}}>
                            /
                        </div>
                        <div className='rmv-bg-navbar-title' style={{fontWeight: 'normal'}}>
                            {userSubscriptionDetail.user_data.subscriptionDetail.credits}&copy;
                        </div>

                    </>
                )}

                <div className='gnrt-img-navbar-close'>
                    <img src={closeBtnImg} alt='' onClick={() => navigate('/my-page')}/>
                </div>
            </div>

            <div className='gnrt-img-main'>
                <div className='gnrt-img-main-sidebar'>
                    <button onClick={() => handleGenerateClick()}>
                        Generate
                    </button>

                    <MultiPromptingSidebar
                        prompts={prompts} setPrompts={setPrompts}
                        weights={weights} setWeights={setWeights}

                        selectedStyle={selectedStyle} setSelectedStyle={setSelectedStyle}
                        selectedAspectRatio={selectedAspectRatio} setSelectedAspectRatio={setSelectedAspectRatio}
                        imageCount={imageCount} setImageCount={setImageCount}
                        CFGScale={CFGScale} setCFGScale={setCFGScale}
                    />

                </div>

                <div className='gnrt-img-main-display'>
                    {loading && (
                        <LoaderTool />
                    )}
                    {
                        error ? (
                            <div>Error</div>
                        ) : (
                            data && (
                                Array.from(data).map((prompt, prompt_idx) => (
                                    <>
                                        <div className='gnrt-img-main-display-header'>
                                            <div>
                                                {prompt.date.slice(0, 19).replace('T', ' ')}
                                            </div>
                                            <div className='gnrt-img-main-display-header-hr'></div>
                                        </div>
                                        <div className='gnrt-img-main-display-header-prompt'>
                                            <div>
                                                {prompt.prompt.map((item, idx) => (
                                                    <div>
                                                        {item.prompt}
                                                    </div>
                                                ))}
                                            </div>

                                            <div style={{marginLeft: '30px', minWidth: '73px'}}>
                                                <img src={downloadImg} alt='download' style={{marginRight: '30px', cursor: 'pointer'}} onClick={() => handleDownloadAllImages(prompt_idx)} />
                                                <img src={deleteImg} alt='delete' style={{cursor: 'pointer'}} onClick={handleDelete(prompt_idx, -1)} />
                                            </div>
                                        </div>

                                        <div className='gnrt-img-main-display-header-img'>
                                            {
                                                Array.from(prompt.image_name_list).map((img_url, img_idx) => (
                                                    <div className='gnrt-img-main-display-header-img-container' onClick={() => handleViewPopup(prompt_idx, img_idx)}>
                                                        <img
                                                            key={img_idx}
                                                            src={`https://www.dandycv.com/media/${img_url.image_name}`}
                                                            alt=''
                                                        />
                                                        <div className='gnrt-img-main-display-header-img-container-dwn-rmv'>
                                                            <img src={downloadGenerateImg} alt='download' style={{marginRight: '30px', cursor: 'pointer'}} onClick={handleDownloadImage('dandycv_multiprompting', img_url.image_name)} />
                                                            <img src={deleteGenerateImg} alt='delete' style={{cursor: 'pointer'}} onClick={handleDelete(prompt_idx, img_idx)} />
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>

                                        <div className='gnrt-img-main-display-header-img-mobile'>
                                            <MobileImageViewer prompt={prompt} prompt_idx={prompt_idx}
                                                setDeleterPopup={setDeleterPopup}
                                                setSelectedPromptId={setSelectedPromptId}
                                                setSelectedImageId={setSelectedImageId}
                                                setDeleteTarget={setDeleteTarget}
                                                multiPrompting={true}
                                            />
                                        </div>
                                    </>
                                ))
                            )
                        )
                    }
                                

                    <div className='rmv-bg-history-table-paginator'>
                        {'<'}
                        {Array.from({ length: totalPaginatorNum }, (_, index) => index + 1).map((number) => (
                            <div
                                className='rmv-bg-history-table-paginator-item'
                                style={{color: paginatorValue === number && '#000'}}
                                onClick={() => setPaginatorValue(number)}
                            >
                            {number}
                            </div>
                        ))}
                        {'>'}
                    </div>
                </div>
                
                {mobileSidebarState ?(
                    <div className='gnrt-img-main-sidebar-mobile'>
                        <div className='gnrt-img-main-sidebar-mobile-img-wrapper' onClick={() => setMobileSidebarState(!mobileSidebarState)}>
                            <img src={arrowImg} alt='' className='gnrt-img-main-sidebar-mobile-img' />
                        </div>
                        

                        <button className='gnrt-img-main-sidebar-mobile-btn'
                            onClick={() => {
                                handleGenerateClick()
                                setMobileSidebarState(!mobileSidebarState)
                            }}
                        >
                            Generate
                        </button>

                        <div className='gnrt-img-main-sidebar-mobile-content'>
                            <MultiPromptingSidebar
                                prompts={prompts} setPrompts={setPrompts}
                                weights={weights} setWeights={setWeights}
                                selectedStyle={selectedStyle} setSelectedStyle={setSelectedStyle}
                                selectedAspectRatio={selectedAspectRatio} setSelectedAspectRatio={setSelectedAspectRatio}
                                imageCount={imageCount} setImageCount={setImageCount}
                                CFGScale={CFGScale} setCFGScale={setCFGScale}
                            />
                        </div>
                    </div>
                ) : (
                    <div className='gnrt-img-main-sidebar-mobile-nav'>
                        <img src={arrowNavImg} alt=''
                            onClick={() => setMobileSidebarState(!mobileSidebarState)}
                        />
                    </div>
                )}
            </div>


            <ViewPopup trigger={viewPopup} setTrigger={setViewPopup}
                deleterPopup={deleterPopup} setDeleterPopup={setDeleterPopup}
                data={data}
                setDeleteTarget={setDeleteTarget}
                selectedPromptId={selectedPromptId} setSelectedPromptId={setSelectedPromptId}
                selectedImageId={selectedImageId} setSelectedImageId={setSelectedImageId}
                multiPrompting={true}
            />

            <DownloadPopup trigger={downloaderPopup} setTrigger={setDownloaderPopup}
                imageFileName={imageFileName} setImageFileName={setImageFileName}
                imageUrl={imageUrl} setImageUrl={setImageUrl}
            />

            

            <DeletePopup trigger={deleterPopup} setTrigger={setDeleterPopup} setViewPopup={setViewPopup}
                data={data}
                deleteTarget={deleteTarget} selectedPromptId={selectedPromptId} selectedImageId={selectedImageId}
                paginatorValue={paginatorValue} itemsPerPage={10}
                multiPrompting={true}
            />
            
        </div>
    )
}

export default MultiPromptingScreen
