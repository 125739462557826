export const TXT_2_IMG_GET_REQUEST = 'TXT_2_IMG_GET_REQUEST'
export const TXT_2_IMG_GET_SUCCESS = 'TXT_2_IMG_GET_SUCCESS'
export const TXT_2_IMG_GET_FAIL = 'TXT_2_IMG_GET_FAIL'

export const TXT_2_IMG_POST_REQUEST = 'TXT_2_IMG_POST_REQUEST'
export const TXT_2_IMG_POST_SUCCESS = 'TXT_2_IMG_POST_SUCCESS'
export const TXT_2_IMG_POST_FAIL = 'TXT_2_IMG_POST_FAIL'

export const TXT_2_IMG_DELETE_REQUEST = 'TXT_2_IMG_DELETE_REQUEST'
export const TXT_2_IMG_DELETE_SUCCESS = 'TXT_2_IMG_DELETE_SUCCESS'
export const TXT_2_IMG_DELETE_FAIL = 'TXT_2_IMG_DELETE_FAIL'