import React from 'react';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import logoSmall from '../../images/logo-small.jpg'
import closeBtnImg from '../../images/close-btn.svg'

function TermsScreen() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div>
            <div className='gnrt-img-navbar'>
                <img src={logoSmall} alt='logo' />
                <div className='gnrt-img-navbar-title' style={{marginLeft: '10px'}}>
                    Privacy Terms and Conditions of Use
                </div>

                <div className='gnrt-img-navbar-close'>
                    <img src={closeBtnImg} alt='' onClick={() => navigate(-1)}/>
                </div>
            </div>

            <div style={{marginTop: '30px', marginLeft: '20px', marginRight: '20px', fontSize: '14px', lineHeight: '26px'}}>
                <div>{parse(t('terms_article_1'))}</div>
                <div>{parse(t('terms_article_2'))}</div>
                <div>{parse(t('terms_article_3'))}</div>
                <div>{parse(t('terms_article_4'))}</div>
                <div>{parse(t('terms_article_5'))}</div>
                <div>{parse(t('terms_article_6'))}</div>
                <div>{parse(t('terms_article_7'))}</div>
                <div>{parse(t('terms_article_8'))}</div>
                <div>{parse(t('terms_article_9'))}</div>
                <div>{parse(t('terms_article_10'))}</div>
                <div>{parse(t('terms_article_11'))}</div>
                <div>{parse(t('terms_article_12'))}</div>
                <div>{parse(t('terms_article_13'))}</div>
                <div>{parse(t('terms_article_14'))}</div>
                <div>{parse(t('terms_article_15'))}</div>
                <div>{parse(t('terms_article_16'))}</div>
            </div>

        </div>
    )
}

export default TermsScreen
