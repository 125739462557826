import React from 'react'

function ImageViewPopupAdmin(props) {
    const styles = {
        overlay: {
            position: 'fixed',
            overflow: 'auto',
            top: '0',
            left: '0',
            width: '100vw',
            height: '100vh',
            background: 'rgba(0, 0, 0, 0.2)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: '100',
        },
        image: {
            objectFit: 'contain',
            maxWidth: 'calc(100vw*1729/1920)',
            maxHeight: 'calc(100vh*1300/1531)'
        }
    }

    return (props.trigger) ? (
        <div style={styles.overlay} onClick={() => props.setTrigger(false)}>
            <div onClick={(e) => e.stopPropagation()}>
                <img src={props.imageSrc} alt='' style={styles.image}/>
            </div>
        </div>
    ) : ''
}

export default ImageViewPopupAdmin
