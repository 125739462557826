import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { userLoginReducers, userRegisterReducers, getUserSubscriptionDetailReducers } from './reducers/userReducers'
import { getPostsDataReducers, getPostWithIdDataReducers } from './reducers/postReducers'
import { adminGetAllPostsReducers, adminPostUploadReducers, adminGenerateImageReducers } from './reducers/adminReducers'
import { getImg2ImgDataReducers } from './reducers/img2imgReducers'
import { getTxt2ImgDataReducers } from './reducers/txt2imgReducers'
import { getUpscalingDataReducers } from './reducers/upscalingReducers'
import { getMultiPromptingDataReducers } from './reducers/multiPromptingReducers'

const reducer = combineReducers({
    userLogin: userLoginReducers,
    userRegister: userRegisterReducers,
    userSubscriptionDetail: getUserSubscriptionDetailReducers,
    getPostData: getPostsDataReducers,
    getPostDataWithId: getPostWithIdDataReducers,
    getTxt2ImgData: getTxt2ImgDataReducers,
    getImg2ImgData: getImg2ImgDataReducers,
    getUpscalingData: getUpscalingDataReducers,
    getMultiPromptingData: getMultiPromptingDataReducers,
    adminAllPostData: adminGetAllPostsReducers,
    adminPostUploadData: adminPostUploadReducers,
    adminGenerateImageData: adminGenerateImageReducers,
})

const userInfoFromStorage = localStorage.getItem('userInfo') ?
    JSON.parse(localStorage.getItem('userInfo')) : ''

const initialState = {
    userLogin: { userInfo: userInfoFromStorage },
}

const middleware = [thunk]

const store = createStore(reducer, initialState,
    composeWithDevTools(applyMiddleware(...middleware)))

export default store