import React, { useState } from 'react'
import axios from 'axios'
import { useSelector } from 'react-redux'

import closeBtnImg from '../images/close-btn.svg'

function RemoveBackgroundDownloadPopup(props) {

  const [increaseResolution, setIncreaseResolution] = useState(false)
  const [categoryJPG, setCategoryJPG] = useState(true)

  const userLogin = useSelector(state => state.userLogin)
  const {userInfo} = userLogin

  function hexToRgb(hex) {
    // Remove the hash symbol (#) if it's present
    hex = hex.replace(/^#/, '');

    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return [ r, g, b ];
  }

  const processImage = async (lowQuality=false) => {

    try {
      var currentdate = new Date();

      const formData = new FormData();

      formData.append('save', true);
      formData.append('username', userInfo.username);
      formData.append('imageName', props.imageFileName);
      formData.append('date', currentdate.getTime() / 1000);
      formData.append('imageCategory', categoryJPG ? 'jpg' : 'png');
      formData.append('imageSize', props.imageFile.size);
      formData.append('imageResolution', props.imageFileResolution);
      formData.append('image', props.imageFile);

      if (lowQuality) {
        formData.append('lowQuality', lowQuality);
      }

      if (props.backgroundColorPicker && props.backgroundPickerState === 0) {
        formData.append('rgbVal', hexToRgb(props.backgroundColorPicker))
      }

      if (props.backgroundPickerState === 1) {
        const imageResponse = await axios.get(props.backgroundImagePicker, { responseType: 'arraybuffer' });
        const imageData = imageResponse.data;
        formData.append('bgImage', new Blob([imageData]));
      }

      axios.post(
        'remove_background/api/',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then(response => {
        const modifiedImage = response.data.modifiedImage;
        downloadImage(modifiedImage)
      })
      .catch((error) => {
        console.error('Error uploading image', error);
      })

    } catch (error) {
      console.error('Error fetching image data:', error);
    }
  }

  const downloadImage = (img) => {
    const link = document.createElement('a');
    link.href = 'data:application/octet-stream;base64,' + img;
    link.download = props.imageFileName + (categoryJPG ? '.jpg' : '.png'); // Specify the desired file name
    link.click();
  };

  return (props.trigger) ? (
    <div className='popup-color-picker-overlay' onClick={() => props.setTrigger(false)}>
            <div className='popup-downloader-content' onClick={(e) => {e.stopPropagation()}}>
              <div className='popup-downloader-header'>
                <div className="popup-downloader-title">Download</div>

                <img src={closeBtnImg} alt='' style={{cursor: 'pointer'}} onClick={() => props.setTrigger(false)} />
              </div>
              <div className='hr' style={{marginLeft: '16px', marginRight: '16px'}} />

              <div style={{marginTop: '16px', marginLeft: '16px'}}>
                Name
              </div>

              <input type='text' style={{marginTop: '10px', marginLeft: '16px'}} value={props.imageFileName} onChange={(e) => props.setImageFileName(e.target.value)}/>

              <div className='popup-downloader-resolution'>

                <div>
                  Increase resolution
                </div>

                <div style={{display: 'flex'}}>
                  <div className={!increaseResolution ? 'popup-downloader-selector-active' : 'popup-downloader-selector'} style={{marginRight: '5px'}} onClick={() => setIncreaseResolution(false)}>x1</div>
                  <div className={increaseResolution ? 'popup-downloader-selector-active' : 'popup-downloader-selector'}  onClick={() => setIncreaseResolution(true)}>x2</div>
                </div>

              </div>

              <div className='popup-downloader-resolution'>

                <div>
                  Category
                </div>

                <div style={{display: 'flex'}}>
                  <div className={categoryJPG ? 'popup-downloader-selector-active' : 'popup-downloader-selector'} style={{marginRight: '5px'}} onClick={() => setCategoryJPG(true)}>JPG</div>
                  <div className={!categoryJPG ? 'popup-downloader-selector-active' : 'popup-downloader-selector'} onClick={() => setCategoryJPG(false)}>PNG</div>
                </div>

              </div>

              <button onClick={() => processImage(true)}>
                Free (Low resolution)
              </button>

              <button style={{marginTop: '8px'}} onClick={() => processImage()}>
                Original
              </button>

                
                
            </div>
        </div>
  ) : "";
}

export default RemoveBackgroundDownloadPopup
