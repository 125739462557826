import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../../actions/userActions'
import { generateAdminImages, uploadAdminPost } from '../../actions/adminActions'

import PostsAdminSidebar from './PostsAdminSidebar'
import LoaderTool from '../../components/LoaderTool'

import './PostsAdminScreen.css'

import logoSmall from '../../images/logo-small.jpg'
import closeBtnImg from '../../images/close-btn.svg'
import downloadImg from '../../images/drag&drop_download.svg'
import downloadGenerateImg from '../../images/generate_download.svg'
import arrowImg from '../../images/generate-image-arrow.svg'
import arrowNavImg from '../../images/generate-image-arrow-nav.svg'

import addImg from '../../images/admin/add.svg'

import img0 from '../../images/generate-images/img0.jpg'
import img1 from '../../images/generate-images/img1.jpg'
import img2 from '../../images/generate-images/img2.jpg'
import img3 from '../../images/generate-images/img3.jpg'
import img4 from '../../images/generate-images/img4.jpg'
import img5 from '../../images/generate-images/img5.jpg'
import img6 from '../../images/generate-images/img6.jpg'
import img7 from '../../images/generate-images/img7.jpg'
import img8 from '../../images/generate-images/img8.jpg'
import img9 from '../../images/generate-images/img9.jpg'
import img10 from '../../images/generate-images/img10.jpg'
import img11 from '../../images/generate-images/img11.jpg'
import img12 from '../../images/generate-images/img12.jpg'
import img13 from '../../images/generate-images/img13.jpg'
import img14 from '../../images/generate-images/img14.jpg'
import img15 from '../../images/generate-images/img15.jpg'
import img16 from '../../images/generate-images/img16.jpg'
import img17 from '../../images/generate-images/img17.jpg'

function PostsAdminScreen() {
    const inputRef = useRef();
    const thumbnailKRRef = useRef();
    const thumbnailENRef = useRef();
    const avatarRef = useRef();

    const videoUploadRef = useRef();
    const videoThumbnailRef = useRef();

    const [isDraggingOverVideo, setDraggingOverVideo] = useState(false);
    const [isDraggingOverVideoThumbnail, setDraggingOverVideoThumbnail] = useState(false);

    const [isDraggingOver, setDraggingOver] = useState(false);
    const [uploadVideoFiles, setUploadVideoFiles] = useState([]);
    const [uploadVideoThumbnailFiles, setUploadVideoThumbnailFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);

    const [mobileSidebarState, setMobileSidebarState] = useState(false);

    const navigate = useNavigate()

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const [turboSelected, setTurboSelected] = useState(false)
    const [prompt, setPrompt] = useState()
    const [negativePrompt, setNegativePrompt] = useState('')
    const [selectedStyle, setSelectedStyle] = useState(0)
    const [selectedAspectRatio, setSelectedAspectRatio] = useState(0)
    const [imageCount, setImageCount] = useState(1)
    const [iterationCount, setIterationCount] = useState(1)
    const [CFGScaleGenerator, setCFGScaleGenerator] = useState(4.0)
    const [CFGScaleDecoder, setCFGScaleDecoder] = useState(0.0)

    // SAVE STATES
    const [thumbnailImgDataKR, setThumbnailImgDataKR] = useState();
    const [thumbnailImgDataEN, setThumbnailImgDataEN] = useState();
    const [avatarImgData, setAvatarImgData] = useState();
    const [saveTitleEN, setSaveTitleEN] = useState('')
    const [saveTitleKR, setSaveTitleKR] = useState('')
    const [selectedSaveCategory, setSelectedSaveCategory] = useState(0)
    const [saveSubheadingEN, setSaveSubheadingEN] = useState('')
    const [saveSubheadingKR, setSaveSubheadingKR] = useState('')
    const [saveSummaryEN, setSaveSummaryEN] = useState('')
    const [saveSummaryKR, setSaveSummaryKR] = useState('')
    const [saveBackgroundEN, setSaveBackgroundEN] = useState('')
    const [saveBackgroundKR, setSaveBackgroundKR] = useState('')

    const [saveYoutubeURL, setSaveYoutubeURL] = useState('')
    const [saveInstagramURL, setSaveInstagramURL] = useState('')
    const [saveTiktokURL, setSaveTiktokURL] = useState('')
    const [saveNaverBlogURL, setSaveNaverBlogURL] = useState('')

    const categorySave = [
        { kr: '역사', en: 'history' },
        { kr: '성경', en: 'bible' },
        { kr: '키즈', en: 'kids' },
        { kr: '경제', en: 'economy' },
        { kr: '경영', en: 'management' },
        { kr: '인문', en: 'humanities' },
        { kr: '과학', en: 'science' },
        { kr: '자기계발', en: 'self-improvement' },
        { kr: '문화', en: 'culture' }
    ]

    const aspectRatios = {
        sdxl: [
            { ratio: '21:9', size: { width: 1536, height: 640 }},
            // { ratio: '16:9', size: { width: 1344, height: 768 }},
            // { ratio: '3:2', size: { width: 1216, height: 832 }},
            { ratio: '5:4', size: { width: 1152, height: 896 }},
            { ratio: '4:5', size: { width: 896, height: 1152 }},
            // { ratio: '2:3', size: { width: 832, height: 1216 }},
            // { ratio: '9:16', size: { width: 768, height: 1344 }},
            { ratio: '9:21', size: { width: 640, height: 1536 }},
            { ratio: '1:1', size: { width: 1024, height: 1024 }},
            { ratio: '1:1', size: { width: 1536, height: 1536 }},
            { ratio: '3:2', size: { width: 2432, height: 1408 }},
            { ratio: '2:3', size: { width: 1408, height: 2432 }},
        ]
    }

    const styles = [
        { value: img0, title: 'No Style' },
        { value: img1, title: 'Anime' },
        { value: img2, title: 'Photographic' },
        { value: img3, title: 'Digital Art' },
        { value: img4, title: 'Comic Book' },
        { value: img5, title: 'Fantasy Art' },
        { value: img6, title: 'Analog Film' },
        { value: img7, title: 'Neon Punk' },
        { value: img8, title: 'Isometric' },
        { value: img9, title: 'Low Poly' },
        { value: img10, title: 'Origami' },
        { value: img11, title: 'Line Art' },
        { value: img12, title: 'Cinematic' },
        { value: img13, title: '3D Model' },
        { value: img14, title: 'Pixel Art' },
        { value: img15, title: 'Enhance' },
        { value: img16, title: 'Tile Texture' },
        { value: img17, title: 'Compound' },
    ]

    const dispatch = useDispatch()

    const adminPostUploadData = useSelector(state => state.adminPostUploadData)
    const { loading, error } = adminPostUploadData

    const adminGenerateImageData = useSelector(state => state.adminGenerateImageData)
    const { loading: generateLoading, error: generateError, generateImageData } = adminGenerateImageData

    useEffect(() => {
        if (!userInfo.isAdmin) {
            navigate('/')
        }
    }, [userInfo, navigate]);

    const handleDownloadImage = (idx) => (event) => {
        event.stopPropagation();

        var a = document.createElement("a");
        a.href = `data:image/jpeg;base64,${generateImageData[idx]}`;
        a.download = `result${idx+1}.jpg`;
        a.click();
    }

    const handleDownloadAllImages = () => {
        generateImageData.forEach((image_base64, idx) => {
            var a = document.createElement("a");
            a.href = `data:image/jpeg;base64,${image_base64}`;
            a.download = `result${idx+1}.jpg`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        })
    }

    const handleDragOver = (event, videos=false, thumbnail=false) => {
        event.preventDefault();

        if (videos) {
            setDraggingOverVideo(true);
        } else if (thumbnail) {
            setDraggingOverVideoThumbnail(true)
        } else {
            setDraggingOver(true);
        }
    }

    const handleDragLeave = (videos=false, thumbnail=false) => {
        if (videos) {
            setDraggingOverVideo(false);
        } else if (thumbnail) {
            setDraggingOverVideoThumbnail(false)
        } else {
            setDraggingOver(false);
        }
    };

    function handleThumbnailUpload(event, languageKR=true) {
        event.preventDefault()
        const file = event.target.files[0]

        if (languageKR) {
            setThumbnailImgDataKR(file)
        } else {
            setThumbnailImgDataEN(file)
        }
    }

    function handleAvatarUpload(event) {
        event.preventDefault()
        const file = event.target.files[0]

        setAvatarImgData(file)
    }

    async function handleOnDrop(event, drop=false, video=false, thumbnail=false) {
        event.preventDefault();

        const files = drop ? event.target.files : event.dataTransfer.files

        var tempData = []

        Array.from(files).forEach(file => {
            if (isAcceptedFile(file, video=video)) {
                tempData.push(file)
            }
        });

        if (video) {
            setDraggingOverVideo(false);
            setUploadVideoFiles([...uploadVideoFiles, ...tempData])
        } else if (thumbnail) {
            setDraggingOverVideoThumbnail(false);
            setUploadVideoThumbnailFiles([...uploadVideoThumbnailFiles, ...tempData])
        } else {
            setDraggingOver(false);
            setUploadedFiles([...uploadedFiles, ...tempData])
        }
    }

    const handleOnReplaceImage = (event, idx) => {
        event.preventDefault();

        const currFile = event.target.files[0]

        const newData = [...uploadedFiles]

        newData[idx] = currFile

        setUploadedFiles([...newData])
    }

    function isAcceptedFile(file, video=false) {
        let acceptedImageTypes = ['image/png', 'image/jpeg']

        if (video) {
            acceptedImageTypes = ['video/mp4']
        }
        return file && acceptedImageTypes.includes(file['type'])
    }

    const handleGenerateClick = () => {
        if (!loading) {
            dispatch(
                generateAdminImages(
                    userInfo.username,
                    turboSelected,
                    prompt,
                    negativePrompt,
                    selectedStyle === 0 ? '' : styles[selectedStyle].title,
                    imageCount,
                    turboSelected ? aspectRatios.turbo[0].size.width : aspectRatios.sdxl[selectedAspectRatio].size.width,
                    turboSelected ? aspectRatios.turbo[0].size.height : aspectRatios.sdxl[selectedAspectRatio].size.height,
                    turboSelected ? iterationCount : 50,
                    CFGScaleGenerator ? CFGScaleGenerator : 0.0,
                    CFGScaleDecoder ? CFGScaleDecoder : 0.0
                )
            )
        }
    }

    const handleSaveBtnClick = async () => {
        if (thumbnailImgDataEN && thumbnailImgDataKR && uploadVideoFiles.length === uploadVideoThumbnailFiles.length) {
            dispatch(
                uploadAdminPost(
                    userInfo.token,
                    thumbnailImgDataEN,
                    thumbnailImgDataKR,
                    avatarImgData,
                    saveTitleEN,
                    saveTitleKR,
                    categorySave[selectedSaveCategory].en,
                    categorySave[selectedSaveCategory].kr,
                    saveSubheadingEN,
                    saveSubheadingKR,
                    saveSummaryEN.replace(/\n/g, '\\n'),
                    saveSummaryKR.replace(/\n/g, '\\n'),
                    saveBackgroundEN.replace(/\n/g, '\\n'),
                    saveBackgroundKR.replace(/\n/g, '\\n'),
                    uploadVideoFiles,
                    uploadVideoThumbnailFiles,
                    uploadedFiles,
                    saveYoutubeURL,
                    saveInstagramURL,
                    saveTiktokURL,
                    saveNaverBlogURL
                )
            ).then(() => {
                navigate('/admin-posts')
            })
        }
    }

    const clearAllSaveStates = () => {
        setUploadedFiles([]);
        setThumbnailImgDataEN();
        setThumbnailImgDataKR();
        setAvatarImgData();
        setSaveTitleEN('');
        setSaveTitleKR('');
        setSaveSubheadingEN('');
        setSaveSubheadingKR('');
        setSaveSummaryEN('');
        setSaveSummaryKR('');
        setSaveBackgroundEN('');
        setSaveBackgroundKR('');
    }

    return (
        <div className='gnrt-img-container'>
            <div className='gnrt-img-navbar'>
                <img src={logoSmall} alt='logo' />
                <NavLink className='gnrt-img-navbar-title' to={'/my-page'} style={{marginLeft: '10px'}}>
                    Admin Page
                </NavLink>
                <div className='gnrt-img-navbar-topic' style={{marginLeft: '4px', marginRight: '4px'}}>
                    /
                </div>
                <div className='gnrt-img-navbar-topic' >
                    Posts
                </div>
                <div className='gnrt-img-navbar-topic' style={{marginLeft: '4px', marginRight: '4px'}}>
                    /
                </div>
                <div className='gnrt-img-navbar-topic' >
                    Add
                </div>

                <div className='gnrt-img-navbar-close'>
                    <img src={closeBtnImg} alt='' onClick={() => navigate('/admin-posts')}/>
                </div>
            </div>
            
            <div className='gnrt-img-main'>
                <div className='gnrt-img-main-sidebar'>
                    <button onClick={() => handleGenerateClick()}>
                        Generate
                    </button>

                    <PostsAdminSidebar
                        turboSelected={turboSelected} setTurboSelected={setTurboSelected}
                        prompt={prompt} setPrompt={setPrompt}
                        negativePrompt={negativePrompt} setNegativePrompt={setNegativePrompt}
                        selectedStyle={selectedStyle} setSelectedStyle={setSelectedStyle}
                        selectedAspectRatio={selectedAspectRatio} setSelectedAspectRatio={setSelectedAspectRatio}
                        imageCount={imageCount} setImageCount={setImageCount}
                        iterationCount={iterationCount} setIterationCount={setIterationCount}
                        CFGScaleGenerator={CFGScaleGenerator} setCFGScaleGenerator={setCFGScaleGenerator}
                        CFGScaleDecoder={CFGScaleDecoder} setCFGScaleDecoder={setCFGScaleDecoder}
                    />

                </div>

                <div className='gnrt-img-main-display' style={{overflowX: 'hidden'}}>
                    {generateLoading ? (
                        <LoaderTool />
                    ) : (
                        generateError ? (
                            <div>Error</div>
                        ) : (
                            generateImageData && (
                                <>
                                    <div className='gnrt-img-main-display-header'>
                                        <div style={{fontSize: '30px'}}>
                                            Result
                                        </div>
                                        <div className='gnrt-img-main-display-header-hr'></div>
                                    </div>
                                    <div className='gnrt-img-main-display-header-prompt'>
                                        <div>
                                            {/* {prompt} */}
                                        </div>

                                        <div style={{marginLeft: '30px', minWidth: '73px'}}>
                                            <img src={downloadImg} alt='download' style={{cursor: 'pointer'}} onClick={() => handleDownloadAllImages()} />
                                        </div>
                                    </div>

                                    <div className='gnrt-img-main-display-header-img'>
                                    {
                                        generateImageData.map((image, idx) => (
                                            <div key={idx} className='gnrt-img-main-display-header-img-container'>
                                                <img
                                                    src={`data:image/jpeg;base64,${image}`}
                                                    alt=''
                                                />
                                                <div className='gnrt-img-main-display-header-img-container-dwn-rmv'>
                                                    <img src={downloadGenerateImg} alt='download' style={{marginRight: '30px', cursor: 'pointer'}} onClick={handleDownloadImage(idx)} />
                                                </div>
                                            </div>
                                        ))
                                    }
                                    </div>
                                </>
                            )
                        )
                    )}

                    <div className='gnrt-img-main-display-header' style={{justifyContent: 'space-between'}}>
                        <div style={{fontSize: '30px'}}>
                            Upload Content
                        </div>

                        <button className='bible-admin-save-btn' style={{cursor: loading && error && 'default'}} onClick={handleSaveBtnClick}>
                            Upload
                        </button>
                        
                    </div>

                    <div className='bible-admin-container' style={{marginTop: '40px'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div style={{marginBottom: '10px'}}>
                                1. 썸네일 KR
                            </div>
                            <div className='bible-admin-uploaded-div' style={{width: '130px', height: '130px', cursor: 'pointer'}} onClick={() => thumbnailKRRef.current.click()}>
                                <input
                                    type='file'
                                    onChange={(event) => handleThumbnailUpload(event, true)}
                                    hidden
                                    ref={thumbnailKRRef}
                                />
                                {thumbnailImgDataKR ? (
                                    <img src={URL.createObjectURL(thumbnailImgDataKR)} alt='' style={{width: '130px', height: '130px', border: '5px'}}></img>
                                ) : (
                                    <img src={addImg} alt='' />
                                )}
                                
                            </div>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', marginLeft: '30px'}}>
                            <div style={{marginBottom: '10px'}}>
                                1. 썸네일 EN
                            </div>
                            <div className='bible-admin-uploaded-div' style={{width: '130px', height: '130px', cursor: 'pointer'}} onClick={() => thumbnailENRef.current.click()}>
                                <input
                                    type='file'
                                    onChange={(event) => handleThumbnailUpload(event, false)}
                                    hidden
                                    ref={thumbnailENRef}
                                />
                                {thumbnailImgDataEN ? (
                                    <img src={URL.createObjectURL(thumbnailImgDataEN)} alt='' style={{width: '130px', height: '130px', border: '5px'}}></img>
                                ) : (
                                    <img src={addImg} alt='' />
                                )}
                                
                            </div>
                        </div>
                    </div>

                    <div className='bible-admin-container' style={{marginTop: '40px'}}>

                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div style={{marginBottom: '10px'}}>
                                2. 프로필이미지
                            </div>
                            <div className='bible-admin-uploaded-div' style={{width: '130px', height: '130px', borderRadius: '100px', cursor: 'pointer'}} onClick={() => avatarRef.current.click()}>
                                <input
                                    type='file'
                                    onChange={(event) => handleAvatarUpload(event)}
                                    hidden
                                    ref={avatarRef}
                                />
                                {avatarImgData ? (
                                    <img src={URL.createObjectURL(avatarImgData)} alt='' style={{width: '130px', height: '130px', borderRadius: '100px'}}></img>
                                ) : (
                                    <img src={addImg} alt='' />
                                )}
                            </div>
                        </div>
                        
                        <div style={{width: '100%'}}>
                            <div style={{display: 'flex', width: '100%'}}>
                                <div className='bible-admin-split' style={{marginRight: '5px', marginLeft: '30px'}}>
                                    <div>
                                        3. 제목 (search data) - KR
                                    </div>
                                    <textarea style={{width: 'calc(100% - 40px)', height: '112px', marginTop: '8px'}} value={saveTitleKR} onChange={(e) => setSaveTitleKR(e.target.value)}/>
                                </div>
                                <div className='bible-admin-split' style={{marginLeft: '5px'}}>
                                    <div>
                                        3. 제목 (search data) - EN
                                    </div>
                                    <textarea style={{width: 'calc(100% - 40px)', height: '112px', marginTop: '8px'}} value={saveTitleEN} onChange={(e) => setSaveTitleEN(e.target.value)}/>
                                </div>
                            </div>
                            
                        </div>

                    </div>

                    <div className='bible-admin-container' style={{marginTop: '40px', flexDirection: 'column'}}>
                        <div>
                            4.
                        </div>
                        <div className='bible-admin-split' style={{marginRight: '5px', marginTop: '20px'}}>
                            <div>Youtube URL</div>
                            <textarea style={{width: 'calc(100% - 40px)', height: '22px', marginTop: '5px'}} value={saveYoutubeURL} onChange={(e) => setSaveYoutubeURL(e.target.value)}/>
                        </div>
                        <div className='bible-admin-split' style={{marginRight: '5px'}}>
                            <div>Instagram URL</div>
                            <textarea style={{width: 'calc(100% - 40px)', height: '22px', marginTop: '5px'}} value={saveInstagramURL} onChange={(e) => setSaveInstagramURL(e.target.value)}/>
                        </div>
                        <div className='bible-admin-split' style={{marginRight: '5px'}}>
                            <div>Tiktok URL</div>
                            <textarea style={{width: 'calc(100% - 40px)', height: '22px', marginTop: '5px'}} value={saveTiktokURL} onChange={(e) => setSaveTiktokURL(e.target.value)}/>
                        </div>
                        <div className='bible-admin-split' style={{marginRight: '5px'}}>
                            <div>Naver Blog URL</div>
                            <textarea style={{width: 'calc(100% - 40px)', height: '22px', marginTop: '5px'}} value={saveNaverBlogURL} onChange={(e) => setSaveNaverBlogURL(e.target.value)}/>
                        </div>
                    </div>

                    <div className='bible-admin-container' style={{marginTop: '40px'}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div style={{marginBottom: '10px'}}>
                                5. 카테고리 (필수)
                            </div>
                            
                            <div style={{display: 'flex'}}>
                                {categorySave.map((category, idx) => (
                                    <div key={idx} className='bible-admin-categories-item' onClick={() => setSelectedSaveCategory(idx)} style={{marginRight: '5px', borderColor: selectedSaveCategory === idx && '#B4307D', color: selectedSaveCategory === idx && '#B4307D'}}>
                                        {category.kr}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className='bible-admin-container' style={{marginTop: '40px'}}>
                        <div className='bible-admin-split' style={{marginRight: '5px'}}>
                            <div>
                                6. 소제목 (search data) - KR
                            </div>
                            <textarea style={{width: 'calc(100% - 40px)', height: '22px', marginTop: '5px'}} value={saveSubheadingKR} onChange={(e) => setSaveSubheadingKR(e.target.value)}/>
                        </div>
                        <div className='bible-admin-split' style={{marginLeft: '5px'}}>
                            <div>
                                6. 소제목 (search data) - EN
                            </div>
                            <textarea style={{width: 'calc(100% - 40px)', height: '22px', marginTop: '5px'}} value={saveSubheadingEN} onChange={(e) => setSaveSubheadingEN(e.target.value)}/>
                        </div>
                    </div>

                    <div className='bible-admin-container' style={{marginTop: '40px'}}>
                        <div className='bible-admin-split' style={{marginRight: '5px'}}>
                            <div>
                                7. 요약(핵심내용) - KR
                            </div>
                            <textarea style={{width: 'calc(100% - 40px)', height: '200px', marginTop: '5px'}} value={saveSummaryKR} onChange={(e) => setSaveSummaryKR(e.target.value)}/>
                        </div>
                        <div className='bible-admin-split' style={{marginLeft: '5px'}}>
                            <div>
                                7. 요약(핵심내용) - EN
                            </div>
                            <textarea style={{width: 'calc(100% - 40px)', height: '200px', marginTop: '5px'}} value={saveSummaryEN} onChange={(e) => setSaveSummaryEN(e.target.value)}/>
                        </div>
                    </div>

                    <div className='bible-admin-container' style={{marginTop: '40px'}}>
                        <div className='bible-admin-split' style={{marginRight: '5px'}}>
                            <div>
                                8. 배경 등 설명 - KR
                            </div>
                            <textarea style={{width: 'calc(100% - 40px)', height: '200px', marginTop: '5px'}} value={saveBackgroundKR} onChange={(e) => setSaveBackgroundKR(e.target.value)}/>
                        </div>
                        <div className='bible-admin-split' style={{marginLeft: '5px'}}>
                            <div>
                                8. 배경 등 설명 - EN
                            </div>
                            <textarea style={{width: 'calc(100% - 40px)', height: '200px', marginTop: '5px'}} value={saveBackgroundEN} onChange={(e) => setSaveBackgroundEN(e.target.value)}/>
                        </div>
                    </div>

                    {/* const videoUploadRef = useRef();
                    const videoThumbnailRef = useRef();

                    const [isDraggingOverVideo, setDraggingOverVideo] = useState(false);
                    const [isDraggingOverVideoThumbnail, setDraggingOverVideoThumbnail] = useState(false); */}

                    <div className='bible-admin-container' style={{marginTop: '40px'}}>
                            <div className='bible-admin-split' style={{marginRight: '5px'}}>
                                <div>
                                    9. Video
                                </div>
                                
                                <div
                                    className='bible-admin-draganddrop-div'
                                    style={
                                        {
                                            width: 'calc(100% - 20px)',
                                            height: '325px',
                                            marginTop: '10px',
                                            padding: '10px',
                                            marginBottom: '42px',
                                            borderColor: isDraggingOverVideo && '#B4307D',
                                            color: isDraggingOverVideo && '#B4307D'
                                        }
                                    }
                                    onDragOver={(e) => handleDragOver(e, true, false)}
                                    onDragLeave={() => handleDragLeave(true, false)}
                                    onDrop={(event) => handleOnDrop(event, false, true, false)}
                                >
                                    <input
                                        type='file'
                                        onChange={(event) => handleOnDrop(event, true, true, false)}
                                        hidden
                                        accept="video/mp4"
                                        ref={videoUploadRef}
                                        multiple
                                    />

                                    <div className='bible-admin-draganddrop-div-grid'>
                                        <div className='bible-admin-uploaded-div' style={{width: '89px', height: '89px', cursor: 'pointer'}} onClick={() => videoUploadRef.current.click()}>
                                            <img src={addImg} alt='' />
                                        </div>

                                        {
                                            uploadVideoFiles.map((file, file_idx) => (
                                                <div key={file_idx} className='bible-admin-uploaded-div'>
                                                    <video>
                                                        <source src={URL.createObjectURL(file)} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className='bible-admin-split' style={{marginRight: '5px'}}>
                                <div>
                                    9. Video's Thumbnail
                                </div>
                                
                                <div
                                    className='bible-admin-draganddrop-div'
                                    style={
                                        {
                                            width: 'calc(100% - 20px)',
                                            height: '325px',
                                            marginTop: '10px',
                                            padding: '10px',
                                            marginBottom: '42px',
                                            borderColor: isDraggingOverVideoThumbnail && '#B4307D',
                                            color: isDraggingOverVideoThumbnail && '#B4307D'
                                        }
                                    }
                                    onDragOver={(e) => handleDragOver(e, false, true)}
                                    onDragLeave={() => handleDragLeave(false, true)}
                                    onDrop={(event) => handleOnDrop(event, false, false, true)}
                                >
                                    <input
                                        type='file'
                                        onChange={(event) => handleOnDrop(event, true, false, true)}
                                        hidden
                                        accept="image/png, image/jpeg"
                                        ref={videoThumbnailRef}
                                        multiple
                                    />

                                    <div className='bible-admin-draganddrop-div-grid'>
                                        <div className='bible-admin-uploaded-div' style={{width: '89px', height: '89px', cursor: 'pointer'}} onClick={() => videoThumbnailRef.current.click()}>
                                            <img src={addImg} alt='' />
                                        </div>

                                        {
                                            uploadVideoThumbnailFiles.map((file, file_idx) => (
                                                <div key={file_idx} className='bible-admin-uploaded-div'>
                                                    <img src={URL.createObjectURL(file)} alt='' />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                    </div>
                    
                    <div className='bible-admin-container' style={{marginTop: '40px'}}>
                        <div style={{width: '100%', marginTop: '25px'}}>
                            <div className='bible-admin-split' style={{marginRight: '5px', width: 'calc(100%)'}}>
                                <div>
                                    10. 관련 이미지들
                                </div>

                                <div style={{display: 'flex', justifyContent: 'right'}}>
                                    <button
                                        style={{marginTop: '40px'}}
                                        className='bible-admin-split-reset-btn'
                                        onClick={() => setUploadedFiles([])}
                                    >
                                        Reset
                                    </button>
                                </div>
                                
                                <div
                                    className='bible-admin-draganddrop-div'
                                    style={
                                        {
                                            width: 'calc(100% - 20px)',
                                            height: '325px',
                                            marginTop: '10px',
                                            padding: '10px',
                                            marginBottom: '42px',
                                            borderColor: isDraggingOver && '#B4307D',
                                            color: isDraggingOver && '#B4307D'
                                        }
                                    }
                                    onDragOver={(e) => handleDragOver(e, false, false)}
                                    onDragLeave={() => handleDragLeave(false, false)}
                                    onDrop={(event) => handleOnDrop(event, false)}
                                >
                                    <input
                                        type='file'
                                        onChange={(event) => handleOnDrop(event, true)}
                                        hidden
                                        accept="image/png, image/jpeg"
                                        ref={inputRef}
                                        multiple
                                    />

                                    <div className='bible-admin-draganddrop-div-grid'>
                                        <div className='bible-admin-uploaded-div' style={{width: '89px', height: '89px', cursor: 'pointer'}} onClick={() => inputRef.current.click()}>
                                            <img src={addImg} alt='' />
                                        </div>

                                        {
                                            uploadedFiles.map((file, file_idx) => (
                                                <>
                                                    <div key={file_idx} className='bible-admin-uploaded-div'>
                                                        <img src={URL.createObjectURL(file)} alt='' />
                                                    </div>
                                                </>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                {mobileSidebarState ?(
                    <div className='gnrt-img-main-sidebar-mobile'>
                        <div className='gnrt-img-main-sidebar-mobile-img-wrapper' onClick={() => setMobileSidebarState(!mobileSidebarState)}>
                            <img src={arrowImg} alt='' className='gnrt-img-main-sidebar-mobile-img' />
                        </div>
                        

                        <button className='gnrt-img-main-sidebar-mobile-btn'
                            onClick={() => {
                                handleGenerateClick()
                                setMobileSidebarState(!mobileSidebarState)
                            }}
                        >
                            Generate
                        </button>

                        <div className='gnrt-img-main-sidebar-mobile-content'>
                            <PostsAdminSidebar
                                turboSelected={turboSelected} setTurboSelected={setTurboSelected}
                                prompt={prompt} setPrompt={setPrompt}
                                negativePrompt={negativePrompt} setNegativePrompt={setNegativePrompt}
                                selectedStyle={selectedStyle} setSelectedStyle={setSelectedStyle}
                                selectedAspectRatio={selectedAspectRatio} setSelectedAspectRatio={setSelectedAspectRatio}
                                imageCount={imageCount} setImageCount={setImageCount}
                                iterationCount={iterationCount} setIterationCount={setIterationCount}
                                CFGScaleGenerator={CFGScaleGenerator} setCFGScaleGenerator={setCFGScaleGenerator}
                                CFGScaleDecoder={CFGScaleDecoder} setCFGScaleDecoder={setCFGScaleDecoder}
                            />
                        </div>
                    </div>
                ) : (
                    <div className='gnrt-img-main-sidebar-mobile-nav'>
                        <img src={arrowNavImg} alt=''
                            onClick={() => setMobileSidebarState(!mobileSidebarState)}
                        />
                    </div>
                )}
            </div>

            {loading && <LoaderTool />}
        </div>
    )
}

export default PostsAdminScreen
