import {
    USER_LOGIN_REQUEST,
    USER_LOGIN_SUCCESS,
    USER_LOGIN_FAIL,

    USER_LOGOUT,

    USER_REGISTER_REQUEST,
    USER_REGISTER_SUCCESS,
    USER_REGISTER_FAIL,

    USER_SUBSCRIPTION_DETAIL_REQUEST,
    USER_SUBSCRIPTION_DETAIL_SUCCESS,
    USER_SUBSCRIPTION_DETAIL_FAIL,

    USER_UPDATE_DETAIL_REQUEST,
    USER_UPDATE_DETAIL_SUCCESS,
    USER_UPDATE_DETAIL_FAIL,
} from '../constants/userConstants'

export const userLoginReducers = (state = {}, action) => {
    switch (action.type) {
        case USER_LOGIN_REQUEST:
            return { loading: true }

        case USER_LOGIN_SUCCESS:
            return { loading: false, userInfo: action.payload }

        case USER_LOGIN_FAIL:
            return { loading: false, error: action.payload }

        case USER_LOGOUT:
            return {}

        default:
            return state
    }
}

export const userRegisterReducers = (state = {}, action) => {
    switch (action.type) {
        case USER_REGISTER_REQUEST:
            return { loading: true }

        case USER_REGISTER_SUCCESS:
            return { loading: false, registerComplete: true }

        case USER_REGISTER_FAIL:
            return { loading: false, error: action.payload }

        case USER_LOGOUT:
            return {}

        default:
            return state
    }
}

export const getUserSubscriptionDetailReducers = (state = {}, action) => {
    switch (action.type) {
        case USER_SUBSCRIPTION_DETAIL_REQUEST:
            return { loading: true }
        case USER_SUBSCRIPTION_DETAIL_SUCCESS:
            return { loading: false, userSubscriptionDetail: action.payload }
        case USER_SUBSCRIPTION_DETAIL_FAIL:
            return { loading: false, subscriptionError: action.payload }
        case USER_UPDATE_DETAIL_REQUEST:
            return { ...state, loading: true }
        case USER_UPDATE_DETAIL_SUCCESS:
            return { loading: false}
        case USER_UPDATE_DETAIL_FAIL:
            return { ...state, loading: false, updateError: action.payload }
        default:
            return state
    }
}