import React, { useEffect, useState, useRef } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { logout, getUserSubscriptionDetail } from '../../actions/userActions'
import axios from 'axios'

import RemoveBackgroundComponent from '../../components/RemoveBackgroundComponent'
import RemoveBackgroundHistoryComponent from '../../components/RemoveBackgroundHistoryComponent'
import ColorPickerPopup from '../../components/ColorPickerPopup'

import RemoveBackgroundDownloadPopup from '../../components/RemoveBackgroundDownloadPopup'

import './RemoveBackgroundScreen.css'
import logoSmall from '../../images/logo-small.jpg'
import closeBtnImg from '../../images/close-btn.svg'
import colorsTransparent from '../../images/img-picker/transparent.svg'
import rmvBgColor from '../../images/rmv-bg-color.svg'

import addImgBtn from '../../images/add-image.svg'
import refreshImg from '../../images/reload.svg'
import downloadImg from '../../images/drag&drop_download.svg'

function RemoveBackgroundScreen() {
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    function getWindowSize() {
        const {innerWidth, innerHeight} = window;
        return {innerWidth, innerHeight};
    }

    const userLogin = useSelector(state => state.userLogin)
    const {userInfo} = userLogin

    const userDetail = useSelector(state => state.userSubscriptionDetail)
    const { userSubscriptionDetail } = userDetail

    const [toggle, setToggle] = useState(true);
    const [downloaderPopup, setDownloaderPopup] = useState(false);

    const [imageFile, setImageFile] = useState(); //
    const [imageFileName, setImageFileName] = useState();
    const [imageFileResolution, setImageFileResolution] = useState();

    const [resultImageFile, setResultImageFile] = useState();

    const [backgroundColorPicker, setBackgroundColorPicker] = useState();
    const [backgroundImagePicker, setBackgroundImagePicker] = useState();
    const [backgroundPickerState, setBackgroundPickerState] = useState(0);

    const [colorPickerPopup, setColorPickerPopup] = useState(false)
    const [hexColorInput, setHexColorInput] = useState("#FFFFFF")

    const [colorOpen, setColorOpen] = useState(true)
    const [pictureOpen, setPictureOpen] = useState(true)

    const customImgRef = useRef();
    const inputRef = useRef();
    const [dragAndDropTxt, setDragAndDropTxt] = useState('Drag and drop file');
    const navigate = useNavigate()

    const setWindowWidth = () => {
        if (window.innerWidth <= 960) {
            setDragAndDropTxt('Upload file')
        } else {
            setDragAndDropTxt('Drag and drop file')
        }
    }

    const dispatch = useDispatch()

    const logoutHandler = () => {
        dispatch(logout())
        navigate('/login')
    }

    useEffect(() => {
        if (!userInfo) {
            navigate('/login')
        } else {
            dispatch(getUserSubscriptionDetail(userInfo.token))
        }
    }, [dispatch, navigate, userInfo]);

    useEffect(() => {
        setWindowWidth();
    }, [setDragAndDropTxt]);

    window.addEventListener('resize', setWindowWidth)

    const colors = [
        { style: {backgroundColor: '#FFFFFF', border: '1px solid rgba(153, 153, 153, 0.30)'} },
        { style: {backgroundColor: '#FF5A5A'} },
        { style: {backgroundColor: '#FF825A'} },
        { style: {backgroundColor: '#FFB35A'} },
        { style: {backgroundColor: '#FFDB5A'} },
        { style: {backgroundColor: '#B4D965'} },
        { style: {backgroundColor: '#00AF31'} },
        { style: {backgroundColor: '#82DCE1'} },
        { style: {backgroundColor: '#00A3FF'} },
        { style: {backgroundColor: '#073EA8'} },
        { style: {backgroundColor: '#AC82E1'} },
        { style: {backgroundColor: '#D482E1'} },
        { style: {backgroundColor: '#AD00FF'} },
        { style: {backgroundColor: '#FF94DB'} },
        { style: {backgroundColor: '#ECD3AF'} },
        { style: {backgroundColor: '#C6AB83'} },
        { style: {backgroundColor: '#DDDDDD'} },
        { style: {backgroundColor: '#B6B6B6'} },
        { style: {backgroundColor: '#000000'} },
    ]

    const resetImages = () => {
        setImageFile();
        setImageFileName();
        setImageFileResolution();
        setResultImageFile();
    }

    const handleCustomImageClick = (event) => {
        // event.preventDefault();
        setBackgroundImagePicker(URL.createObjectURL(event.target.files[0]))
        setBackgroundPickerState(1)
    }

    async function handleUpload(event) {
        event.preventDefault()
        const img = event.target.files[0]

        if (isImageFile(img)) {
            var res = await getImageResolution(img)

            setImageFileResolution(res.width + 'x' + res.height)

            setImageFile(img)
            setImageFileName(img.name.substring(0, img.name.lastIndexOf('.')))
    
            // send to backend
            processImage(img)
        }
    }

    function isImageFile(file) {
        const acceptedImageTypes = ['image/png', 'image/jpeg']
        return file && acceptedImageTypes.includes(file['type'])
    }

    function getImageResolution(file) {
        return new Promise((resolve, reject) => {
            const image = new Image();
    
            image.onload = () => {
                const width = image.width;
                const height = image.height;
                resolve({width, height})
            }
    
            image.onerror = () => {
                reject(new Error('Failed to load the image'));
            };
    
            image.src = URL.createObjectURL(file);
        })
    }

    function hexToRgb(hex) {
        // Remove the hash symbol (#) if it's present
        hex = hex.replace(/^#/, '');
    
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
    
        return [ r, g, b ];
    }

    const processImageDownload = async (lowQuality=false) => {

        try {
          var currentdate = new Date();
    
          const formData = new FormData();
    
          formData.append('save', true);
          formData.append('username', userInfo.username);
          formData.append('imageName', imageFileName);
          formData.append('date', currentdate.getTime() / 1000);
          formData.append('imageCategory', 'png');
          formData.append('imageSize', imageFile.size);
          formData.append('imageResolution', imageFileResolution);
          formData.append('image', imageFile);
    
          if (lowQuality) {
            formData.append('lowQuality', lowQuality);
          }
    
          if (backgroundColorPicker && backgroundPickerState === 0) {
            formData.append('rgbVal', hexToRgb(backgroundColorPicker))
          }
    
          if (backgroundPickerState === 1) {
            const imageResponse = await axios.get(backgroundImagePicker, { responseType: 'arraybuffer' });
            const imageData = imageResponse.data;
            formData.append('bgImage', new Blob([imageData]));
          }
    
          axios.post(
            'remove_background/api/',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          )
          .then(response => {
            const modifiedImage = response.data.modifiedImage;
            downloadImage(modifiedImage)
          })
          .catch((error) => {
            console.error('Error uploading image', error);
          })
    
        } catch (error) {
          console.error('Error fetching image data:', error);
        }
    }

    const downloadImage = (img) => {
        const link = document.createElement('a');
        link.href = 'data:application/octet-stream;base64,' + img;
        link.download = imageFileName + ('.png'); // Specify the desired file name
        link.click();
    };

    function processImage(image) {
        return new Promise((resolve, reject) => {    
            const formData = new FormData();
            formData.append('username', userInfo.username);
            formData.append('image', image);

            axios.post(
              'remove_background/api/',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  'Authorization': `Bearer ${userInfo.access}`
                }
              }
            )
            .then(response => {
                if (response.data.user_404) {
                    logoutHandler();
                } else {
                    const modifiedImage = response.data.modifiedImage;
                    setResultImageFile(modifiedImage);
                }

                // dispatch(getUserSubscriptionDetail(userInfo.token))
    
                resolve()
            })
            .catch(error => {
                reject(error)
            })
        })
    }

    const handleHexInputChange = (event) => {
        var hexRegex = /^[0-9A-Fa-f]*$/; // Regular expression for hex color
    
        if (!hexRegex.test(event.target.value)) {
          event.target.value = event.target.value.replace(/[^0-9A-Fa-f]/g, '');
        }
    
        event.target.value = '#' + event.target.value.toUpperCase()
    
        setHexColorInput(event.target.value)
    
        if (event.target.value.length === 7) {
            setBackgroundColorPicker(event.target.value)
            setBackgroundPickerState(0)
        }
    }

    return (
        <div className='rmv-bg-container'>
            <div className='rmv-bg-navbar'>
                <img src={logoSmall} alt='logo' />
                <NavLink className='rmv-bg-navbar-title' to={'/my-page'} style={{marginLeft: '10px'}}>
                    Image data
                </NavLink>
                <div className='rmv-bg-navbar-topic' style={{marginLeft: '4px', marginRight: '4px'}}>
                    /
                </div>
                <div className='rmv-bg-navbar-topic' >
                    Remove background
                </div>
                {userSubscriptionDetail && userInfo && (
                    <>
                        <div className='rmv-bg-navbar-title' style={{marginLeft: '4px', marginRight: '4px', fontWeight: 'normal'}}>
                            /
                        </div>
                        <div className='rmv-bg-navbar-title' style={{fontWeight: 'normal'}}>
                            {userSubscriptionDetail.user_data.subscriptionDetail.credits}&copy;
                        </div>

                    </>
                )}

                <div className='rmv-bg-navbar-close'>
                    <img src={closeBtnImg} alt='' onClick={() => navigate('/my-page')} />
                    {/* <i className="fa-solid fa-xmark fa-sm" style={{cursor: 'pointer', fontSize: '22px'}} onClick={() => navigate('/my-page')}></i> */}
                </div>
            </div>
            
            <div className='rmv-bg-main'>

                {
                    (windowSize.innerWidth > 1100 || toggle) && 
                    <div className='rmv-bg-main-sidebar'>
                        <div className='rmv-bg-main-sidebar-upload-btn' onClick={() => inputRef.current.click()}>
                            <input
                                type='file'
                                onChange={(event) => handleUpload(event, true)}
                                hidden
                                ref={inputRef}
                            />
                            Upload
                        </div>

                        <div className={colorOpen ? 'rmv-bg-main-sidebar-item open' : 'rmv-bg-main-sidebar-item'}>
                            <div className='rmv-bg-main-sidebar-item-title' onClick={() => setColorOpen(!colorOpen)}>
                                <i className="fa-solid fa-chevron-down toggle-btn"></i>
                                Color
                            </div>

                            {colorOpen &&
                                <>
                                    <div className='rmv-bg-main-sidebar-colors'>
                                        <li key={'transparent'} onClick={() => {
                                                setBackgroundColorPicker('')
                                                setBackgroundPickerState(0)
                                            }}>
                                            <img src={colorsTransparent} alt='transparent' />
                                        </li>
                                        {colors.map(color => (
                                            <li
                                                key={color.style.backgroundColor}
                                                style={color.style}
                                                onClick={() => {
                                                    setBackgroundColorPicker(color.style.backgroundColor)
                                                    setBackgroundPickerState(0)
                                                    setHexColorInput(color.style.backgroundColor)
                                                }}
                                            />
                                        ))}
                                    </div>

                                    <div className='rmv-bg-main-sidebar-color-picker'>
                                        <div className='rmv-bg-main-sidebar-color-picker-display' style={{background: backgroundColorPicker}} onClick={() => setColorPickerPopup(true)}>
                                            <div className='rmv-bg-main-sidebar-color-picker-display-img'>
                                                <img src={rmvBgColor} alt='tool' />
                                            </div>
                                        </div>

                                        <input
                                            className='rmv-bg-main-sidebar-color-picker-input'
                                            type='text'
                                            maxLength="7"
                                            value={backgroundColorPicker ? hexColorInput : 'Transparent'}
                                            onChange={(e) => handleHexInputChange(e)}
                                        />
                                    </div>
                                </>
                            }

                        </div>

                        <div className={pictureOpen ? 'rmv-bg-main-sidebar-item open' : 'rmv-bg-main-sidebar-item'}>
                            <div className='rmv-bg-main-sidebar-item-title' onClick={() => setPictureOpen(!pictureOpen)}>
                                <i className="fa-solid fa-chevron-down toggle-btn"></i>
                                Picture
                            </div>

                            {pictureOpen && 
                                <>
                                    <div className='rmv-bg-main-sidebar-upload-btn' onClick={() => customImgRef.current.click()}>
                                        <input
                                            type='file'
                                            accept='image/*'
                                            onChange={handleCustomImageClick}
                                            hidden
                                            ref={customImgRef}
                                        />
                                        <img src={addImgBtn} alt='' style={{height: '24px', width: '24px'}} />
                                    </div>

                                </>
                            }

                        </div>
                        
                    </div>
                }
                

                <div className='rmv-bg-main-drag-and-drop'>
                    <div className='rmv-bg-main-drag-and-drop-navbar'>
                        <div className='rmv-bg-main-drag-and-drop-navbar-links'>

                            <div className={toggle && 'rmv-bg-main-drag-and-drop-navbar-links-active'} onClick={() => setToggle(true)} style={{marginRight: '50px', cursor: 'pointer'}}>
                                Remove Background
                            </div>

                            <div className={!toggle && 'rmv-bg-main-drag-and-drop-navbar-links-active'} onClick={() => setToggle(false)} style={{cursor: 'pointer'}}>
                                History
                            </div>
                        </div>
                        <div className='rmv-bg-main-drag-and-drop-navbar-btns' style={{visibility: !toggle && 'hidden'}}>
                            <img src={refreshImg} alt='' style={{marginRight: '30px', cursor: 'pointer'}} onClick={resetImages} />
                            <img src={downloadImg} alt='' style={{cursor: 'pointer'}} onClick={() => (imageFile && processImageDownload())}/>
                        </div>
                    </div>

                    <div>
                        {toggle ?
                            <RemoveBackgroundComponent dragAndDropTxt={dragAndDropTxt}
                                imageFile={imageFile} setImageFile={setImageFile}
                                setImageFileName={setImageFileName}
                                setImageFileResolution={setImageFileResolution}
                                setDownloaderPopup={setDownloaderPopup}
                                resultImageFile={resultImageFile} setResultImageFile={setResultImageFile}
                                backgroundPickerState={backgroundPickerState} backgroundColorPicker={backgroundColorPicker} backgroundImagePicker={backgroundImagePicker}
                            />
                        :
                            <RemoveBackgroundHistoryComponent />
                        }
                    </div>

                    <RemoveBackgroundDownloadPopup trigger={downloaderPopup} setTrigger={setDownloaderPopup}
                        imageFile={imageFile}
                        imageFileName={imageFileName} setImageFileName={setImageFileName}
                        imageFileResolution={imageFileResolution}
                        resultImageFile={resultImageFile}
                        backgroundPickerState={backgroundPickerState}
                        backgroundColorPicker={backgroundColorPicker}
                        backgroundImagePicker={backgroundImagePicker}
                    />

                    <ColorPickerPopup
                        trigger={colorPickerPopup} setTrigger={setColorPickerPopup}
                        savedColor={backgroundColorPicker} setSavedColor={setBackgroundColorPicker} 
                        hexColorInput={hexColorInput} setHexColorInput={setHexColorInput}
                        setBackgroundPickerState={setBackgroundPickerState}
                    />
   
                </div>
            </div>
        </div>
    )
}

export default RemoveBackgroundScreen
