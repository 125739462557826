import axios from 'axios'
import { USER_LOGOUT } from '../constants/userConstants'

import {
    POST_GET_REQUEST,
    POST_GET_SUCCESS,
    POST_GET_FAIL,

    POST_ID_GET_REQUEST,
    POST_ID_GET_SUCCESS,
    POST_ID_GET_FAIL,

    POST_SEARCH_REQUEST,
    POST_SEARCH_SUCCESS,
    POST_SEARCH_FAIL
} from '../constants/postConstants'

export const getPostsData = (paginatorValue, itemsPerPage) => async (dispatch) => {
    try {
        dispatch({
            type: POST_GET_REQUEST
        })

        const formData = new FormData();
        formData.append('pageNumber', paginatorValue)
        formData.append('itemsPerPage', itemsPerPage)

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }

        const { data } = await axios.post(
            `admin_generations/api/posts/display/`,
            formData,
            config
        )

        dispatch({
            type: POST_GET_SUCCESS,
            payload: data
        })

    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('userInfo')
            dispatch({type: USER_LOGOUT})
        } else {
            dispatch({
                type: POST_GET_FAIL,
                payload: error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
            })
        }
    }
}

export const getPostsDataId = (idx) => async (dispatch) => {
    try {
        dispatch({
            type: POST_ID_GET_REQUEST
        })

        const { data } = await axios.get(
            `admin_generations/api/posts/${idx}`
        )

        dispatch({
            type: POST_ID_GET_SUCCESS,
            payload: data
        })

    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('userInfo')
            dispatch({type: USER_LOGOUT})
        } else {
            dispatch({
                type: POST_ID_GET_FAIL,
                payload: error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
            })
        }
    }
}

export const searchPostData = (search_book, search_chapter, search_start_verse, search_end_verse) => async (dispatch) => {
    try {
        dispatch({
            type: POST_SEARCH_REQUEST
        })

        const formData = new FormData();
        formData.append('search_book', search_book ? search_book : '')
        formData.append('search_chapter', search_chapter ? search_chapter : '0')
        formData.append('search_start_verse', search_start_verse ? search_start_verse : '0')
        formData.append('search_end_verse', search_end_verse ? search_end_verse : '0')

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        }

        const { data } = await axios.post(
            `admin_generations/api/posts/search/`,
            formData,
            config
        )

        dispatch({
            type: POST_SEARCH_SUCCESS,
            payload: data
        })
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('userInfo')
            dispatch({type: USER_LOGOUT})
        } else {
            dispatch({
                type: POST_SEARCH_FAIL,
                payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
            })
        }
    }
}
