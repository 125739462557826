import { HashRouter, Routes, Route } from 'react-router-dom'

import './App.css';

import Sidebar from './components/Sidebar'
import PostsImageViewer from './components/PostsImageViewer'

import HomeScreen from './screens/HomeScreen/HomeScreen'
import LoginScreen from './screens/LoginScreen/LoginScreen'
import RegisterScreen from './screens/LoginScreen/RegisterScreen'
import MyPageScreen from './screens/MyPageScreen/MyPageScreen'
import RemoveBackgroundScreen from './screens/RemoveBackgroundScreen/RemoveBackgroundScreen'
import Txt2ImgScreen from './screens/Txt2ImgScreen/Txt2ImgScreen'
import AccountSettingsScreen from './screens/AccountSettingsScreen/AccountSettingsScreen'
import PostsScreen from './screens/PostsScreen/PostsScreen'
import ImageToImageScreen from './screens/ImageToImageScreen'
import UpscalingScreen from './screens/UpscalingScreen'
import MultiPromptingScreen from './screens/MultiPromptingScreen/MultiPromptingScreen'
import TermsScreen from './screens/TermsAndPrivacyScreen/TermsScreen'
import PrivacyScreen from './screens/TermsAndPrivacyScreen/PrivacyScreen'

// Admin pages
import PostsAdminScreen from './screens/_adminScreens/PostsAdminScreen'
import MainPostsAdminScreen from './screens/_adminScreens/MainPostsAdminScreen'
import EditPostAdminScreen from './screens/_adminScreens/EditPostAdminScreen'
import AccountPaymentComponent from './components/AccountPaymentComponent';

function App() {

  return (
    <HashRouter>
      <Routes>
        <Route path='/' element={<HomeScreen />} />
        <Route path='/login' element={<LoginScreen />} />
        <Route path='/register' element={<RegisterScreen />} />
        <Route path='/term-of-service' element={<TermsScreen />} />
        <Route path='/privacy-policy' element={<PrivacyScreen />} />

        <Route path='/my-page' element={<Sidebar />} >
          <Route path='/my-page' element={<MyPageScreen />} />
          <Route path='/my-page/account-settings' element={<AccountSettingsScreen />} />
          <Route path='/my-page/account-settings/payment' element={<AccountPaymentComponent />} />
        </Route>

        <Route path='/my-page/remove-background' element={<RemoveBackgroundScreen />} />
        <Route path='/my-page/txt2img' element={<Txt2ImgScreen />} />
        <Route path='/my-page/img2img' element={<ImageToImageScreen />} />
        <Route path='/my-page/upscaling' element={<UpscalingScreen />} />
        <Route path='/my-page/multi-prompting' element={<MultiPromptingScreen />} />

        <Route path='/posts' element={<PostsScreen />} />
        <Route path='/posts/:id' element={<PostsImageViewer />} />

        {/* <Route path='/posts' element={<PostsScreen />}/> */}

        <Route path='/admin-posts' element={<MainPostsAdminScreen />} />
        <Route path='/admin-posts/add' element={<PostsAdminScreen />} />
        <Route path='/admin-posts/edit/:id' element={<EditPostAdminScreen />} />

      </Routes>
    </HashRouter>
  );
}

export default App;
