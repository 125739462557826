import React, { useRef, useState } from 'react'

import addImg from '../images/img2img_add.jpg'
import closeBtnImg from '../images/close-btn.svg'

function UpscalingSidebar(props) {
    const inputRef = useRef()

    const [inputImageOpen, setInputImageOpen] = useState(true)
    const [modelOpen, setModelOpen] = useState(true)
    const [isDraggingOver, setDraggingOver] = useState(false);

    const [imageErrorMessage, setImageErrorMessage] = useState('')

    const handleDragOver = (event) => {
        event.preventDefault();

        setDraggingOver(true);
    }

    const handleDragLeave = () => {
        setDraggingOver(false);
    };

    async function handleOnDrop(event, drop=false) {
        event.preventDefault();
        setImageErrorMessage('')

        const file = drop ? event.target.files[0] : event.dataTransfer.files[0]

        var res = await getImageResolution(file)

        if (props.modelTypeState === 0 && res.width * res.height * 4 > 4194304) {
            setImageErrorMessage(`Resulting max pixel count allowed 4194304 (current ${res.width*res.height*4})`)
        } else if (props.modelTypeState === 0 && res.height < 256) {
            setImageErrorMessage(`Image height must be no less than 256 (current ${res.height})`)
        } else {
            props.setImageInput(file)
        }

        setDraggingOver(false);
    }

    function getImageResolution(file) {
        return new Promise((resolve, reject) => {
            const image = new Image();

            image.onload = () => {
                const width = image.width;
                const height = image.height;
                resolve({width, height})
            }

            image.onerror = () => {
                reject(new Error('Failed to load the image'));
            };
    
            image.src = URL.createObjectURL(file);
        })
    }

    const styles = {
        btn: {
            width: '100%',
            height: '42px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '5px',
            border: '1px solid rgba(153, 153, 153, 0.3)',
            cursor: 'pointer'
        }
    }

    async function setModelState (val) {
        props.setModelTypeState(val)
    }

    return (
        <>
            <div className={inputImageOpen ? 'gnrt-img-main-sidebar-item open' : 'gnrt-img-main-sidebar-item'}>
                <div className='gnrt-img-main-sidebar-item-title' onClick={() => setInputImageOpen(!inputImageOpen)}>
                    <div>
                        <i className="fa-solid fa-chevron-down toggle-btn"></i>
                        Input image
                    </div>
                </div>
                
                {inputImageOpen && (
                    <>
                        {imageErrorMessage && <div style={{color: 'red', marginBottom: '10px'}}>{imageErrorMessage}</div>}
                        <div style={{
                                border: '1px solid rgba(153, 153, 153, 0.3)',
                                borderColor: isDraggingOver ? '#B4307D' : 'rgba(153, 153, 153, 0.3)',
                                borderRadius: '5px',
                                height: '132px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative'
                            }}
                            onDragOver={(e) => handleDragOver(e)}
                            onDragLeave={() => handleDragLeave()}
                            onDrop={(event) => handleOnDrop(event)}
                        >
                            <input
                                type='file'
                                onChange={(event) => handleOnDrop(event, true)}
                                hidden
                                accept="image/png, image/jpeg"
                                ref={inputRef}
                            />
                            {props.imageInput ? (
                                <>
                                    <img src={closeBtnImg} alt='' style={{position: 'absolute', right: '0', top: '0', margin: '5px', cursor: 'pointer'}} onClick={() => props.setImageInput()}/>
                                    <img src={URL.createObjectURL(props.imageInput)} alt='' style={{maxHeight: '132px', maxWidth: '100%'}} />
                                </>
                            ) : (
                                <>
                                    <img src={addImg} alt='' style={{height: '20px', width: '20px', cursor: 'pointer'}} onClick={() => inputRef.current.click()}/>
                                    <div style={{marginTop: '3px'}}>Upload an image</div>
                                </>
                            )}
                        </div>
                    </>
                )}

            </div>

            <div className={modelOpen ? 'gnrt-img-main-sidebar-item open' : 'gnrt-img-main-sidebar-item'}>
                <div className='gnrt-img-main-sidebar-item-title' onClick={() => setModelOpen(!modelOpen)}>
                    <div>
                        <i className="fa-solid fa-chevron-down toggle-btn"></i>
                        Model
                    </div>
                </div>

                {modelOpen && (
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{...styles.btn, borderColor: props.modelTypeState === 0 ? '#B4307D' : 'rgba(153, 153, 153, 0.3)'}} onClick={() => setModelState(0)}>
                            {props.modelType[0]}
                        </div>
                        {/* <div style={{...styles.btn, borderColor: props.modelTypeState === 1 ? '#B4307D' : 'rgba(153, 153, 153, 0.3)'}} onClick={() => setModelState(1)}>
                            {props.modelType[1]}
                        </div> */}
                    </div>
                )}
            </div>

        </>
    )
}

export default UpscalingSidebar
