import React, { useRef } from 'react'

import profileImg from '../images/profile.svg'
import { useSelector } from 'react-redux'

function AccountSettingsComponent(props) {

    const inputRef = useRef()

    const userDetail = useSelector(state => state.userSubscriptionDetail)
    const { updateError } = userDetail

    return (
        <div className='account-settings-component'>
            <div style={{color: 'red', marginBottom: '20px'}}>{props.updateErrorMessage && props.updateErrorMessage}</div>
            <div style={{color: 'red', marginBottom: '20px'}}>{updateError && updateError}</div>
            <div>Profile Pictue</div>
            <div className='account-settings-component-profile'>
                <img src={props.avatarImgInput ? URL.createObjectURL(props.avatarImgInput) : (props.avatar ? `https://www.dandycv.com/media/${props.avatar}` : profileImg)} alt=''/>
                <input
                    type='file'
                    onChange={(event) => props.setAvatarImgInput(event.target.files[0])}
                    hidden
                    accept="image/png, image/jpeg"
                    ref={inputRef}
                />
                <button onClick={() => inputRef.current.click()}>Upload Profile Pictture</button>
            </div>

            <div className='account-settings-component-profile-info'>
                Min. 200x200px, .PNG or .JPG
            </div>

            <div>
                <div className='account-settings-component-profile-input'>Username (within 10 characters)</div>
                <input maxLength={10} type='text' value={props.username} onChange={(e) => props.setUsername(e.target.value)}></input>
            </div>

            <div>
                <div className='account-settings-component-profile-input'>Email</div>
                <input type='text' style={{background: 'rgba(153, 153, 153, 0.3)'}} value={props.email} onChange={(e) => props.setEmail(e.target.value)} disabled></input>
            </div>

            <div>
                <div className='account-settings-component-profile-input'>First Name</div>
                <input type='text' value={props.firstName} onChange={(e) => props.setFirstName(e.target.value)}></input>
            </div>

            <div>
                <div className='account-settings-component-profile-input'>Last Name</div>
                <input type='text' value={props.lastName} onChange={(e) => props.setLastName(e.target.value)}></input>
            </div>

            {
                props.changePasswordRequest ? (
                    <>
                        <div>
                            <div className='account-settings-component-profile-input'>Current Password</div>
                            <input type='password' value={props.oldPassword} onChange={(e) => props.setOldPassword(e.target.value)}></input>
                        </div>

                        <div>
                            <div className='account-settings-component-profile-input'>New Password</div>
                            <input type='password' value={props.newPassword} onChange={(e) => props.setNewPassword(e.target.value)}></input>
                        </div>

                        <div>
                            <div className='account-settings-component-profile-input'>Confirm Password</div>
                            <input type='password' value={props.confirmPassword} onChange={(e) => props.setConfirmPassword(e.target.value)}></input>
                        </div>
                    </>
                ) : (
                    <div className='account-settings-component-profile'>
                        <button style={{marginLeft: '0 '}} onClick={() => props.setChangePasswordRequest(true)}>Change Password</button>
                    </div>
                )
            }

            
        </div>
    )
}

export default AccountSettingsComponent
