import React, { useRef, useState } from 'react'

import img0 from '../images/generate-images/img0.jpg'
import img1 from '../images/generate-images/img1.jpg'
import img2 from '../images/generate-images/img2.jpg'
import img3 from '../images/generate-images/img3.jpg'
import img4 from '../images/generate-images/img4.jpg'
import img5 from '../images/generate-images/img5.jpg'
import img6 from '../images/generate-images/img6.jpg'
import img7 from '../images/generate-images/img7.jpg'
import img8 from '../images/generate-images/img8.jpg'
import img9 from '../images/generate-images/img9.jpg'
import img10 from '../images/generate-images/img10.jpg'
import img11 from '../images/generate-images/img11.jpg'
import img12 from '../images/generate-images/img12.jpg'
import img13 from '../images/generate-images/img13.jpg'
import img14 from '../images/generate-images/img14.jpg'
import img15 from '../images/generate-images/img15.jpg'
import img16 from '../images/generate-images/img16.jpg'
import img17 from '../images/generate-images/img17.jpg'

import addImg from '../images/img2img_add.jpg'
import closeBtnImg from '../images/close-btn.svg'

function MultiPromptingSidebar(props) {
    const aspectRatios = {
        sdxl: [
            { ratio: '21:9', size: { width: 1536, height: 640 }},
            { ratio: '16:9', size: { width: 1344, height: 768 }},
            { ratio: '3:2', size: { width: 1216, height: 832 }},
            { ratio: '5:4', size: { width: 1152, height: 896 }},
            { ratio: '4:5', size: { width: 896, height: 1152 }},
            { ratio: '2:3', size: { width: 832, height: 1216 }},
            { ratio: '9:16', size: { width: 768, height: 1344 }},
            { ratio: '9:21', size: { width: 640, height: 1536 }},
            { ratio: '1:1', size: { width: 1024, height: 1024 }}
        ]
    }

    const styles = [
        { value: img0, title: 'No Style' },
        { value: img1, title: 'Anime' },
        { value: img2, title: 'Photographic' },
        { value: img3, title: 'Digital Art' },
        { value: img4, title: 'Comic Book' },
        { value: img5, title: 'Fantasy Art' },
        { value: img6, title: 'Analog Film' },
        { value: img7, title: 'Neon Punk' },
        { value: img8, title: 'Isometric' },
        { value: img9, title: 'Low Poly' },
        { value: img10, title: 'Origami' },
        { value: img11, title: 'Line Art' },
        { value: img12, title: 'Cinematic' },
        { value: img13, title: '3D Model' },
        { value: img14, title: 'Pixel Art' },
        { value: img15, title: 'Enhance' },
        { value: img16, title: 'Tile Texture' },
        { value: img17, title: 'Compound' },
    ]

    const [promptCount, setPromptCount] = useState(1)
    const [prompt0Open, setPrompt0Open] = useState(true)
    const [prompt1Open, setPrompt1Open] = useState(true)
    const [prompt2Open, setPrompt2Open] = useState(true)
    const [prompt3Open, setPrompt3Open] = useState(true)
    const [prompt4Open, setPrompt4Open] = useState(true)
    const [prompt5Open, setPrompt5Open] = useState(true)
    const [prompt6Open, setPrompt6Open] = useState(true)
    const [prompt7Open, setPrompt7Open] = useState(true)
    const [prompt8Open, setPrompt8Open] = useState(true)
    const [prompt9Open, setPrompt9Open] = useState(true)

    const [styleOpen, setStyleOpen] = useState(true)
    const [aspectRatioOpen, setAspectRatioOpen] = useState(true)
    const [imageCountOpen, setImageCountOpen] = useState(true)
    const [CFGScaleOpen, setCFGScaleOpen] = useState(true)

    return (
        <>
            <div className={styleOpen ? 'gnrt-img-main-sidebar-item open' : 'gnrt-img-main-sidebar-item'}>
                <div className='gnrt-img-main-sidebar-item-title'  onClick={() => setStyleOpen(!styleOpen)}>
                    <div>
                        <i className="fa-solid fa-chevron-down toggle-btn"></i>
                        Style
                    </div>
                </div>

                {styleOpen &&
                    <div className='gnrt-img-main-sidebar-styles'>
                        {styles.map((style, idx) => (
                            <div className='gnrt-img-main-sidebar-styles-item'>
                                {idx === 0 ? (
                                    <div className='gnrt-img-main-sidebar-styles-item-img-initial' onClick={() => props.setSelectedStyle(idx)} style={{border: props.selectedStyle === idx && '1px solid #B4307D'}}>
                                        <img src={style.value} alt='' style={{width: '30px', height: '30px'}}/>
                                    </div>
                                ) : (
                                    <div className='gnrt-img-main-sidebar-styles-item-img' onClick={() => props.setSelectedStyle(idx)}>
                                        <img src={style.value} alt='' style={{border: props.selectedStyle === idx && '1px solid #B4307D'}}/>
                                    </div>
                                )}
                                
                                <div style={{color: props.selectedStyle === idx && '#B4307D', fontWeight: props.selectedStyle === idx && 'bold'}}>
                                    {style.title}
                                </div>
                            </div>
                        ))}
                    </div>
                }
            </div>

            <div className={aspectRatioOpen ? 'gnrt-img-main-sidebar-item open' : 'gnrt-img-main-sidebar-item'}>
                <div className='gnrt-img-main-sidebar-item-title' onClick={() => setAspectRatioOpen(!aspectRatioOpen)}>
                    <div>
                        <i className="fa-solid fa-chevron-down toggle-btn"></i>
                        Aspect ratio
                    </div>
                </div>

                {aspectRatioOpen && (
                    <div className='gnrt-img-main-sidebar-aspect-ratio'>
                        {aspectRatios.sdxl.map((ratio, index) => (
                            <div className='gnrt-img-main-sidebar-aspect-ratio-div' style={{borderColor: props.selectedAspectRatio === index && '#B4307D'}} onClick={() => props.setSelectedAspectRatio(index)} >
                                <div className='gnrt-img-main-sidebar-aspect-ratio-div-content'>
                                    <div style={{fontSize: '14px'}}>
                                        {ratio.ratio}
                                    </div>
                                    <div style={{fontSize: '12px'}}>
                                        {ratio.size.width}x{ratio.size.height}
                                    </div>
                                </div>
                                
                            </div>
                        ))}
                    </div>
                )}

                

            </div>

            <div className={imageCountOpen ? 'gnrt-img-main-sidebar-item open' : 'gnrt-img-main-sidebar-item'}>
                <div className='gnrt-img-main-sidebar-item-title' style={{width: '100%'}} onClick={() => setImageCountOpen(!imageCountOpen)}>
                    <div>
                        <i className="fa-solid fa-chevron-down toggle-btn"></i>
                        Image count
                    </div>

                    {props.imageCount}
                </div>

                {imageCountOpen &&
                    <div className='gnrt-img-main-sidebar-image-count'>
                        <input type='range' min={'1'} max={'4'} step={'1'} value={props.imageCount} onChange={(e) => props.setImageCount(e.target.value)} />
                    </div>
                }
            </div>

            <div className={CFGScaleOpen ? 'gnrt-img-main-sidebar-item open' : 'gnrt-img-main-sidebar-item'}>
                <div className='gnrt-img-main-sidebar-item-title' onClick={() => setCFGScaleOpen(!CFGScaleOpen)}>
                    <div>
                        <i className="fa-solid fa-chevron-down toggle-btn"></i>
                        CFG scale
                    </div>
                </div>

                {CFGScaleOpen && (
                    <input  type='number' className='gnrt-img-main-sidebar-item-negative-prompt' style={{paddingTop: '8px', paddingBottom: '9px', height: '16px'}} step={0.1} value={props.CFGScale} onChange={(e) => props.setCFGScale(e.target.value)}/>
                )}

            </div>

            {Array.from({length: 10}, (v, i) => i).map((index) => (
                props.prompts.length > index && (
                    <div className={eval('prompt' + index + 'Open') ? 'gnrt-img-main-sidebar-item open' : 'gnrt-img-main-sidebar-item'}>
                        <div className='gnrt-img-main-sidebar-item-title' style={{width: '100%', cursor: 'default'}}>
                            <div style={{cursor: 'pointer'}} onClick={() => eval('setPrompt' + index + 'Open')(!eval('prompt' + index + 'Open'))}>
                                <i className="fa-solid fa-chevron-down toggle-btn"></i>
                                Prompt {index+1}
                            </div>

                            {props.prompts.length > 1 && (
                                <img src={closeBtnImg} alt='' style={{cursor: 'pointer', width: '14px', height: '14px'}} onClick={() => {
                                    props.setWeights(props.weights.filter((v, i) => (i != index)))
                                    props.setPrompts(props.prompts.filter((v, i) => (i != index)))
                                }}/>
                            )}
                        </div>

                        {eval('prompt' + index + 'Open') && (
                            <>
                                <textarea className='gnrt-img-main-sidebar-item-prompt' value={props.prompts[index]} onChange={(e) => {
                                    let newArr = [...props.prompts]
                                    newArr[index] = e.target.value
                                    props.setPrompts(newArr)
                                }}/>

                                <div className='gnrt-img-main-sidebar-item-title' style={{width: '100%', marginTop: '10px', marginBottom: '0px'}}>

                                    <div>Weight</div>
                                    
                                    {props.weights[index]}

                                </div>
                                <div className='gnrt-img-main-sidebar-image-count'>
                                    <input type='range' min={-1} max={1} step={0.01} value={props.weights[index]} onChange={(e) => {
                                        let newArr = [...props.weights]
                                        newArr[index] = e.target.value
                                        props.setWeights(newArr)
                                    }}/>
                                </div>
                            </>
                        )}
                    </div>
                )
            ))}

            {props.prompts.length < 10 && (
                <button className='gnrt-img-main-sidebar-mobile-btn' style={{marginTop: '50px', marginLeft: '0'}} onClick={() => {
                    props.setWeights([...props.weights, 1])
                    props.setPrompts([...props.prompts, ''])
                }}>
                    Add Prompt
                </button>
            )}

        </>
    )
}

export default MultiPromptingSidebar
