import React from 'react'
import parse from 'html-react-parser'
import { useTranslation } from 'react-i18next'

function CopyPopup(props) {
    const style = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '210px',
        height: '38px',
        borderRadius: '5px',
        border: '1px solid #999',
        background: '#FFF',
        position: 'absolute',
        botttom: '0',
        right: '0',
        color: '#000',
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: 'normal',
    }

    const { t } = useTranslation()

    return (props.trigger) ? (
        <div style={style}>
            {parse(t('post_link_copied'))}
        </div>
    ) : ('')
}

export default CopyPopup
