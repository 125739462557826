import React, { useEffect, useRef, useState } from 'react'
import { loadPaymentWidget, ANONYMOUS } from "@tosspayments/payment-widget-sdk";
import { nanoid } from 'nanoid';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';

import saleImg from '../images/hot-sale.png'

function AccountSettingsSubscriptionComponent(props) {

    const priceList = [
        {credits: 363, price: '￦ 4,900', pay: 4900},
        {sale: '733<span>+5 service</span>', credits: 768, price: '￦ 9,900', pay: 9900},
        {sale: '2215<span>+25 service</span>', credits: 2240, price: '￦ 29,900', pay: 29900, hot: true},
        {sale: '4437<span>+55 service</span>', credits: 4492, price: '￦ 59,900', pay: 59900},
        {sale: '6659<span>+85 service</span>', credits: 6744, price: '￦ 89,900', pay: 89900},
    ]

    const [selectedPayment, setSelectedPayment] = useState(0)

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const navigate = useNavigate();

    useEffect(() => {
        if (!userInfo) {
            navigate('/login')
        }
    }, [userInfo]);

    const handlePaymentRequest = async () => {
        // loadTossPayments(clientKey).then(tossPayments => {
        //     tossPayments.requestPayment('카드', {
        //         amount: priceList[selectedPayment].pay,
        //         orderId: ORDER_ID,
        //         orderName: 'dandycv',
        //         successUrl: `https://www.dandycv.com/payments/success/${userInfo.username}`,
        //         failUrl: `https://www.dandycv.com/payments/fail`,
        //     }).catch(function (error) {
        //         if (error.code === 'USER_CANCEL') {
        //             console.log('user canceled payment')
        //         } else if (error.code === 'INVALID_CARD_COMPANY') {
        //             console.log('invalid card')
        //         }
        //     })
        // })
    };

    return (
        <div className='account-settings-subscription-component' style={{position: 'relative'}}>
            <div className='account-settings-subscription-component-container'>
                <div style={{marginRight: '70px'}}>
                    <div className='account-settings-subscription-component-credits-div'>
                        <div>Credits</div>
                        <div style={{marginTop: '23px', color: '#B4307D', fontWeight: 'bold'}}>
                            {props.userSubscriptionDetail ? props.userSubscriptionDetail.user_data.subscriptionDetail.credits : 0}<span style={{fontWeight: 'normal'}}>&copy;</span>
                        </div>

                        {priceList.map((item, idx) => (
                            <div className='account-settings-subscription-component-payment-div'>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <input type='checkbox' checked={idx === selectedPayment} onClick={() => setSelectedPayment(idx)}/>
                                    <div style={{marginLeft: '20px'}}>{item.sale && <> {parse(item.sale)} = </>} {item.credits}&copy;</div>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>{item.hot && <img src={saleImg} alt='' style={{width: '25px', height: '25px', marginRight: '5px'}}/>}{item.price}</div>
                            </div>
                        ))}
                        
                        <button
                            onClick={() => navigate('/my-page/account-settings/payment', { state: { data: priceList[selectedPayment].pay } })}
                            style={{
                                marginTop: '40px', background: '#B4307D', border: 'none', height: '50px', cursor: 'pointer', fontSize: '16px', color: '#FFF', borderRadius: '5px'
                            }}
                        >
                                Buy
                        </button>
                    </div>

                    <div className='account-settings-subscription-component-credits-div' style={{height: '350px', overflow: 'auto', marginTop: '12px'}}>
                        <div>Payments</div>
                        <div className='account-settings-subscription-component-credits-div-table' style={{marginTop: '15px'}}>
                            <div className='account-settings-subscription-component-credits-div-table-1'>Date</div>
                            <div className='account-settings-subscription-component-credits-div-table-2'>Price</div>
                            <div className='account-settings-subscription-component-credits-div-table-3'>Credits</div>
                        </div>
                        <div className='hr' style={{marginTop: '15px', marginBottom: '15px'}}></div>

                        {props.userSubscriptionDetail && props.userSubscriptionDetail.payment_data.map((item, idx) => (
                            <div className='account-settings-subscription-component-credits-div-table' style={{marginTop: '6px'}}>
                                <div className='account-settings-subscription-component-credits-div-table-1'>{item.created.slice(0, 10)}</div>
                                <div className='account-settings-subscription-component-credits-div-table-2'>￦ {item.price}</div>
                                <div className='account-settings-subscription-component-credits-div-table-3' style={{display: 'flex', alignItems: 'center'}}>{item.credits}&copy;</div>
                            </div>
                        ))}
                        
                        
                    </div>
                </div>
                <div>
                    <div className='account-settings-subscription-component-credits-div-right-title'>Create your content at reasonable prices</div>
                    <div className='account-settings-subscription-component-credits-div-right-title-info' style={{color: '#FEBF34', marginTop: '16px'}}>1 credit = 1 generate image</div>
                    <div style={{marginTop: '30px'}}>
                        <div style={{display: 'flex'}}>
                            <div>
                                <li style={{color: '#B4307D'}}></li>
                            </div>
                            <div>
                                1$ = 100 credit = 100 images
                            </div>
                        </div>
                        {/* <div style={{display: 'flex', marginTop: '8px'}}>
                            <div>
                                <li style={{color: '#B4307D'}}></li>
                            </div>
                            <div>
                                1$ = 400 images available for download
                            </div>
                        </div> */}
                        <div style={{display: 'flex', marginTop: '8px'}}>
                            <div>
                                <li style={{color: '#B4307D'}}></li>
                            </div>
                            <div>
                                1$ = 1.25 generate video
                            </div>
                        </div>
                    </div>

                    <div className='account-settings-subscription-component-credits-div-right-title-info' style={{marginTop: '60px'}}>Refund policy</div>
                    <div style={{marginTop: '30px'}}>
                        <div style={{display: 'flex'}}>
                            <div>
                                <li style={{color: '#B4307D'}}></li>
                            </div>
                            <div>
                                We inform that refund is possible, if no credits have been used within 7 days after payment.
                            </div>
                        </div>
                        <div style={{display: 'flex', marginTop: '8px'}}>
                            <div>
                                <li style={{color: '#B4307D'}}></li>
                            </div>
                            <div>
                                The expiration date of charged points is up to 12 months from the time of payment and automatically expires after the expiration date.
                            </div>
                        </div>
                        <div style={{display: 'flex', marginTop: '8px'}}>
                            <div>
                                <li style={{color: '#B4307D'}}></li>
                            </div>
                            <div>
                                The period of use and refund of charged points is limited to within one year from the time of payment.
                            </div>
                        </div>
                        <div style={{display: 'flex', marginTop: '8px'}}>
                            <div>
                                <li style={{color: '#B4307D'}}></li>
                            </div>
                            <div>
                                <div>For refunding, please contact us by email below:</div>
                                <button
                                    onClick={() => window.location = 'mailto:dandycv.ai@gmail.com'}
                                    style={{
                                        marginTop: '8px',
                                        cursor: 'pointer',
                                        background: 'none',
                                        border: 'none',
                                        fontSize: '16px',
                                        color: '#0000EE'
                                    }}
                                >
                                    dandycv.ai@gmail.com
                                </button>
                                {/* Regardless of the amount of credits used, once a credit has been utilized, refunds are not possible. */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    
}

export default AccountSettingsSubscriptionComponent
