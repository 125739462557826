import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import parse from 'html-react-parser';
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../actions/userActions'

import './LoginScreen.css'

import googleImg from '../../images/icon-google.jpg'
import appleImg from '../../images/icon-mac.jpg'
import loginImg from '../../images/login.jpg'
import logoSmallImg from '../../images/logo-small.jpg'

function LoginScreen() {
  const { t } = useTranslation();
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const dispatch = useDispatch()

  // const location = useLocation()
  const navigate = useNavigate()

  // const redirect = location.search ? location.search.split('=')[1] : '/'

  const userLogin = useSelector(state => state.userLogin)
  const {error, loading, userInfo} = userLogin

  useEffect(() => {
    if (userInfo) {
      navigate('/my-page')
    }
  }, [userInfo, navigate])

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(login(username, password))
}

  return (
    <div className='login'>
      <div className='login-image'>
        <img src={loginImg} alt='login-img'/>
      </div>

      <div className='login-form'>
        <div className='login-logo'>
          <NavLink to={'/'}>
              <img src={logoSmallImg} alt='logo' style={{width: '40px', height: '40px'}}/>
          </NavLink>
        </div>

        {loading &&
            <div className='loader-login-wrapper'>
                <span className='loader-login'></span>
            </div>
        }

        <div className='login-form-wrapper'>
          <div className='login-form-welcome'>
            {parse(t('login_welcome'))}
          </div>
          <div className='login-form-signup'>
            {parse(t('login_switch_sign'))} <NavLink to='/register'><span>&nbsp; {parse(t('login_switch_sign_link'))}</span></NavLink>
          </div>
          <div className='login-form-container'>
            <input type='text' placeholder={parse(t('login_username_placeholder'))} value={username} onChange={(e) => setUsername(e.target.value)}></input>
            <input type='password' placeholder={parse(t('login_password_placeholder'))} value={password} onChange={(e) => setPassword(e.target.value)}></input>
            {error && <div className='input-alert-form'>{error}</div>}
            <button onClick={handleSubmit}>{parse(t('login_btn'))}</button>
          </div>

          {/* <div className='login-form-help'>
            {parse(t('login_help'))}
          </div> */}
          {/* <div className='login-form-login-methods'>
            <button style={{position: 'relative'}}>
              <div style={{position: 'absolute', marginLeft: '30px'}} >
                <img src={googleImg} alt='' style={{width: '24px', height: '24px'}} />
              </div>
              <div>{parse(t('login_google'))}</div>
            </button>
            <button style={{position: 'relative'}}>
              <div style={{position: 'absolute', marginLeft: '30px'}} >
                <img src={appleImg} alt='' style={{width: '24px', height: '24px'}} />
              </div>
              <div>{parse(t('login_apple'))}</div>
            </button>
          </div> */}
        </div>

        
      </div>
    </div>
  )
}

export default LoginScreen
