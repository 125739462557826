export const MULTI_PROMPTING_GET_REQUEST = 'MULTI_PROMPTING_GET_REQUEST'
export const MULTI_PROMPTING_GET_SUCCESS = 'MULTI_PROMPTING_GET_SUCCESS'
export const MULTI_PROMPTING_GET_FAIL = 'MULTI_PROMPTING_GET_FAIL'

export const MULTI_PROMPTING_POST_REQUEST = 'MULTI_PROMPTING_POST_REQUEST'
export const MULTI_PROMPTING_POST_SUCCESS = 'MULTI_PROMPTING_POST_SUCCESS'
export const MULTI_PROMPTING_POST_FAIL = 'MULTI_PROMPTING_POST_FAIL'

export const MULTI_PROMPTING_DELETE_REQUEST = 'MULTI_PROMPTING_DELETE_REQUEST'
export const MULTI_PROMPTING_DELETE_SUCCESS = 'MULTI_PROMPTING_DELETE_SUCCESS'
export const MULTI_PROMPTING_DELETE_FAIL = 'MULTI_PROMPTING_DELETE_FAIL'