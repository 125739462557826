import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { editAdminPost } from '../../actions/adminActions'

import EditPostImageAdminScreen from './EditPostImageAdminScreen'
import ImageViewPopupAdmin from './ImageViewPopupAdmin'
import LoaderTool from '../../components/LoaderTool'

import addImg from '../../images/admin/add.svg'
import logoSmall from '../../images/logo-small.jpg'
import closeBtnImg from '../../images/close-btn.svg'
import { useDispatch, useSelector } from 'react-redux';

function EditPostAdminScreen() {
    const dispatch = useDispatch();

    const styles = {
        thumbnailBtn: {
            width: '100px',
            height: '25px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #B4307D',
            borderRadius: '5px',
            color: '#B4307D',
            marginBottom: '10px',
            cursor: 'pointer'
        }
    }

    const navigate = useNavigate();
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const adminData = useSelector(state => state.adminPostUploadData)
    const { loading } = adminData
    
    useEffect(() => {
        if (!userInfo && !userInfo.isAdmin) {
            navigate('/')
        }
    }, [])

    let { id: selectedPostId } = useParams();
    const [data, setData] = useState({})
    const [isDraggingOverVideo, setDraggingOverVideo] = useState(false);
    const [isDraggingOverVideoThumbnail, setDraggingOverVideoThumbnail] = useState(false);
    const [isDraggingOver, setDraggingOver] = useState(false);
    const [uploadVideoFiles, setUploadVideoFiles] = useState([]);
    const [uploadVideoThumbnailFiles, setUploadVideoThumbnailFiles] = useState([]);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadedFilesOrder, setUploadedFilesOrder] = useState([])
    const [uploadedFilesStartVerse, setUploadedFilesStartVerse] = useState([])
    const [uploadedFilesEndVerse, setUploadedFilesEndVerse] = useState([])

    const [warningPopup, setWarningPopup] = useState(false)
    const [deletePostImageWarningPopup, setDeletePostImageWarningPopup] = useState(false)
    const [editPostImageWarningPopup, setEditPostImageWarningPopup] = useState(false)
    const [selectedIdDeletePostImageWarningPopup, setSelectedIdDeletePostImageWarningPopup] = useState(0)
    const [saveWarningPopup, setSaveWarningPopup] = useState(false)
    const [imageViewPopup, setImageViewPopup] = useState(false)
    const [imageViewPopupSrc, setImageViewPopupSrc] = useState()

    // SAVE STATES
    const [thumbnailImgDataKR, setThumbnailImgDataKR] = useState();
    const [thumbnailImgDataEN, setThumbnailImgDataEN] = useState();
    const [avatarImgData, setAvatarImgData] = useState();
    const [saveTitleEN, setSaveTitleEN] = useState('')
    const [saveTitleKR, setSaveTitleKR] = useState('')
    const [selectedSaveCategory, setSelectedSaveCategory] = useState(0)
    const [saveSubheadingEN, setSaveSubheadingEN] = useState('')
    const [saveSubheadingKR, setSaveSubheadingKR] = useState('')
    const [saveSummaryEN, setSaveSummaryEN] = useState('')
    const [saveSummaryKR, setSaveSummaryKR] = useState('')
    const [saveBackgroundEN, setSaveBackgroundEN] = useState('')
    const [saveBackgroundKR, setSaveBackgroundKR] = useState('')

    const [saveYoutubeURL, setSaveYoutubeURL] = useState('')
    const [saveInstagramURL, setSaveInstagramURL] = useState('')
    const [saveTiktokURL, setSaveTiktokURL] = useState('')
    const [saveNaverBlogURL, setSaveNaverBlogURL] = useState('')

    const inputRef = useRef();
    const postImageRef = useRef();
    const thumbnailKRRef = useRef();
    const thumbnailENRef = useRef();
    const avatarRef = useRef();
    const videoUploadRef = useRef();
    const videoThumbnailRef = useRef();

    // EDIT POST IMAGE STATES
    const [postImageUpload, setPostImageUpload] = useState()
    const [postImageOrder, setPostImageOrder] = useState(0)
    const [postImageStartVerse, setPostImageStartVerse] = useState(0)
    const [postImageEndVerse, setPostImageEndVerse] = useState(0)


    const assignPostDataToStates = (res) => {
        setData(res.data)
        setSaveTitleEN(res.data.title_en)
        setSaveTitleKR(res.data.title_kr)
        setSaveYoutubeURL(res.data.youtube_url)
        setSaveInstagramURL(res.data.instagram_url)
        setSaveTiktokURL(res.data.tiktok_url)
        setSaveNaverBlogURL(res.data.naver_blog_url)

        for(let i=0; i<categorySave.length; i++) {
            if (categorySave[i].en === res.data.category_en) {
                setSelectedSaveCategory(i)
                break;
            }
        }

        setSaveSubheadingEN(res.data.subheading_en)
        setSaveSubheadingKR(res.data.subheading_kr)
        setSaveSummaryEN(res.data.summary_en)
        setSaveSummaryKR(res.data.summary_kr)
        setSaveBackgroundEN(res.data.background_en)
        setSaveBackgroundKR(res.data.background_kr)
    }

    useEffect(() => {
        getPostData(selectedPostId)
    }, [selectedPostId])

    const getPostData = async (idx) => {
        try {
    
            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }
            const { data: res } = await axios.get(
                `admin_generations/api/posts/${idx}`,
                config
            )

            assignPostDataToStates(res)

        } catch (error) {
            console.log(error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
            )
        }
    }

    const editPostData = (idx) => {
        setWarningPopup(false)
        dispatch(editAdminPost(
            userInfo.token,
            idx,
            uploadVideoFiles,
            uploadVideoThumbnailFiles,
            uploadedFiles,
            uploadedFilesOrder,
            uploadedFilesStartVerse,
            uploadedFilesEndVerse,
            thumbnailImgDataEN,
            thumbnailImgDataKR,
            avatarImgData,
            saveTitleEN,
            saveTitleKR,
            categorySave[selectedSaveCategory].en,
            categorySave[selectedSaveCategory].kr,
            saveSubheadingEN,
            saveSubheadingKR,
            saveSummaryEN,
            saveSummaryKR,
            saveBackgroundEN,
            saveBackgroundKR,
            saveYoutubeURL,
            saveInstagramURL,
            saveTiktokURL,
            saveNaverBlogURL,
        ))
        .then(() => {
            navigate('/admin-posts')
        })
    }

    const deletePost = async (idx) => {
        try {

            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }
    
            const { data: res } = await axios.delete(
                `admin_generations/api/posts/delete/${idx}`,
                config
            )

            navigate('/admin-posts', {replace: true})

        } catch (error) {
            console.log(error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
            )
        }
    }

    const changePostImage = async (idx) => {
        try {

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data; charset=utf-8',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }
            const formData = new FormData();

            {postImageUpload && formData.append('image_file', postImageUpload);}
            formData.append('image_order', postImageOrder);
            formData.append('image_start_verse', postImageStartVerse);
            formData.append('image_end_verse', postImageEndVerse);            

            const { data } = await axios.post(
                `admin_generations/api/posts/image/update/${idx}`,
                formData,
                config
            )

            setEditPostImageWarningPopup(false)
            getPostData(selectedPostId)

        } catch (error) {
            console.log(error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
            )
        }
    }

    const deletePostImage = async (idx) => {
        try {

            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }
    
            const { data: res } = await axios.delete(
                `admin_generations/api/posts/image/delete/${idx}`,
                config
            )

            getPostData(selectedPostId)
            setDeletePostImageWarningPopup(false)

        } catch (error) {
            console.log(error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
            )
        }
    }

    const deletePostVideo = async (idx) => {
        try {

            const config = {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }
    
            const { data: res } = await axios.delete(
                `admin_generations/api/posts/video/delete/${idx}`,
                config
            )

            getPostData(selectedPostId)

        } catch (error) {
            console.log(error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message
            )
        }
    }


    const categorySave = [
        { kr: '역사', en: 'history' },
        { kr: '성경', en: 'bible' },
        { kr: '키즈', en: 'kids' },
        { kr: '경제', en: 'economy' },
        { kr: '경영', en: 'management' },
        { kr: '인문', en: 'humanities' },
        { kr: '과학', en: 'science' },
        { kr: '자기계발', en: 'self-improvement' },
        { kr: '문화', en: 'culture' }
    ]



    function handleThumbnailUpload(event, languageKR=true) {
        event.preventDefault()
        const file = event.target.files[0]

        if (languageKR) {
            setThumbnailImgDataKR(file)
        } else {
            setThumbnailImgDataEN(file)
        }
    }

    function handleAvatarUpload(event) {
        event.preventDefault()
        const file = event.target.files[0]

        setAvatarImgData(file)
    }

    const handleDragOver = (event, videos=false, thumbnail=false) => {
        event.preventDefault();

        if (videos) {
            setDraggingOverVideo(true);
        } else if (thumbnail) {
            setDraggingOverVideoThumbnail(true)
        } else {
            setDraggingOver(true);
        }
    }

    const handleDragLeave = (videos=false, thumbnail=false) => {
        if (videos) {
            setDraggingOverVideo(false);
        } else if (thumbnail) {
            setDraggingOverVideoThumbnail(false)
        } else {
            setDraggingOver(false);
        }
    };

    async function handleOnDrop(event, drop=false, video=false, thumbnail=false) {
        event.preventDefault();

        const files = drop ? event.target.files : event.dataTransfer.files

        var tempData = []
        var tempDataOrder = []
        var tempDataStartVerse = []
        var tempDataEndVerse = []

        Array.from(files).forEach(file => {
            if (isAcceptedFile(file, video)) {
                tempData.push(file)
                tempDataOrder.push(0)
                tempDataStartVerse.push(0)
                tempDataEndVerse.push(0)
            }
        });

        if (video) {
            setDraggingOverVideo(false);
            setUploadVideoFiles([...uploadVideoFiles, ...tempData])
        } else if (thumbnail) {
            setDraggingOverVideoThumbnail(false);
            setUploadVideoThumbnailFiles([...uploadVideoThumbnailFiles, ...tempData])
        } else {
            setDraggingOver(false);
            setUploadedFiles([...uploadedFiles, ...tempData])
            setUploadedFilesOrder([...uploadedFilesOrder, ...tempDataOrder])
            setUploadedFilesStartVerse([...uploadedFilesStartVerse, ...tempDataStartVerse])
            setUploadedFilesEndVerse([...uploadedFilesEndVerse, ...tempDataEndVerse])
        }
    }

    function isAcceptedFile(file, video=false) {
        let acceptedImageTypes = ['image/png', 'image/jpeg']

        if (video) {
            acceptedImageTypes = ['video/mp4']
        }
        return file && acceptedImageTypes.includes(file['type'])
    }

    return (
        <div style={{overflow: 'auto'}}>
            <div className='gnrt-img-navbar'>
                <img src={logoSmall} alt='logo' />
                <NavLink className='gnrt-img-navbar-title' to={'/my-page'} style={{marginLeft: '10px'}}>
                    Admin Page
                </NavLink>
                <div className='gnrt-img-navbar-topic' style={{marginLeft: '4px', marginRight: '4px'}}>
                    /
                </div>
                <NavLink className='gnrt-img-navbar-topic' style={{color: '#000'}} to={'/admin-posts'}>
                    Posts
                </NavLink>
                <div className='gnrt-img-navbar-topic' style={{marginLeft: '4px', marginRight: '4px'}}>
                    /
                </div>
                <div className='gnrt-img-navbar-topic' >
                    Edit
                </div>

                <div className='gnrt-img-navbar-close'>
                    <img src={closeBtnImg} alt='' onClick={() => navigate('/admin-posts')}/>
                </div>
            </div>

            <div  style={{padding: '20px'}}>
                <div className='gnrt-img-main-display-header' style={{justifyContent: 'space-between'}}>
                    <div style={{fontSize: '30px'}}>
                        Edit Post
                    </div>

                    <div>
                        <button className='bible-admin-save-btn' style={{marginRight: '15px'}} onClick={() => setWarningPopup(true)}>
                            Delete
                        </button>
                        <button className='bible-admin-save-btn' onClick={() => {
                            setSaveWarningPopup(true)
                            setWarningPopup(true)
                        }}>
                            Save
                        </button>
                    </div>
                    
                </div>

                <div className='bible-admin-container' style={{marginTop: '40px'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{marginBottom: '10px'}}>
                            1. 썸네일 KR
                        </div>

                        {thumbnailImgDataKR ? (
                            <div style={styles.thumbnailBtn} onClick={() => setThumbnailImgDataKR()}>reset</div>
                        ) : (
                            <>
                                <input
                                    type='file'
                                    onChange={(event) => handleThumbnailUpload(event, true)}
                                    hidden
                                    ref={thumbnailKRRef}
                                />
                                <div style={styles.thumbnailBtn} onClick={() => thumbnailKRRef.current.click()}>change</div>
                            </>
                        )}

                        <div className='bible-admin-uploaded-div' style={{width: '150px', height: '150px', cursor: 'pointer'}} onClick={() => {
                            if (thumbnailImgDataKR) {
                                setImageViewPopupSrc(URL.createObjectURL(thumbnailImgDataKR))
                            } else {
                                setImageViewPopupSrc(data.thumbnail_kr)
                            }
                            setImageViewPopup(true)
                        }}>
                            {
                                thumbnailImgDataKR ? (
                                    <img src={URL.createObjectURL(thumbnailImgDataKR)} alt='' style={{width: '150px', height: '150px', border: '5px'}}></img>
                                ) : (
                                    data ? (
                                        <img src={data.thumbnail_kr} alt='' style={{width: '150px', height: '150px', border: '5px'}}></img>
                                    ) : (
                                        <img src={addImg} alt='' />
                                    )
                                )
                            }                        
                        </div>
                    </div>

                    <div style={{display: 'flex', flexDirection: 'column', marginLeft: '30px'}}>
                        <div style={{marginBottom: '10px'}}>
                            1. 썸네일 EN
                        </div>

                        {thumbnailImgDataEN ? (
                            <div style={styles.thumbnailBtn} onClick={() => setThumbnailImgDataEN()}>reset</div>
                        ) : (
                            <>
                                <input
                                    type='file'
                                    onChange={(event) => handleThumbnailUpload(event, false)}
                                    hidden
                                    ref={thumbnailENRef}
                                />
                                <div style={styles.thumbnailBtn} onClick={() => thumbnailENRef.current.click()}>change</div>
                            </>
                        )}

                        <div className='bible-admin-uploaded-div' style={{width: '150px', height: '150px', cursor: 'pointer'}} onClick={() => {
                            if (thumbnailImgDataEN) {
                                setImageViewPopupSrc(URL.createObjectURL(thumbnailImgDataEN))
                            } else {
                                setImageViewPopupSrc(data.thumbnail_en)
                            }
                            setImageViewPopup(true)
                        }}>
                            {
                                thumbnailImgDataEN ? (
                                    <img src={URL.createObjectURL(thumbnailImgDataEN)} alt='' style={{width: '150px', height: '150px', border: '5px'}}></img>
                                ) : (
                                    data ? (
                                        <img src={data.thumbnail_en} alt='' style={{width: '150px', height: '150px', border: '5px'}}></img>
                                    ) : (
                                        <img src={addImg} alt='' />
                                    )
                                )
                            }                        
                        </div>
                    </div>
                </div>

                <div className='bible-admin-container' style={{marginTop: '40px'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{marginBottom: '10px'}}>
                            2. 프로필이미지
                        </div>

                        {avatarImgData ? (
                            <div style={styles.thumbnailBtn} onClick={() => setAvatarImgData()}>reset</div>
                        ) : (
                            <>
                                <input
                                    type='file'
                                    onChange={(event) => handleAvatarUpload(event)}
                                    hidden
                                    ref={avatarRef}
                                />
                                <div style={styles.thumbnailBtn} onClick={() => avatarRef.current.click()}>change</div>
                            </>
                        )}

                        <div className='bible-admin-uploaded-div' style={{width: '150px', height: '150px', borderRadius: '100px', cursor: 'pointer'}} onClick={() => {
                            if (avatarImgData) {
                                setImageViewPopupSrc(URL.createObjectURL(avatarImgData))
                            } else {
                                setImageViewPopupSrc(data.profile_image)
                            }
                            setImageViewPopup(true)
                        }}>
                            {avatarImgData ? (
                                <img src={URL.createObjectURL(avatarImgData)} alt='' style={{width: '150px', height: '150px', borderRadius: '100px'}}></img>
                            ) : (
                                data ? (
                                    <img src={data.profile_image} alt='' style={{width: '150px', height: '150px', borderRadius: '100px'}}></img>
                                ) : (
                                    <img src={addImg} alt='' />
                                )
                            )}
                        </div>
                    </div>
                    
                    <div style={{width: '100%'}}>
                        <div style={{display: 'flex', width: '100%'}}>
                            <div className='bible-admin-split' style={{marginRight: '5px', marginLeft: '30px'}}>
                                <div>
                                    3. 제목 (search data) - KR
                                </div>
                                <textarea style={{width: 'calc(100% - 40px)', height: '170px', marginTop: '8px'}} value={saveTitleKR} onChange={(e) => setSaveTitleKR(e.target.value)}/>
                            </div>
                            <div className='bible-admin-split' style={{marginLeft: '5px'}}>
                                <div>
                                    3. 제목 (search data) - EN
                                </div>
                                <textarea style={{width: 'calc(100% - 40px)', height: '170px', marginTop: '8px'}} value={saveTitleEN} onChange={(e) => setSaveTitleEN(e.target.value)}/>
                            </div>
                        </div>
                        
                    </div>

                </div>

                <div className='bible-admin-container' style={{marginTop: '40px', flexDirection: 'column'}}>
                    <div>
                        4.
                    </div>
                    <div className='bible-admin-split' style={{marginRight: '5px', marginTop: '20px'}}>
                        <div>Youtube URL</div>
                        <textarea style={{width: 'calc(100% - 40px)', height: '22px', marginTop: '5px'}} value={saveYoutubeURL} onChange={(e) => setSaveYoutubeURL(e.target.value)}/>
                    </div>
                    <div className='bible-admin-split' style={{marginRight: '5px'}}>
                        <div>Instagram URL</div>
                        <textarea style={{width: 'calc(100% - 40px)', height: '22px', marginTop: '5px'}} value={saveInstagramURL} onChange={(e) => setSaveInstagramURL(e.target.value)}/>
                    </div>
                    <div className='bible-admin-split' style={{marginRight: '5px'}}>
                        <div>Tiktok URL</div>
                        <textarea style={{width: 'calc(100% - 40px)', height: '22px', marginTop: '5px'}} value={saveTiktokURL} onChange={(e) => setSaveTiktokURL(e.target.value)}/>
                    </div>
                    <div className='bible-admin-split' style={{marginRight: '5px'}}>
                        <div>Naver Blog URL</div>
                        <textarea style={{width: 'calc(100% - 40px)', height: '22px', marginTop: '5px'}} value={saveNaverBlogURL} onChange={(e) => setSaveNaverBlogURL(e.target.value)}/>
                    </div>
                </div>

                <div className='bible-admin-container' style={{marginTop: '40px'}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{marginBottom: '10px'}}>
                            5. 카테고리 (필수)
                        </div>
                        
                        <div style={{display: 'flex'}}>
                            {categorySave.map((category, idx) => (
                                <div key={idx} className='bible-admin-categories-item' onClick={() => setSelectedSaveCategory(idx)} style={{marginRight: '5px', borderColor: selectedSaveCategory === idx && '#B4307D', color: selectedSaveCategory === idx && '#B4307D'}}>
                                    {category.kr}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className='bible-admin-container' style={{marginTop: '40px'}}>
                    <div className='bible-admin-split' style={{marginRight: '5px'}}>
                        <div>
                            6. 소제목 (search data) - KR
                        </div>
                        <textarea style={{width: 'calc(100% - 40px)', height: '22px', marginTop: '5px'}} value={saveSubheadingKR} onChange={(e) => setSaveSubheadingKR(e.target.value)}/>
                    </div>
                    <div className='bible-admin-split' style={{marginLeft: '5px'}}>
                        <div>
                            6. 소제목 (search data) - EN
                        </div>
                        <textarea style={{width: 'calc(100% - 40px)', height: '22px', marginTop: '5px'}} value={saveSubheadingEN} onChange={(e) => setSaveSubheadingEN(e.target.value)}/>
                    </div>
                </div>

                <div className='bible-admin-container' style={{marginTop: '40px'}}>
                    <div className='bible-admin-split' style={{marginRight: '5px'}}>
                        <div>
                            7. 요약(핵심내용) - KR
                        </div>
                        <textarea style={{width: 'calc(100% - 40px)', height: '200px', marginTop: '5px'}} value={saveSummaryKR} onChange={(e) => setSaveSummaryKR(e.target.value)}/>
                    </div>
                    <div className='bible-admin-split' style={{marginLeft: '5px'}}>
                        <div>
                            7. 요약(핵심내용) - EN
                        </div>
                        <textarea style={{width: 'calc(100% - 40px)', height: '200px', marginTop: '5px'}} value={saveSummaryEN} onChange={(e) => setSaveSummaryEN(e.target.value)}/>
                    </div>
                </div>

                <div className='bible-admin-container' style={{marginTop: '40px'}}>
                    <div className='bible-admin-split' style={{marginRight: '5px'}}>
                        <div>
                            8. 배경 등 설명 - KR
                        </div>
                        <textarea style={{width: 'calc(100% - 40px)', height: '200px', marginTop: '5px'}} value={saveBackgroundKR} onChange={(e) => setSaveBackgroundKR(e.target.value)}/>
                    </div>
                    <div className='bible-admin-split' style={{marginLeft: '5px'}}>
                        <div>
                            8. 배경 등 설명 - EN
                        </div>
                        <textarea style={{width: 'calc(100% - 40px)', height: '200px', marginTop: '5px'}} value={saveBackgroundEN} onChange={(e) => setSaveBackgroundEN(e.target.value)}/>
                    </div>
                </div>

                <div className='bible-admin-container' style={{marginTop: '40px'}}>
                    <div className='admin-post' style={{width: '100%'}}>
                        <div style={{marginBottom: '10px'}}>
                            9. Video
                        </div>
                        <div className='admin-table-wrapper'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>order</th>
                                        <th>video</th>
                                        <th>thumbnail</th>
                                        <th>action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.video_file_name_list && data.video_file_name_list.map((v, idx) => (
                                        <tr key={idx} className='table-row'>
                                            <td>{v.order}</td>
                                            <td>
                                                {v.file_name}
                                                {/* <video style={{height: '80px', width: 'fit-content'}} controls>
                                                    <source src={`https://www.dandycv.com/media/${v.file_name}`} type="video/mp4"/>
                                                    Your browser does not support the video tag.
                                                </video> */}
                                            </td>
                                            <td>
                                                <img src={`https://www.dandycv.com/media/${v.thumbnail}`} alt='' style={{height: '80px'}} />
                                            </td>
                                            <td>
                                                <button onClick={() => deletePostVideo(v.id)}>
                                                    delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className='bible-admin-container' style={{marginTop: '40px'}}>
                        <div className='bible-admin-split' style={{marginRight: '5px'}}>
                            <div>
                                Video
                            </div>
                            
                            <div
                                className='bible-admin-draganddrop-div'
                                style={
                                    {
                                        width: 'calc(100% - 20px)',
                                        height: '325px',
                                        marginTop: '10px',
                                        padding: '10px',
                                        marginBottom: '42px',
                                        borderColor: isDraggingOverVideo && '#B4307D',
                                        color: isDraggingOverVideo && '#B4307D'
                                    }
                                }
                                onDragOver={(e) => handleDragOver(e, true, false)}
                                onDragLeave={() => handleDragLeave(true, false)}
                                onDrop={(event) => handleOnDrop(event, false, true, false)}
                            >
                                <input
                                    type='file'
                                    onChange={(event) => handleOnDrop(event, true, true, false)}
                                    hidden
                                    accept="video/mp4"
                                    ref={videoUploadRef}
                                    multiple
                                />

                                <div className='bible-admin-draganddrop-div-grid'>
                                    <div className='bible-admin-uploaded-div' style={{width: '89px', height: '89px', cursor: 'pointer'}} onClick={() => videoUploadRef.current.click()}>
                                        <img src={addImg} alt='' />
                                    </div>

                                    {
                                        uploadVideoFiles.map((file, file_idx) => (
                                            <div key={file_idx} className='bible-admin-uploaded-div'>
                                                <video>
                                                    <source src={URL.createObjectURL(file)} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='bible-admin-split' style={{marginRight: '5px'}}>
                            <div>
                                Video's Thumbnail
                            </div>
                            
                            <div
                                className='bible-admin-draganddrop-div'
                                style={
                                    {
                                        width: 'calc(100% - 20px)',
                                        height: '325px',
                                        marginTop: '10px',
                                        padding: '10px',
                                        marginBottom: '42px',
                                        borderColor: isDraggingOverVideoThumbnail && '#B4307D',
                                        color: isDraggingOverVideoThumbnail && '#B4307D'
                                    }
                                }
                                onDragOver={(e) => handleDragOver(e, false, true)}
                                onDragLeave={() => handleDragLeave(false, true)}
                                onDrop={(event) => handleOnDrop(event, false, false, true)}
                            >
                                <input
                                    type='file'
                                    onChange={(event) => handleOnDrop(event, true, false, true)}
                                    hidden
                                    accept="image/png, image/jpeg"
                                    ref={videoThumbnailRef}
                                    multiple
                                />

                                <div className='bible-admin-draganddrop-div-grid'>
                                    <div className='bible-admin-uploaded-div' style={{width: '89px', height: '89px', cursor: 'pointer'}} onClick={() => videoThumbnailRef.current.click()}>
                                        <img src={addImg} alt='' />
                                    </div>

                                    {
                                        uploadVideoThumbnailFiles.map((file, file_idx) => (
                                            <div key={file_idx} className='bible-admin-uploaded-div'>
                                                <img src={URL.createObjectURL(file)} alt='' />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                </div>
                
                <div className='bible-admin-container' style={{marginTop: '40px'}}>
                    <div className='admin-post' style={{width: '100%'}}>
                        <div style={{marginBottom: '10px'}}>
                            10. 관련 이미지들
                        </div>
                        <div className='admin-table-wrapper'>
                            <table>
                                <thead>
                                    <tr>
                                        <th>order</th>
                                        <th>image</th>
                                        <th>start verse</th>
                                        <th>end verse</th>
                                        <th>action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.image_file_name_list && data.image_file_name_list.map((v, idx) => (
                                        <tr key={idx} className='table-row'>
                                            <td>{v.order}</td>
                                            <td>
                                                <img src={`https://www.dandycv.com/media/${v.file_name}`} alt='' style={{height: '80px', cursor: 'pointer'}} onClick={() => {
                                                    setImageViewPopupSrc(`https://www.dandycv.com/media/${v.file_name}`)
                                                    setImageViewPopup(true)
                                                }}/>
                                            </td>
                                            <td>{v.start_verse}</td>
                                            <td>{v.end_verse}</td>
                                            <td>
                                                <button style={{marginRight: '4px'}} onClick={() => {
                                                    setPostImageUpload()
                                                    setPostImageOrder(v.order)
                                                    setPostImageStartVerse(v.start_verse)
                                                    setPostImageEndVerse(v.end_verse)
                                                    setSelectedIdDeletePostImageWarningPopup(idx)
                                                    setEditPostImageWarningPopup(true)
                                                }}>
                                                    modify
                                                </button>
                                                <button onClick={() => {
                                                    setSelectedIdDeletePostImageWarningPopup(v.id)
                                                    setDeletePostImageWarningPopup(true)
                                                }}>
                                                    delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        
                    </div>
                </div>

                <div className='bible-admin-container' style={{marginTop: '40px'}}>
                    <div style={{width: '100%', marginTop: '25px'}}>
                        <div className='bible-admin-split' style={{marginRight: '5px', width: 'calc(100%)'}}>
                            <div>
                                10. Image upload
                            </div>

                            <div style={{display: 'flex', justifyContent: 'right', marginBottom: '4px'}}>
                                <button
                                    style={{marginTop: '40px', marginRight: '4px'}}
                                    className='bible-admin-split-reset-btn'
                                    onClick={() => setUploadedFiles([])}
                                >
                                    Reset
                                </button>
                                <button
                                    style={{marginTop: '40px'}}
                                    className='bible-admin-split-reset-btn'
                                    onClick={() => inputRef.current.click()}
                                >
                                    Add
                                </button>
                            </div>
                            
                            <div
                                className='bible-admin-draganddrop-div admin-post'
                                style={
                                    {
                                        width: 'calc(100%)',
                                        height: '325px',
                                        marginTop: '10px',
                                        marginBottom: '42px',
                                        borderColor: isDraggingOver && '#B4307D',
                                        color: isDraggingOver && '#B4307D'
                                    }
                                }
                                onDragOver={(e) => handleDragOver(e, false, false)}
                                onDragLeave={() => handleDragLeave(false, false)}
                                onDrop={(event) => handleOnDrop(event, false)}
                            >
                                <input
                                    type='file'
                                    onChange={(event) => handleOnDrop(event, true)}
                                    hidden
                                    accept="image/png, image/jpeg"
                                    ref={inputRef}
                                    multiple
                                />

                                <div className='admin-table-wrapper' style={{height: '325px', border: 'none'}}>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>order</th>
                                                <th>image</th>
                                                <th>start verse</th>
                                                <th>end verse</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                uploadedFiles.map((file, file_idx) => (
                                                    <tr key={file_idx} className='table-row'>
                                                        <td>
                                                            <input type="number" min="0" step="1" value={uploadedFilesOrder[file_idx]} onChange={(e) => {
                                                                let newArr = [...uploadedFilesOrder];
                                                                newArr[file_idx] = e.target.value
                                                                setUploadedFilesOrder(newArr)
                                                            }}/>
                                                        </td>
                                                        <td><img src={URL.createObjectURL(file)} alt='' style={{height: '80px'}} /></td>
                                                        <td><input type='number' min={0} step={1} value={uploadedFilesStartVerse[file_idx]} onChange={(e) => {
                                                            let newArr = [...uploadedFilesStartVerse]
                                                            newArr[file_idx] = e.target.value
                                                            setUploadedFilesStartVerse(newArr)
                                                        }}/></td>
                                                        <td><input type='number' min={0} step={1} value={uploadedFilesEndVerse[file_idx]} onChange={(e) => {
                                                            let newArr = [...uploadedFilesEndVerse]
                                                            newArr[file_idx] = e.target.value
                                                            setUploadedFilesEndVerse(newArr)
                                                        }}/></td>
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {loading && <LoaderTool />}

            <ImageViewPopupAdmin trigger={imageViewPopup} setTrigger={setImageViewPopup} imageSrc={imageViewPopupSrc} />

            {deletePostImageWarningPopup ? (
                <div className='admin-popup-overlay' onClick={() => setDeletePostImageWarningPopup(false)}>
                    <div className='admin-popup-content' style={{width: '420px'}} onClick={(e) => e.stopPropagation()}>
                        <div className='popup-downloader-header'>
                            <div className="popup-downloader-title">
                                Delete
                            </div>

                            <img src={closeBtnImg} alt='' style={{cursor: 'pointer'}} onClick={() => setDeletePostImageWarningPopup(false)}/>
                        </div>

                        <div className='hr' style={{marginLeft: '16px', marginRight: '16px'}} />

                        <div className='popup-deleter-content-txt'>
                            Do you want to delete the selected image?
                        </div>

                        <div className='popup-deleter-buttons' style={{width: 'calc(100% - 35px)', justifyContent: 'center'}}>
                            <button style={{border: '1px solid #B4307D', color: '#B4307D', marginRight: '10px'}} onClick={() => setDeletePostImageWarningPopup(false)}>
                                Cancel
                            </button>
                
                            <button onClick={() => deletePostImage(selectedIdDeletePostImageWarningPopup)}>
                                OK
                            </button>
                        </div>  
                    </div>
                </div>
            ) : ('')}

            {warningPopup ? (
                <div className='admin-popup-overlay' onClick={() => {
                    setSaveWarningPopup(false)
                    setWarningPopup(false)
                }}>
                    <div className='admin-popup-content' style={{width: '500px'}} onClick={(e) => e.stopPropagation()}>
                        <div className='popup-downloader-header'>
                            <div className="popup-downloader-title">
                                {saveWarningPopup ? 'Upload' : 'Delete'}
                            </div>

                            <img src={closeBtnImg} alt='' style={{cursor: 'pointer'}} onClick={() => {
                                setSaveWarningPopup(false)
                                setWarningPopup(false)
                            }}/>
                        </div>
                        <div className='hr' style={{marginLeft: '16px', marginRight: '16px'}} />
                        <div className='popup-deleter-content-txt'>
                            {saveWarningPopup ? (
                                <div>Do you want to upload the content?</div>
                            ) : (
                                <div>Do you want to delete the content?</div>
                            )}
                        </div>
                        <div className='popup-deleter-buttons' style={{width: 'calc(100% - 35px)', justifyContent: 'center'}}>
                            <button style={{border: '1px solid #B4307D', color: '#B4307D', marginRight: '10px'}} onClick={() => {
                                setSaveWarningPopup(false)
                                setWarningPopup(false)
                            }}>
                                Cancel
                            </button>
                
                            <button onClick={() => {
                                saveWarningPopup ? (
                                    editPostData(data.id)
                                ) : (
                                    deletePost(data.id)
                                )
                            }}>
                                OK
                            </button>
                        </div>  
                    </div>
                </div>
            ) : ''}

            {editPostImageWarningPopup ? (
                <div className='admin-popup-overlay' onClick={() => setEditPostImageWarningPopup(false)}>
                    <div className='admin-popup-content' style={{width: '500px', height: '350px'}} onClick={(e) => e.stopPropagation()}>
                        <div className='popup-downloader-header'>
                            <div className="popup-downloader-title">
                                Edit
                            </div>

                            <img src={closeBtnImg} alt='' style={{cursor: 'pointer'}} onClick={() => setEditPostImageWarningPopup(false)}/>
                        </div>

                        <div className='hr' style={{marginLeft: '16px', marginRight: '16px'}} />

                        <div style={{padding: '16px'}}>
                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <div>
                                    <input
                                        type='file'
                                        onChange={(event) => setPostImageUpload(event.target.files[0])}
                                        hidden
                                        ref={postImageRef}
                                    />
                                    {postImageUpload ? (
                                        <img src={URL.createObjectURL(postImageUpload)} alt='' style={{height: '80px'}} ></img>
                                    ) : (
                                        data.image_file_name_list && <img src={`https://www.dandycv.com/media/${data.image_file_name_list[selectedIdDeletePostImageWarningPopup].file_name}`} alt='' style={{height: '80px'}} />
                                    )}
                                    
                                </div>
                                <div>
                                    {postImageUpload ? (
                                        <button onClick={() => setPostImageUpload()}>Reset Image</button>
                                    ): (
                                        <button onClick={() => postImageRef.current.click()}>Change Image</button>
                                    )}
                                </div>
                            </div>

                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '8px'}}>
                                <div>
                                    Id
                                </div>
                                <div>
                                    {data.image_file_name_list[selectedIdDeletePostImageWarningPopup].id}
                                </div>
                            </div>

                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '8px'}}>
                                <div>
                                    Order
                                </div>
                                <div>
                                    <input type="number" min="0" step="1" value={postImageOrder} onChange={(e) => setPostImageOrder(e.target.value)}/>
                                </div>
                            </div>

                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '8px'}}>
                                <div>
                                    Start Verse
                                </div>
                                <div>
                                    <input type="number" min="0" step="1" value={postImageStartVerse} onChange={(e) => setPostImageStartVerse(e.target.value)}/>
                                </div>
                            </div>

                            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '8px'}}>
                                <div>
                                    End Verse
                                </div>
                                <div>
                                    <input type="number" min="0" step="1" value={postImageEndVerse} onChange={(e) => setPostImageEndVerse(e.target.value)}/>
                                </div>
                            </div>
                        </div>
                        {/* handlePostImageChange(idx) */}
                        <div className='popup-deleter-buttons' style={{width: 'calc(100% - 35px)', justifyContent: 'center'}}>
                            <button style={{border: '1px solid #B4307D', color: '#B4307D', marginRight: '10px'}} onClick={() => setEditPostImageWarningPopup(false)}>
                                Cancel
                            </button>
                
                            <button onClick={() => changePostImage(data.image_file_name_list[selectedIdDeletePostImageWarningPopup].id)}>
                                OK
                            </button>
                        </div>  
                    </div>
                </div>
            ) : ''}

        </div>
    )
}

export default EditPostAdminScreen
