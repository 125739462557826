import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { loadPaymentWidget, ANONYMOUS } from "@tosspayments/payment-widget-sdk";
import { nanoid } from "nanoid";
import { useSelector } from 'react-redux';

const clientKey = process.env.REACT_APP_TOSS_CLIENT_KEY

function AccountPaymentComponent() {
    const navigate = useNavigate();
    const location = useLocation();
    const price = location.state?.data || null

    const [paymentWidget, setPaymentWidget] = useState(null);
    const paymentMethodsWidgetRef = useRef(null);

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {
        if (!userInfo) {
            navigate('/login')
        } else if (!price) {
            navigate('/my-page/account-settings?app=subscription')
        }
    }, [navigate, userInfo, price])

    useEffect(() => {
        const fetchPaymentWidget = async () => {
          try {
            const loadedWidget = await loadPaymentWidget(clientKey, ANONYMOUS);
            setPaymentWidget(loadedWidget);
          } catch (error) {
            console.error("Error fetching payment widget:", error);
          }
        };
    
        fetchPaymentWidget();
    }, []);
    
    useEffect(() => {
        if (paymentWidget == null) {
          return;
        }
    
        const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
          "#payment-widget",
          { value: price },
          { variantKey: "DEFAULT" }
        );
    
        paymentWidget.renderAgreement(
          "#agreement", 
          { variantKey: "AGREEMENT" }
        );
    
        paymentMethodsWidgetRef.current = paymentMethodsWidget;
    }, [paymentWidget, price]);
    
    useEffect(() => {
        const paymentMethodsWidget = paymentMethodsWidgetRef.current;
    
        if (paymentMethodsWidget == null) {
          return;
        }
    
        paymentMethodsWidget.updateAmount(price);
    }, [price]);
    
    const handlePaymentRequest = async () => {
        // 결제를 요청하기 전에 orderId, amount를 서버에 저장하세요.
        // 결제 과정에서 악의적으로 결제 금액이 바뀌는 것을 확인하는 용도입니다.
        try {
          await paymentWidget?.requestPayment({
            orderId: nanoid(),
            orderName: 'dandycv',
            // customerName: "김토스",
            // customerEmail: "customer123@gmail.com",
            // customerMobilePhone: "01012341234",
            successUrl: `https://www.dandycv.com/payments/success/${userInfo.username}`,
            failUrl: `https://www.dandycv.com/payments/fail`,
          });
        } catch (error) {
          console.error("Error requesting payment:", error);
        }
    };

    return (
        <div className='account-settings-subscription-component' >
            <div className='account-settings-subscription-component-container' >
                <div style={{width: '100%'}}>
                    {/* 결제 UI, 이용약관 UI 영역 */}
                    <div id="payment-widget" />
                    <div id="agreement" />
                    {/* 결제하기 버튼 */}
                    <button
                        onClick={handlePaymentRequest}
                        style={{
                            marginTop: '40px', background: '#B4307D', border: 'none', width: '100px', height: '50px', cursor: 'pointer', fontSize: '16px', color: '#FFF', borderRadius: '5px'
                        }}
                    >
                        Pay
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AccountPaymentComponent
