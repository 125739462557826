import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../actions/userActions'

import RemoveBackgroundDeletePopup from '../components/RemoveBackgroundDeletePopup'

import downloadImg from '../images/drag&drop_download.svg'
import deleteImg from '../images/drag&drop_delete.svg'

import { use } from 'i18next';

function RemoveBackgroundHistoryComponent() {

  const [data, setData] = useState([]);
  const [paginatorValue, setPaginatorValue] = useState(1);
  const [totalPaginatorNumArray, setTotalPaginatorNumArray] = useState([1]);

  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const [deleterPopup, setDeleterPopup] = useState(false)

  const userLogin = useSelector(state => state.userLogin)
  const { userInfo } = userLogin

  const [windowSize, setWindowSize] = useState(getWindowSize());

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const logoutHandler = () => {
      dispatch(logout())
      navigate('/login')
  }
  
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    processImage();
  }, [userInfo.username, paginatorValue, setTotalPaginatorNumArray])

  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }

  function processImage() {
    return new Promise((resolve, reject) => {    
        const formData = new FormData();
        formData.append('username', userInfo.username);
        formData.append('itemsPerPage', 10);
        formData.append('pageNumber', paginatorValue);

        axios.post(
          'remove_background/user_history_entries/',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${userInfo.token}`
            }
          }
        )
        .then(response => {
          if (response.data.user_404) {
            logoutHandler();
          } else {
            setData(response.data.data)

            // paginator display logic 
            const tempArray = (response.data.num_pages < 6) ? (Array.from({length: response.data.num_pages}, (_, i) => i + 1)) : (
              paginatorValue < 4 ? [1, 2, 3, 4, '...', response.data.num_pages] :
              (paginatorValue + 2 < response.data.num_pages ? [1, '...', paginatorValue - 1, paginatorValue, paginatorValue + 1, '...', response.data.num_pages] :
              [1, '...', response.data.num_pages - 3, response.data.num_pages - 2, response.data.num_pages - 1, response.data.num_pages])
            );

            setTotalPaginatorNumArray(tempArray)
          }

          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      const allIds = data.map((item, idx) => idx);
      setSelectedRows(allIds);
    }
    setSelectAll(!selectAll);
  };

  const toggleCheckbox = (id) => {
    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter((rowId) => rowId !== id));
      setSelectAll(false)
    } else {
      setSelectedRows([...selectedRows, id]);
      
      if (selectedRows.length + 1 === data.length) {
        setSelectAll(true)
      }
    }
  };

  function shortenBytes(n) {
    const k = n > 0 ? Math.floor((Math.log2(n)/10)) : 0;
    const rank = (k > 0 ? 'KMGT'[k - 1] : '') + 'b';
    const count = (n / Math.pow(1000, k)).toFixed(1);
    return count + rank;
  }

  const handleDownloadImages = () => {

    selectedRows.forEach(idx => {
      fetch('https://www.dandycv.com/media/' + data[idx].image_after_name)
      .then((response) => response.blob())
      .then((blob) => {
          // Create a Blob object and generate a URL for it
          const url = window.URL.createObjectURL(blob);

          // Create an anchor element for downloading
          const a = document.createElement('a');
          a.href = url;
          a.download = data[idx].imageName + '.' + data[idx].imageCategory; // Specify the desired file name
          document.body.appendChild(a);

          // Trigger a click event on the anchor element to initiate the download
          a.click();

          // Remove the anchor element from the DOM
          document.body.removeChild(a);
      });
    })
}

  return (
    <div className='rmv-bg-history-container'>
      <div className='rmv-bg-history-navbar'>
        <div>
        </div>
        <div className='rmv-bg-history-navbar-right'>
          {/* <div className='rmv-bg-history-navbar-right-date-chooser'>
            <input type="text" placeholder="2023-06-01" name="search"></input>
            <div style={{color: '#000', marginRight: '16px'}}>
              ~
            </div>
            <input type="text" placeholder="2023-06-01" name="search"></input>
          </div>
          <input type="text" placeholder="Search" name="search"></input>
          <button>
            <i className="fa-solid fa-magnifying-glass"></i>
          </button> */}
          <div>
              <img src={downloadImg} alt='download' style={{cursor: 'pointer', marginRight: '30px'}} onClick={() => handleDownloadImages()}/>
              <img src={deleteImg} alt='delete' style={{cursor: 'pointer'}} onClick={() => setDeleterPopup(true)}/>
          </div>
        </div>
      </div>

      <div className='rmv-bg-history-navbar-mobile'>
        <div className='hr' style={{marginTop: '12px'}}></div>

        <div className='rmv-bg-history-navbar-mobile-right'>
          <div>
              <img src={downloadImg} alt='download' style={{cursor: 'pointer', marginRight: '30px'}} onClick={() => handleDownloadImages()}/>
              <img src={deleteImg} alt='delete' style={{cursor: 'pointer'}} onClick={() => setDeleterPopup(true)}/>
          </div>
          <input
            type="checkbox"
            style={{marginLeft: '38px'}}
            checked={selectAll}
            onChange={toggleSelectAll}
          />

        </div>
      </div>

      <div className='rmv-bg-history-table'>
        {windowSize.innerWidth > 1100 ? (
          <table>
            <thead>
              <tr>
                <th>
                  <div className='rmv-bg-history-table-item'>
                    <input
                        type="checkbox"
                        checked={selectAll}
                        onChange={toggleSelectAll}
                    />
                  </div>
                </th>
                <th>Img</th>
                <th>Date</th>
                <th>Resolution</th>
                <th>Type</th>
                <th>Size</th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {Array.from(data).map(
                (file, idx) => (
                  <>
                    <tr
                      key={idx}
                    >
                      <td>
                        <div className='rmv-bg-history-table-item'>
                          <input
                            type="checkbox"
                            onChange={() => toggleCheckbox(idx)}
                            checked={selectedRows.includes(idx)}
                          />
                        </div>
                        
                      </td>
                      <td>
                        <div className='rmv-bg-history-table-item'>
                          <div className='rmv-bg-history-table-img-wrapper'>
                            <div className='rmv-bg-history-table-img'>
                              <img src={`https://www.dandycv.com/media/${file.image_after_name}`} alt='' />
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>{file.date.slice(0, 19).replace('T', ' ')}</td>
                      <td>{file.imageResolution}</td>
                      <td>{file.imageCategory}</td>
                      <td>{shortenBytes(file.imageSize)}</td>
                      <td>{file.imageName.length > 30 ? file.imageName.substring(0, 30) + '...' : file.imageName}</td>
                    </tr>
                    <div className='rmv-bg-history-table-hr' />
                  </>
                )
              )}
            </tbody>
          </table>
        ) : (
          <div>
            {Array.from(data).map((file, idx) => (
              <div>
                <div className='rmv-bg-history-table-mobile-header'>
                  <div>{file.date.slice(0, 19).replace('T', ' ')}</div>
                  <div className='rmv-bg-history-table-mobile-header-hr'></div>
                </div>

                <div className='rmv-bg-history-table-mobile-body'>

                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <div className='rmv-bg-history-table-item'>
                      <div className='rmv-bg-history-table-img-wrapper'>
                        <div className='rmv-bg-history-table-img'>
                          <img src={`https://www.dandycv.com/media/${file.image_after_name}`} alt='' />
                        </div>
                      </div>
                    </div>

                    <div className='rmv-bg-history-table-mobile-body-name'>
                      <div>
                        {file.imageResolution}
                      </div>
                      <div style={{marginTop: '10px'}}>
                        {shortenBytes(file.imageSize)}
                      </div>
                      <div style={{marginTop: '10px'}}>
                        {file.imageName.length > 30 ? file.imageName.substring(0, 20) + '...' : file.imageName}.{file.imageCategory}
                      </div>
                    </div>
                  </div>

                  

                  <input
                    type="checkbox"
                    style={{marginLeft: '38px'}}
                    onChange={() => toggleCheckbox(idx)}
                    checked={selectedRows.includes(idx)}
                  />
                </div>
              </div>
            ))}
          </div>
        )}

        <div className='rmv-bg-history-table-paginator'>
          {'<'}
          {/* set some logic */}
          {totalPaginatorNumArray.map((number) => (
            number === '...' ? <div className='rmv-bg-history-table-paginator-item' style={{cursor: 'default'}}>
              {number}
            </div>
            :
            <div
              className='rmv-bg-history-table-paginator-item'
              style={{color: paginatorValue === number && '#000'}}
              onClick={() => setPaginatorValue(number)}
            >
              {number}
            </div>
          ))}
          {'>'}
        </div>
      </div>

      <RemoveBackgroundDeletePopup trigger={deleterPopup} setTrigger={setDeleterPopup}
        data={data} setData={setData}
        selectedRows={selectedRows} setSelectedRows={setSelectedRows} setSelectAll={setSelectAll}

      />

    </div>
  )
}

export default RemoveBackgroundHistoryComponent