import axios from 'axios'
import { USER_LOGOUT } from '../constants/userConstants'

import {
    ADMIN_GENERATE_IMAGE_REQUEST,
    ADMIN_GENERATE_IMAGE_SUCCESS,
    ADMIN_GENERATE_IMAGE_FAIL,

    ADMIN_POST_UPLOAD_REQUEST,
    ADMIN_POST_UPLOAD_SUCCESS,
    ADMIN_POST_UPLOAD_FAIL,

    ADMIN_POST_EDIT_REQUEST,
    ADMIN_POST_EDIT_SUCCESS,
    ADMIN_POST_EDIT_FAIL,

    ADMIN_GET_ALL_POST_REQUEST,
    ADMIN_GET_ALL_POST_SUCCESS,
    ADMIN_GET_ALL_POST_FAIL
} from '../constants/adminConstants'

export const getAllPosts = (userToken) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADMIN_GET_ALL_POST_REQUEST
        })

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userToken}`
            }
        }


        const { data } = await axios.get(
            `admin_generations/api/posts/`,
            config
        )

        dispatch({
            type: ADMIN_GET_ALL_POST_SUCCESS,
            payload: data
        })
    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('userInfo')
            dispatch({type: USER_LOGOUT})
        } else {
            dispatch({
                type: ADMIN_GET_ALL_POST_FAIL,
                payload: error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
            })
        }
    }
}

export const generateAdminImages = (
    username,
    turboSelected,
    prompt,
    negativePrompt,
    selectedStyle,
    imageCount,
    aspectRatioWidth,
    aspectRatioHeight,
    iterationCount,
    CFGScaleGenerator,
    CFGScaleDecoder
) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADMIN_GENERATE_IMAGE_REQUEST
        })

        const {
            userLogin: { userInfo },
        } = getState()

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        var currentdate = new Date();

        const formData = new FormData();
        formData.append('username', username);
        if (turboSelected) {
            formData.append('turboSelected', turboSelected)
        }
        formData.append('prompt', prompt);
        formData.append('negative_prompt', negativePrompt);
        formData.append('style', selectedStyle);
        formData.append('ratio', '1:1');
        formData.append('date', currentdate / 1000);
        formData.append('num_images_per_prompt', imageCount);
        formData.append('image_width', aspectRatioWidth);
        formData.append('image_height', aspectRatioHeight);
        formData.append('num_inference_steps', iterationCount);
        formData.append('cfg_generator', CFGScaleGenerator)
        formData.append('cfg_decoder', CFGScaleDecoder)

        const { data } = await axios.post(
            `admin_generations/api/generate_post_images/`,
            formData,
            config
        )

        dispatch({
            type: ADMIN_GENERATE_IMAGE_SUCCESS,
            payload: data
        })

    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('userInfo')
            dispatch({type: USER_LOGOUT})
        } else {
            dispatch({
                type: ADMIN_GENERATE_IMAGE_FAIL,
                payload: error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
            })
        }
    }
}

export const uploadAdminPost = (
    userToken,
    thumbnailImgDataEN,
    thumbnailImgDataKR,
    avatarImgData,
    saveTitleEN,
    saveTitleKR,
    categorySave_en,
    categorySave_kr,
    saveSubheadingEN,
    saveSubheadingKR,
    saveSummaryEN,
    saveSummaryKR,
    saveBackgroundEN,
    saveBackgroundKR,
    uploadVideoFiles,
    uploadVideoThumbnailFiles,
    uploadedFiles,
    saveYoutubeURL,
    saveInstagramURL,
    saveTiktokURL,
    saveNaverBlogURL
) => async (dispatch, getState) => {
    try {
        dispatch({
            type: ADMIN_POST_UPLOAD_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data; charset=utf-8',
                Authorization: `Bearer ${userToken}`
            }
        }

        const formData = new FormData();

        for (let i=0; i<uploadVideoFiles.length; i++) {
            formData.append('video_file' + i, uploadVideoFiles[i])
            formData.append('video_thumbnail_file' + i, uploadVideoThumbnailFiles[i])
        } 

        for (let i=0; i<uploadedFiles.length; i++) {
            formData.append('image_file' + i, uploadedFiles[i])
        } 

        formData.append('thumbnail_en', thumbnailImgDataEN);
        formData.append('thumbnail_kr', thumbnailImgDataKR);
        formData.append('profile_image', avatarImgData);
        formData.append('title_en', saveTitleEN);
        formData.append('title_kr', saveTitleKR);
        formData.append('category_en', categorySave_en);
        formData.append('category_kr', categorySave_kr);
        formData.append('subheading_en', saveSubheadingEN);
        formData.append('subheading_kr', saveSubheadingKR);
        formData.append('summary_en', saveSummaryEN);
        formData.append('summary_kr', saveSummaryKR);
        formData.append('background_en', saveBackgroundEN);
        formData.append('background_kr', saveBackgroundKR);


        formData.append('youtube_url', saveYoutubeURL);
        formData.append('instagram_url', saveInstagramURL);
        formData.append('tiktok_url', saveTiktokURL);
        formData.append('naver_blog_url', saveNaverBlogURL);

        const { data } = await axios.post(
            `admin_generations/api/posts/upload/`,
            formData,
            config
        )

        dispatch({
            type: ADMIN_POST_UPLOAD_SUCCESS,
            payload: data
        })

    } catch (error) {
        if (error.response && error.response.status === 401) {
            localStorage.removeItem('userInfo')
            dispatch({type: USER_LOGOUT})
        } else {
            dispatch({
                type: ADMIN_POST_UPLOAD_FAIL,
                payload: error.response && error.response.data.detail
                    ? error.response.data.detail
                    : error.message,
            })
        }
    }
}

export const editAdminPost = (
    userToken,
    idx,
    uploadVideoFiles,
    uploadVideoThumbnailFiles,
    uploadedFiles,
    uploadedFilesOrder,
    uploadedFilesStartVerse,
    uploadedFilesEndVerse,
    thumbnailImgDataEN,
    thumbnailImgDataKR,
    avatarImgData,
    title_en,
    title_kr,
    category_en,
    category_kr,
    subheading_en,
    subheading_kr,
    summary_en,
    summary_kr,
    background_en,
    background_kr,
    youtube_url,
    instagram_url,
    tiktok_url,
    naver_blog_url,
) => async (dispatch) => {
    try {
        dispatch({
            type: ADMIN_POST_EDIT_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data; charset=utf-8',
                Authorization: `Bearer ${userToken}`
            }
        }

        const formData = new FormData();

        for (let i=0; i<uploadVideoFiles.length; i++) {
            formData.append('video_file' + i, uploadVideoFiles[i])
            formData.append('video_thumbnail_file' + i, uploadVideoThumbnailFiles[i])
        } 

        for (let i=0; i<uploadedFiles.length; i++) {
            formData.append('image_file' + i, uploadedFiles[i])
            formData.append('image_file_order' + i, uploadedFilesOrder[i])
            formData.append('image_file_start_verse' + i, uploadedFilesStartVerse[i])
            formData.append('image_file_end_verse' + i, uploadedFilesEndVerse[i])
        }

        if (thumbnailImgDataKR) {
            formData.append('thumbnail_kr', thumbnailImgDataKR);
        }

        if (thumbnailImgDataEN) {
            formData.append('thumbnail_en', thumbnailImgDataEN);
        }

        if (avatarImgData) {
            formData.append('profile_image', avatarImgData);
        }

        formData.append('title_en', title_en);
        formData.append('title_kr', title_kr);
        formData.append('category_en', category_en);
        formData.append('category_kr', category_kr);
        formData.append('subheading_en', subheading_en);
        formData.append('subheading_kr', subheading_kr);
        formData.append('summary_en', summary_en);
        formData.append('summary_kr', summary_kr);
        formData.append('background_en', background_en);
        formData.append('background_kr', background_kr);

        formData.append('youtube_url', youtube_url);
        formData.append('instagram_url', instagram_url);
        formData.append('tiktok_url', tiktok_url);
        formData.append('naver_blog_url', naver_blog_url);

        const { data } = await axios.post(
            `admin_generations/api/posts/update/${idx}`,
            formData,
            config
        )

        dispatch({
            type: ADMIN_POST_EDIT_SUCCESS,
        })

    } catch (error) {
        dispatch({
            type: ADMIN_POST_EDIT_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}
