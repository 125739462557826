import axios from 'axios'

import {
    MULTI_PROMPTING_GET_REQUEST,
    MULTI_PROMPTING_GET_SUCCESS,
    MULTI_PROMPTING_GET_FAIL,

    MULTI_PROMPTING_POST_REQUEST,
    MULTI_PROMPTING_POST_SUCCESS,
    MULTI_PROMPTING_POST_FAIL,

    MULTI_PROMPTING_DELETE_REQUEST,
    MULTI_PROMPTING_DELETE_SUCCESS,
    MULTI_PROMPTING_DELETE_FAIL,
} from '../constants/multiPromptingConstants'

export const multiPromptingHistory = (userToken, paginatorValue, itemsPerPage=10) => async (dispatch) => {
    try {
        dispatch({
            type: MULTI_PROMPTING_GET_REQUEST
        })

        const formData = new FormData();
        formData.append('itemsPerPage', itemsPerPage);
        formData.append('pageNumber', paginatorValue);

        const { data } = await axios.post(
            `multi_prompting/api/history/`,
            formData,
            {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${userToken}`
                }
            }
        )

        dispatch({
            type: MULTI_PROMPTING_GET_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: MULTI_PROMPTING_GET_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const multiPromptingGeneration = (
    userToken,
    prompts,
    weights,
    style,
    ratio,
    imageCount,
    image_width,
    image_height,
    num_inference_steps,
    cfg_scale,
    paginatorValue,
    itemsPerPage
) => async (dispatch) => {
    try {
        dispatch({
            type: MULTI_PROMPTING_POST_REQUEST
        })

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data; charset=utf-8',
                Authorization: `Bearer ${userToken}`
            }
        }

        var currentdate = new Date();

        const formData = new FormData();

        for (let i=0; i<prompts.length; i++) {
            formData.append('prompt' + i, prompts[i])
            formData.append('prompt_weight' + i, weights[i])
        }

        formData.append('style', style);
        formData.append('ratio', ratio);
        formData.append('date', currentdate / 1000);
        formData.append('num_images_per_prompt', imageCount);
        formData.append('image_width', image_width);
        formData.append('image_height', image_height);
        formData.append('num_inference_steps', num_inference_steps);
        formData.append('model_type', 'Stable Diffusion XL 1.0');
        formData.append('cfg_scale', cfg_scale)


        const { data } = await axios.post(
            `multi_prompting/api/generate/`,
            formData,
            config
        )

        dispatch({
            type: MULTI_PROMPTING_POST_SUCCESS
        })

        dispatch(multiPromptingHistory(userToken, paginatorValue, itemsPerPage))

    } catch (error) {
        dispatch({
            type: MULTI_PROMPTING_POST_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}

export const multiPromptingDelete = (userToken, api_request, image_id, paginatorValue, itemsPerPage) => async (dispatch) => {
    try {
        dispatch({
            type: MULTI_PROMPTING_DELETE_REQUEST
        })

        const { data } = await axios.delete(
            api_request,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${userToken}`
                },
                data: {
                    'image_id': image_id,
                }
            }
        )

        dispatch({
            type: MULTI_PROMPTING_DELETE_SUCCESS
        })

        dispatch(multiPromptingHistory(userToken, paginatorValue, itemsPerPage))

    } catch (error) {
        dispatch({
            type: MULTI_PROMPTING_DELETE_FAIL,
            payload: error.response && error.response.data.detail
                ? error.response.data.detail
                : error.message,
        })
    }
}