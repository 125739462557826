import {
    UPSCALING_GET_REQUEST,
    UPSCALING_GET_SUCCESS,
    UPSCALING_GET_FAIL,

    UPSCALING_POST_REQUEST,
    UPSCALING_POST_SUCCESS,
    UPSCALING_POST_FAIL,

    UPSCALING_DELETE_REQUEST,
    UPSCALING_DELETE_SUCCESS,
    UPSCALING_DELETE_FAIL,
} from '../constants/upscalingConstants'

export const getUpscalingDataReducers = (state = { upscalingData: [] }, action) => {
    switch (action.type) {
        case UPSCALING_GET_REQUEST:
            return { ...state, loading: true }

        case UPSCALING_GET_SUCCESS:
            return { ...state, loading: false, upscalingData: action.payload.data }

        case UPSCALING_GET_FAIL:
            return { ...state, loading: false, error: action.payload }

        case UPSCALING_POST_REQUEST:
            return { ...state, loading: true }

        case UPSCALING_POST_SUCCESS:
            return { ...state, loading: false }

        case UPSCALING_POST_FAIL:
            return { ...state, loading: false, error: action.payload }

        case UPSCALING_DELETE_REQUEST:
            return { ...state, loading: true}

        case UPSCALING_DELETE_SUCCESS:
            return { ...state, loading: false }

        case UPSCALING_DELETE_FAIL:
            return { ...state, loading: false, error: action.payload }

        default:
            return state
    }
}