import React, { useEffect } from 'react'

import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../actions/userActions';

import profileImg from '../images/profile.svg'

function AccountPopup(props) {

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userDetail = useSelector(state => state.userSubscriptionDetail)
    const { userSubscriptionDetail } = userDetail

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const logoutHandler = () => {
        navigate('/')
        dispatch(logout())
    }

    useEffect(() => {
        if (!userInfo) {
            navigate('/login')
        }
    }, [userInfo, navigate]);

    return (props.trigger) ? (
        <div className='account-popup'>

            <div className='account-popup-info'>
                <img src={(userSubscriptionDetail && userInfo && userSubscriptionDetail.user_data.avatar)? `https://www.dandycv.com/media/${userSubscriptionDetail.user_data.avatar}` : profileImg} alt=''/>
                <div className='account-popup-info-username'>{userInfo.username}</div>
                <div className='account-popup-info-email'>{userInfo.email}</div>


                <NavLink to={'/my-page/account-settings'} onClick={() => props.setTrigger(false)}>
                    <div className='account-popup-info-account-btn'>
                        <div className='account-popup-info-account-btn-div1'>My Account</div>
                        <div className='account-popup-info-account-btn-div2'>Edit account profile</div>
                    </div>
                </NavLink>

                
                <div className='account-popup-info-upgrade-btn' onClick={() => {
                    navigate('/my-page/account-settings?app=subscription')
                    props.setTrigger(false)
                }}>
                    Purchase Credits
                </div>

                <div className='account-popup-info-hr'></div>

            </div>

            <div className='account-popup-logout-btn' onClick={logoutHandler}>Logout</div>
        </div>
    ) : "";
}

export default AccountPopup
