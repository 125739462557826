import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getAllPosts } from '../../actions/adminActions'
import { NavLink, useNavigate } from 'react-router-dom';

import logoSmall from '../../images/logo-small.jpg'
import closeBtnImg from '../../images/close-btn.svg'

function MainPostsAdminScreen() {


    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const allData = useSelector(state => state.adminAllPostData);
    const { loading, error, allAdminPostData: data } = allData

    useEffect(() => {
        if (userInfo && userInfo.isAdmin) {
            dispatch(getAllPosts(userInfo.token))
        } else {
            navigate('/')
        }
    }, [dispatch])

    return (
        <div className='admin-post'>

            <div className='gnrt-img-navbar'>
                <img src={logoSmall} alt='logo' />
                <NavLink className='gnrt-img-navbar-title' to={'/my-page'} style={{marginLeft: '10px'}}>
                    Admin Page
                </NavLink>
                <div className='gnrt-img-navbar-topic' style={{marginLeft: '4px', marginRight: '4px'}}>
                    /
                </div>
                <div className='gnrt-img-navbar-topic' >
                    Posts
                </div>

                <div className='gnrt-img-navbar-close'>
                    <img src={closeBtnImg} alt='' onClick={() => navigate('/my-page')}/>
                </div>
            </div>

            <div style={{padding: '10px'}}>
                <button onClick={() => navigate('/admin-posts/add')} style={{
                    marginBottom: '20px',
                    marginTop: '20px',
                    border: '1px solid #B4307D',
                    borderRadius: '5px',
                    width: '100px',
                    height: '25px',
                    color: '#B4307D',
                    background: '#FFF',
                    cursor: 'pointer'
                }}>Add Post</button>
                {loading ? (
                    <div>Loading..</div>
                ) : (
                    error ? (
                        <div>Error</div>
                    ) : (
                        data && (
                            <table>
                                <thead>
                                    <tr>
                                        <th>id</th>
                                        <th>Post</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((row, idx) => (
                                        <tr key={idx} className='table-row' onClick={() => navigate(`/admin-posts/edit/${row.id}`)}>
                                            <td>
                                                {row.id}
                                            </td>
                                            <td>
                                                {row.title_en}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>

                            

                            </table>
                        )
                    )
                )}
            </div>
            
        </div>
    )
}

export default MainPostsAdminScreen
