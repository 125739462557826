import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie'

import PostsImageMobileFileViewer from '../components/PostsImageMobileFileViewer'
import CopyPopup from '../components/copyPopup'

import logoSmall from '../images/logo-small.jpg'
import closeBtnImg from '../images/close-btn.svg'
import arrowImg from '../images/full-arrow.svg'
import lockImg from '../images/posts/lock.png'
// import downloadImg from '../images/posts/download.png'
import postShareImg from '../images/icons/posts-share.svg'
import postYoutubeImg from '../images/icons/post-youtube.svg'
import postInstagramImg from '../images/icons/post-instagram.svg'
import postTiktokImg from '../images/icons/post-tiktok.svg'
import postBlogImg from '../images/icons/post-blog.svg'

function PostImageViewPopup(props) {
    const currentLanguageCode = Cookies.get('i18next') || 'en';

    const getPostData = useSelector(state => state.getPostData)
    const { postData } = getPostData

    const navigate = useNavigate();

    const handleFileClick = (idx) => {
        props.setSelectedFileId(idx)
        if (props.mobileState) {
            props.setMobileFileViewer(true)
        }
    }

    const [copyPopup, setCopyPopup] = useState(false)
    
    const handleCopyLinkClick = () => {
        navigator.clipboard.writeText(`https://www.dandycv.com/#/posts/${postData.data[props.selectedPostId].id}`)
        setCopyPopup(true)

        setTimeout(() => {
            setCopyPopup(false)
        }, 1000)
    }

    const handleFileSwitchClick = (val) => {
        if (postData.data[props.selectedPostId].video_file_name_list.length + postData.data[props.selectedPostId].image_file_name_list.length > 0) {
            const counter = postData.data[props.selectedPostId].video_file_name_list.length + postData.data[props.selectedPostId].image_file_name_list.length  - 1

            if (counter < props.selectedFileId + val) {
                props.setSelectedFileId(0)
            } else if (props.selectedFileId + val < 0) {
                props.setSelectedFileId(counter)
            } else {
                props.setSelectedFileId(props.selectedFileId + val)
            }
        }
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
          if (props.trigger) {
            if (event.keyCode === 37) {
                handleFileSwitchClick(-1)
            } else if (event.keyCode === 39) {
                handleFileSwitchClick(1)
            }
          }
        };

        document.addEventListener('keydown', handleKeyDown);
    
        // Detach the event listener when the component unmounts
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
    }, [props.trigger, props.selectedFileId]);

    const handlePostSwitchClick = (val) => {
        props.setSelectedFileId(0)
        if (props.selectedPostId + val > postData.data.length - 1) {
            props.setSelectedPostId(0)
        } else if (props.selectedPostId + val < 0) {
            props.setSelectedPostId(postData.data.length - 1)
        } else {
            props.setSelectedPostId(props.selectedPostId + val)
        }
    }

    return props.trigger ? (
        <div className='post-image-viewer-overlay' onClick={() => props.setTrigger(false)}>
            <div className='post-image-viewer-content' onClick={(e) => e.stopPropagation()}>
                <div className='post-image-viewer-content-navbar'>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {
                            props.mobileState && (
                                <>
                                    <img src={logoSmall} alt='logo' style={{width: '24px', height: '24px'}}/>
                                    <NavLink className='post-image-viewer-content-navbar-title' to={'/'} style={{marginLeft: '10px'}}>
                                        dandycv
                                    </NavLink>
                                </>
                            )
                        }
                    </div>

                    <img src={closeBtnImg} alt='' style={{cursor: 'pointer'}} onClick={() => props.setTrigger(false)}/>
                </div>

                <div
                    className='hr'
                    style={
                        {
                            marginTop: '15px',
                            marginLeft: !props.mobileState && '20px',
                            marginRight: !props.mobileState && '20px'
                        }
                    }
                />

                <div className='post-image-view-content-helper'>
                    <div className='post-image-view-content-left'>
                        <div className='post-image-view-content-left-scroll'>
                            <div className='post-image-view-content-left-title'>
                                {currentLanguageCode === 'en' ? postData.data[props.selectedPostId].title_en : postData.data[props.selectedPostId].title_kr}
                            </div>

                            <div className='post-image-view-content-left-avatar'>
                                <img className='post-image-view-content-left-avatar-img' src={`https://www.dandycv.com${postData.data[props.selectedPostId].profile_image}`} alt='' />

                                <div className='post-image-view-content-left-avatar-info'>
                                    <div className='post-image-view-content-left-avatar-info-content'>
                                        <div style={{marginRight: '20px'}}>
                                            <span>
                                                {postData.data[props.selectedPostId].video_file_name_list && postData.data[props.selectedPostId].video_file_name_list.length}
                                            </span> Videos
                                        </div>
                                        <div style={{marginRight: '30px'}}>
                                            <span>
                                                {postData.data[props.selectedPostId].image_file_name_list && postData.data[props.selectedPostId].image_file_name_list.length}
                                            </span> Images
                                        </div>

                                        <div style={{position: 'relative'}}>
                                            <img src={postShareImg} alt='' style={{cursor: 'pointer'}} onClick={() => handleCopyLinkClick()}/>
                                            <CopyPopup trigger={copyPopup}/>
                                        </div>
                                        
                                    </div>
                                    <div className='post-image-view-content-left-avatar-info-social'>
                                        <img src={postYoutubeImg} alt='' style={{cursor: 'pointer'}} onClick={() => postData.data[props.selectedPostId].youtube_url && window.open(postData.data[props.selectedPostId].youtube_url, '_blank', 'noreferrer')}/>
                                        <img src={postInstagramImg} alt='' style={{cursor: 'pointer'}} onClick={() => postData.data[props.selectedPostId].instagram_url && window.open(postData.data[props.selectedPostId].instagram_url, '_blank', 'noreferrer')}/>
                                        <img src={postTiktokImg} alt='' style={{cursor: 'pointer'}} onClick={() => postData.data[props.selectedPostId].tiktok_url && window.open(postData.data[props.selectedPostId].tiktok_url, '_blank', 'noreferrer')}/>
                                        <img src={postBlogImg} alt='' style={{cursor: 'pointer'}} onClick={() => postData.data[props.selectedPostId].naver_blog_url && window.open(postData.data[props.selectedPostId].naver_blog_url, '_blank', 'noreferrer')}/>
                                    </div>
                                </div>
                            </div>

                            <div className='post-image-view-content-left-username'>
                                @ai.dandycv
                            </div>

                            <div className='post-image-view-content-left-switch-page-btn'>
                                <div
                                    className='post-image-view-content-left-switch-page-btn-div'
                                    style={
                                        {
                                            borderBottomColor: props.selectedPostViewContentPage && '#B4307D',
                                            color: props.selectedPostViewContentPage && '#B4307D',
                                            fontWeight: props.selectedPostViewContentPage && '700',
                                        }
                                    }
                                    onClick={() => props.setSelectedPostViewContentPage(true)}
                                >
                                    Content
                                </div>
                                <div
                                    className='post-image-view-content-left-switch-page-btn-div'
                                    style={
                                        {
                                            borderBottomColor: !props.selectedPostViewContentPage && '#B4307D',
                                            color: !props.selectedPostViewContentPage && '#B4307D',
                                            fontWeight: !props.selectedPostViewContentPage && '700',
                                        }
                                    }
                                    onClick={() => props.setSelectedPostViewContentPage(false)}
                                >
                                    Video & Image
                                </div>
                            </div>

                            {
                                props.selectedPostViewContentPage ? (
                                    <>
                                        <div className='post-image-view-content-left-summary'>
                                            {currentLanguageCode === 'en' ? postData.data[props.selectedPostId].subheading_en : postData.data[props.selectedPostId].subheading_kr}
                                        </div>

                                        <div className='hr' style={{marginTop: '15px', marginLeft: '20px', marginRight: '20px'}}></div>

                                        <div className='post-image-view-content-left-summary'>
                                            <div>
                                                {currentLanguageCode === 'en' ? postData.data[props.selectedPostId].summary_en : postData.data[props.selectedPostId].summary_kr}
                                            </div>
                                        </div>

                                        <div className='hr' style={{marginTop: '15px', marginLeft: '20px', marginRight: '20px'}}></div>

                                        <div className='post-image-view-content-left-summary'>
                                            <div>
                                                {currentLanguageCode === 'en' ? postData.data[props.selectedPostId].background_en : postData.data[props.selectedPostId].background_kr}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className='post-image-view-content-left-image-display'>
                                        {postData.data[props.selectedPostId].video_file_name_list.map((file, idx) => (
                                            <div
                                                className='post-image-view-content-left-image-display-div'
                                                style={{borderColor: props.selectedFileId === idx && !props.mobileState && '#B4307D'}}
                                                onClick={() => handleFileClick(idx)}
                                            >
                                                <img key={idx} src={`https://www.dandycv.com/media/${file.thumbnail}`} alt='' style={{pointerEvents: 'none'}}/>
                                            </div>
                                        ))}
                                        {postData.data[props.selectedPostId].image_file_name_list.map((file, idx) => (
                                            <div
                                                className='post-image-view-content-left-image-display-div'
                                                style={{borderColor: props.selectedFileId === idx + postData.data[props.selectedPostId].video_file_name_list.length && !props.mobileState && '#B4307D'}}
                                                onClick={() => handleFileClick(idx + postData.data[props.selectedPostId].video_file_name_list.length)}
                                            >
                                                <img key={idx} src={`https://www.dandycv.com/media/${file.file_name}`} alt='' style={{pointerEvents: 'none'}}/>
                                            </div>
                                            
                                        ))}
                                    </div>
                                )
                            }

                        </div>

                        <div className='post-image-view-content-left-switch-post-btn'>
                            <button style={{marginRight: '4px'}} onClick={() => handlePostSwitchClick(-1)}>이전</button>
                            <button onClick={() => handlePostSwitchClick(1)}>다음</button>
                        </div>
                    </div>

                    {!props.mobileState && (
                        <div className='post-image-view-content-right'>
                            <div className='popup-generate-image-view-content-right-wrapper'>
                                {
                                    postData.data[props.selectedPostId].video_file_name_list && (
                                        props.selectedFileId < postData.data[props.selectedPostId].video_file_name_list.length ? (
                                            <React.Fragment key={postData.data[props.selectedPostId].video_file_name_list[props.selectedFileId].file_name}>
                                                <video controls autoPlay>
                                                    <source src={`https://www.dandycv.com/media/${postData.data[props.selectedPostId].video_file_name_list[props.selectedFileId].file_name}`} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </React.Fragment>
                                        ) : (
                                            postData.data[props.selectedPostId].image_file_name_list.length > 0 && (<img
                                                src={`https://www.dandycv.com/media/${postData.data[props.selectedPostId].image_file_name_list[props.selectedFileId - postData.data[props.selectedPostId].video_file_name_list.length].file_name}`}
                                                alt=''
                                                style={{pointerEvents: 'none'}}
                                            />)
                                        )
                                    )
                                }
                            </div>

                            <div className='popup-generate-image-view-content-right-slide-container' style={{left: '0', transform: 'rotate(180deg)'}} onClick={() => handleFileSwitchClick(-1)}>
                                <img src={arrowImg} alt='' />
                            </div>

                            <div className='popup-generate-image-view-content-right-slide-container' style={{right: '0'}} onClick={() => handleFileSwitchClick(1)}>
                                <img src={arrowImg} alt='' />
                            </div>

                            <div style={{position: 'absolute', bottom: '0', margin: '14px', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                <div style={{color: '#FFF', marginBottom: '10px'}}>
                                    {props.selectedFileId >= postData.data[props.selectedPostId].video_file_name_list.length && (
                                        postData.data[props.selectedPostId].image_file_name_list.length > 0 && (
                                            <>
                                                {postData.data[props.selectedPostId].image_file_name_list[props.selectedFileId - postData.data[props.selectedPostId].video_file_name_list.length].start_verse}~{postData.data[props.selectedPostId].image_file_name_list[props.selectedFileId - postData.data[props.selectedPostId].video_file_name_list.length].end_verse}
                                            </>
                                        )
                                    )}
                                </div>
                                <img src={lockImg} alt='' style={{width: '32px', height: '32px'}}/>
                            </div>

                        </div>
                    )}
                </div>

            </div>

            <PostsImageMobileFileViewer viewerTrigger={props.mobileFileViewer && props.mobileState} setViewerTrigger={props.setMobileFileViewer}
                selectedFileId={props.selectedFileId} selectedPostId={props.selectedPostId}
                normalState={true}
            />
        </div>
    ) : ('')
}

export default PostImageViewPopup
