export const UPSCALING_GET_REQUEST = 'UPSCALING_GET_REQUEST'
export const UPSCALING_GET_SUCCESS = 'UPSCALING_GET_SUCCESS'
export const UPSCALING_GET_FAIL = 'UPSCALING_GET_FAIL'

export const UPSCALING_POST_REQUEST = 'UPSCALING_POST_REQUEST'
export const UPSCALING_POST_SUCCESS = 'UPSCALING_POST_SUCCESS'
export const UPSCALING_POST_FAIL = 'UPSCALING_POST_FAIL'

export const UPSCALING_DELETE_REQUEST = 'UPSCALING_DELETE_REQUEST'
export const UPSCALING_DELETE_SUCCESS = 'UPSCALING_DELETE_SUCCESS'
export const UPSCALING_DELETE_FAIL = 'UPSCALING_DELETE_FAIL'