import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { NavLink, useNavigate } from 'react-router-dom';
import { logout, getUserSubscriptionDetail } from '../actions/userActions'

import Dropdown from '../components/Dropdown'

import profileImg from '../images/home-profile.svg'
import logo from '../images/dandycv-logo-w.png'
import logoPosts from '../images/dandycv-logo.png'

import { useDispatch, useSelector } from 'react-redux';

function HomeNavbar(props) {
    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const [isActive, setIsActive] = useState(false)

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const logoutHandler = () => {
        dispatch(logout())
    }

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin;

    const userDetail = useSelector(state => state.userSubscriptionDetail)
    const { userSubscriptionDetail } = userDetail

    useEffect(() => {
        if (userInfo) {
            dispatch(getUserSubscriptionDetail(userInfo.token))
        }
    }, [userInfo, dispatch]);

    const productDropdownItems = [
        {
            name: parse(t('navbar_products_drop_1')),
            navigate: '/posts',
        },
        {
            name: parse(t('navbar_products_drop_2')),
            navigate: '/my-page',
        },
        {
            name: parse(t('navbar_products_drop_3')),
            navigate: '/',
        },

    ]

    const styles = {
        dropdown: {
            width: '178px',
            height: '137px',
            background: 'rgba(0, 0, 0, 0.5)',
            position: 'absolute',
            left: '-56px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: '10px',
            borderRadius: '5px'
        }
    };

    return (
        <div className={props.posts ? 'posts-navbar' : 'home-navbar'}>

            <img src={props.posts ? logoPosts : logo} alt='logo' style={{width: '150px', height: '33.69px'}} onClick={() => props.posts ? navigate('/') : window.location.reload(false)}/>

            <div className={props.posts ? 'posts-navbar-left-web' : 'home-navbar-left-web'}>

                <NavLink to='/'>
                    {parse(t('navbar_home'))}
                </NavLink>

                <div style={{position: 'relative', marginRight: '78px'}}>
                    <div className="dropdown-btn" style={{cursor: 'pointer'}} onClick={(e) => setIsActive(!isActive)}>
                        {parse(t('navbar_products'))}
                    </div>
                    
                    {isActive && (
                        <div style={styles.dropdown}>
                            {productDropdownItems.map((item, idx) => (
                                <div className='product-dropdown-item' onClick={() => navigate(item.navigate)}>
                                    {item.name}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                {userSubscriptionDetail && userInfo &&
                    <div style={{marginRight: '10px', cursor: 'pointer'}} onClick={() => navigate('/my-page/account-settings?app=subscription')}>
                        {userSubscriptionDetail.user_data.subscriptionDetail.credits}&copy;
                    </div>
                }

                <img style={{width: '36px', height: '36px', borderRadius: '100px', marginRight: '30px'}} src={(userSubscriptionDetail && userInfo && userSubscriptionDetail.user_data.avatar)? `https://www.dandycv.com/media/${userSubscriptionDetail.user_data.avatar}` : profileImg} alt='login_img' onClick={() => navigate('/my-page/account-settings')}/>

                {userInfo ? 
                    <div style={{display: 'flex', marginRight: '24px'}}>
                        <div className='home-login-btn' onClick={logoutHandler} style={{borderColor: props.posts && '#000', color: props.posts && '#000'}}>
                            {parse(t('navbar_logout'))}
                        </div>
                    </div>
                    :
                    <div style={{marginRight: '24px'}}>
                        <NavLink to='/login' style={{marginRight: '0'}}>
                            <div className='home-login-btn' style={{borderColor: props.posts && '#000', color: props.posts && '#000'}}>
                                {parse(t('navbar_login'))}
                            </div>
                        </NavLink>
                    </div>
                }

                
                <Dropdown posts={props.posts && true}/>
            </div>

            <div className='home-navbar-left-mobile'>
                <div>
                    <i className="fa-solid fa-bars" style={{color: props.posts ? '#000' : '#FFF', fontSize: '36px'}} onClick={() => setIsOpen(!isOpen)}></i>
                </div>

                {isOpen && 
                <div className='overlay-main-page' onClick={() => setIsOpen(!isOpen)}>
                    <div className='home-navbar-left-mobile-sidebar' onClick={(e) => e.stopPropagation()}>
                        <div className='home-navbar-left-mobile-sidebar-nav'>

                            <div style={{display: 'flex'}}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <img src={(userSubscriptionDetail && userInfo && userSubscriptionDetail.user_data.avatar)? `https://www.dandycv.com/media/${userSubscriptionDetail.user_data.avatar}` : profileImg} alt='login_img' style={{width: '50px', height: '50px', borderRadius: '100px'}} />
                                    <div style={{color: '#FFF'}}>
                                        {userSubscriptionDetail && userInfo &&
                                            <div style={{marginLeft: '20px', fontSize: '16px'}}>
                                                {userSubscriptionDetail.user_data.subscriptionDetail.credits}&copy;
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <i className="fa-solid fa-xmark" style={{color: '#FFF', fontSize: '36px'}} onClick={() => setIsOpen(!isOpen)}></i>
                        </div>

                        <NavLink to='/' className='home-navbar-left-mobile-sidebar-navlinks'>
                            <div>
                                {parse(t('navbar_home'))}
                            </div>

                            <i className="fa-solid fa-angle-right" style={{color: '#FFF', fontSize: '24px'}}></i>
                        </NavLink>

                        <div>
                            <div className="dropdown-btn-mobile" style={{cursor: 'pointer'}} onClick={(e) => setIsActive(!isActive)}>
                                <div>{parse(t('navbar_products'))}</div>
                                <i className="fa-solid fa-angle-right" style={{color: '#FFF', fontSize: '24px', transform: isActive && 'rotate(90deg)'}}></i>
                            </div>
                            {
                                isActive && (
                                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                                        {productDropdownItems.map((item, idx) => (
                                            <div className='product-dropdown-item' onClick={() => navigate(item.navigate)}>
                                                {item.name}
                                            </div>
                                        ))}
                                    </div>
                                )
                            }
                        </div>

                        <div className='home-navbar-left-mobile-sidebar-navlinks'>
                            <Dropdown />
                        </div>

                        <div className='home-navbar-left-mobile-sidebar-button'>
                            {userInfo ? <button onClick={logoutHandler} style={{cursor: 'pointer'}}>{parse(t('navbar_logout'))}</button> : 
                            <NavLink to='/login'>
                                <div className='home-navbar-left-mobile-sidebar-button-link'>
                                    {parse(t('navbar_login'))}
                                </div>
                            </NavLink>
                            }
                        </div>

                    </div>
                </div>
                }
            </div>
        </div>
    )
}

export default HomeNavbar