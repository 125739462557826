import React, { useState } from 'react'

import deleteImg from '../images/drag&drop_delete.svg'
import arrowImg from '../images/full-arrow.svg'
import downloadGenerateImg from '../images/generate_download.svg'
import deleteGenerateImg from '../images/generate_delete.svg'

function MobileImageViewer(props) {
    const [imageId, setImageId] = useState(0)

    const handleLeftArrowPress = () => {
        if (imageId > 0) {
            setImageId(imageId - 1)
        } else {
            setImageId(props.prompt.image_name_list.length - 1)
        }
    }

    const handleRightArrowPress = () => {
        if (imageId < props.prompt.image_name_list.length - 1) {
            setImageId(imageId + 1)
        } else {
            setImageId(0)
        }
    }

    const handleDownload = () => {
        fetch('https://www.dandycv.com/media/' + props.prompt.image_name_list[imageId].image_name)
        .then((response) => response.blob())
        .then((blob) => {
            // Create a Blob object and generate a URL for it
            const url = window.URL.createObjectURL(blob);

            // Create an anchor element for downloading
            const a = document.createElement('a');
            a.href = url;
            a.download = props.prompt.prompt + '.jpg'; // Specify the desired file name
            document.body.appendChild(a);

            // Trigger a click event on the anchor element to initiate the download
            a.click();

            // Remove the anchor element from the DOM
            document.body.removeChild(a);
        });
    }

    const handleDelete = (image_only=true) => {
        props.setSelectedImageId(imageId)
        props.setSelectedPromptId(props.prompt_idx)
        props.setDeleteTarget(image_only ? 1 : 0)
        props.setDeleterPopup(true)
    }

    return (
        <div className='gnrt-img-mobile-img-viewer'>
            <div className='gnrt-img-mobile-img-viewer-wrapper'>
                <div className='gnrt-img-mobile-img-viewer-wrapper-img'>
                    {props.prompt.image_name_list[imageId] ? (
                        <img
                            src={`https://www.dandycv.com/media/${props.prompt.image_name_list[imageId].image_name}`}
                            alt=''
                        />
                    ) : (
                        <>No image</>
                    )}

                    {props.prompt.image_name_list.length > 1 && 
                        <>
                            <div className='popup-generate-image-view-content-right-slide-container' style={{left: '0'}} onClick={handleLeftArrowPress}>
                                <img src={arrowImg} alt='' style={{transform: 'rotate(180deg)'}}/>
                            </div>

                            <div className='popup-generate-image-view-content-right-slide-container' style={{right: '0'}} onClick={handleRightArrowPress}>
                                <img src={arrowImg} alt='' />
                            </div>
                        </>
                    }
                    
                    

                    <div className='gnrt-img-main-display-header-img-container-dwn-rmv'>
                        <img src={downloadGenerateImg} alt='download' style={{marginRight: '30px', cursor: 'pointer'}} onClick={handleDownload} />
                        <img src={deleteGenerateImg} alt='delete' style={{cursor: 'pointer'}} onClick={() => handleDelete(true)}/>
                    </div>
                </div>

                {props.prompt.image_name_list.length > 1 && 
                    <div className='gnrt-img-mobile-img-viewer-wrapper-help'>
                        {
                            props.prompt.image_name_list.map((img_name, i) => (
                                <div className='register-progress-bar' style={{background: imageId === i ? '#999' : 'rgba(153, 153, 153, 0.30)'}}></div>
                            ))
                        }
                    </div>
                }
                <div className='gnrt-img-mobile-img-viewer-wrapper-data'>
                    {props.multiPrompting ? (
                        props.prompt.prompt.map((item, idx) => (
                            <>
                                <div className='popup-generate-image-view-content-left-title'>
                                    prompt {idx+1}
                                </div>
                                <div className='popup-generate-image-view-content-left-body'>
                                    {item.prompt}
                                </div>

                                <div className='popup-generate-image-view-content-left-title'>
                                    weight
                                </div>
                                <div className='popup-generate-image-view-content-left-body'>
                                    {item.weight}
                                </div>

                                <div className='hr'></div>
                            </>
                        ))
                    ) : (
                        <>
                            <div className='popup-generate-image-view-content-left-title'>
                                prompt
                            </div>
                            <div className='popup-generate-image-view-content-left-body'>
                                {props.prompt.prompt}
                            </div>
                            <div className='hr'></div>

                            <div className='popup-generate-image-view-content-left-title'>
                                Negative prompt
                            </div>
                            <div className='popup-generate-image-view-content-left-body'>
                                {props.prompt.negative_prompt ? props.prompt.negative_prompt : 'None'}
                            </div>
                            <div className='hr'></div>
                        </>
                    )}
                    

                    <div className='popup-generate-image-view-content-left-title'>
                        Style
                    </div>
                    <div className='popup-generate-image-view-content-left-body'>
                        {props.prompt.style ? props.prompt.style : 'No style'}
                    </div>
                    <div className='hr'></div>

                    <div style={{display: 'flex'}}>
                        {!props.img2img &&
                            <div>
                                <div className='popup-generate-image-view-content-left-title'>
                                    Ratio
                                </div>
                                <div className='popup-generate-image-view-content-left-body'>
                                    {props.prompt.ratio ? props.prompt.ratio : 'None'}
                                </div>
                            </div>
                        }

                        <div style={{marginLeft: !props.img2img && '20px'}}>
                            <div className='popup-generate-image-view-content-left-title'>
                                Size
                            </div>
                            <div className='popup-generate-image-view-content-left-body'>
                                {props.prompt.resolution ? props.prompt.resolution : 'None'}
                            </div>
                        </div>
                    </div>
                    <div className='hr'></div>

                    <div className='popup-generate-image-view-content-left-buttons'>
                        <img src={deleteImg} alt='' onClick={() => handleDelete(false)}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobileImageViewer