import { useState } from "react"
import { NavLink } from "react-router-dom"

export default function SidebarItem({item}) {
    const [open, setOpen] = useState(true)
    return (
        <>
            {(item.child && item.parent) &&
                <div className="hr" style={{marginTop: '15px', marginLeft: '13px', marginRight: '13px'}}></div>
            }

            {
                item.parent && (item.path ? 
                <NavLink to={item.path}>
                    <div className='sidebar-title'>
                        <div className='sidebar-title-img-wrapper'>
                            <img src={item.src} alt=''></img>
                        </div>
                        <div className='sidebar-title-parent'>
                            {item.parent}
                        </div>
                    </div>
                </NavLink>
                :
                <div className='sidebar-title' style={{cursor: 'default'}}>
                    <div className='sidebar-title-img-wrapper'>
                        <img src={item.src} alt=''></img>
                    </div>
                    <div className='sidebar-title-parent'>
                        {item.parent}
                    </div>
                </div>
                )
            }

            <div className={open ? 'sidebar-item open' : 'sidebar-item'}>
                {
                    item.children && 
                    <div className="sidebar-title" onClick={() => setOpen(!open)}>
                        <div className='sidebar-title-img-wrapper'>
                            <img src={item.src} alt='' className='toggle-btn'></img>
                        </div>
                        <div className='sidebar-title-subparent'>
                            {item.name}
                        </div>
                    </div>
                }

                {item.children && 
                    <div className='sidebar-content'>
                        {item.children.map((child, idx) =>
                            <NavLink key={idx} to={child.path} className='sidebar-content-item'>
                                {child.name}
                            </NavLink>
                        )}
                    </div>
                }
            </div>

            {item.child && 
                <div>
                    {item.child.map((child, idx) => 
                        <SidebarItem key={idx} item={child} />
                    )}
                </div>
            }
        </>
    )
} 