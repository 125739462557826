import React, { useEffect } from 'react'

import backBtn from '../images/icons/posts-back.svg'
import { useSelector } from 'react-redux'

import lockImg from '../images/posts/lock.png'

function PostsImageMobileFileViewer(props) {

    const getPostDataWithId = useSelector(state => state.getPostDataWithId)
    // const { postDataWithId } = getPostDataWithId

    const getPostData = useSelector(state => state.getPostData)
    // const { postData } = getPostData

    const postDataWithId = props.normalState ? getPostData.postData.data[props.selectedPostId] : getPostDataWithId.postDataWithId

    useEffect(() => {
        const fileElement = document.getElementById(`file-${props.selectedFileId}`);
        if (fileElement) {
            fileElement.scrollIntoView({ behavior: 'instant', inline: 'start' });
        }
    }, [props.selectedFileId])

    return (props.viewerTrigger) ? (
        
        <div className='post-image-viewer-overlay' onClick={(e) => e.stopPropagation()}>
            <div className='post-image-viewer-content' style={{
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
            }}>

                <div className='post-image-viewer-content-navbar-mobile'>
                    <img src={backBtn} alt='' style={{cursor: 'pointer', marginLeft: '18px'}} onClick={() => props.setViewerTrigger(false)}/>
                </div>

                <div style={{height: '1px', background: 'rgba(153, 153, 153, 0.30)', marginTop: '15px'}}></div>

                <div className='post-image-viewer-content-mobile-file-viewer'>
                    {postDataWithId.video_file_name_list.map((file, idx) => (
                        <React.Fragment key={file.file_name}>
                            <video controls id={`file-${idx}`}>
                                <source src={`https://www.dandycv.com/media/${file.file_name}`} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </React.Fragment>
                    ))}
                    {postDataWithId.image_file_name_list.map((file, idx) => (
                        <div className='post-image-viewer-content-mobile-file-viewer-wrapper'>
                            <img id={`file-${idx+postDataWithId.video_file_name_list.length}`} key={idx} src={`https://www.dandycv.com/media/${file.file_name}`} alt='' style={{pointerEvents: 'none'}}/>
                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', right: 0, bottom: 0, marginRight: '20px', marginBottom: '25px'}}>
                                <div style={{color: '#FFF', fontSize: '18px', marginRight: '10px'}}>
                                    {props.selectedFileId >= postDataWithId.video_file_name_list.length && (
                                        postDataWithId.image_file_name_list.length > 0 && (
                                            <>
                                                {postDataWithId.image_file_name_list[idx].start_verse}~{postDataWithId.image_file_name_list[idx].end_verse}
                                            </>
                                        )
                                    )}
                                </div>
                                <img src={lockImg} alt='' style={{width: '32px', height: '32px'}}/>
                                
                            </div>
                            
                        </div>
                        ))}
                </div>
            </div>
        </div>
    ) : ''
}

export default PostsImageMobileFileViewer
