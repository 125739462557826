import { 
    ADMIN_POST_UPLOAD_REQUEST,
    ADMIN_POST_UPLOAD_SUCCESS,
    ADMIN_POST_UPLOAD_FAIL,

    ADMIN_POST_EDIT_REQUEST,
    ADMIN_POST_EDIT_SUCCESS,
    ADMIN_POST_EDIT_FAIL,

    ADMIN_GENERATE_IMAGE_SUCCESS,
    ADMIN_GENERATE_IMAGE_REQUEST,
    ADMIN_GENERATE_IMAGE_FAIL,

    ADMIN_GET_ALL_POST_REQUEST,
    ADMIN_GET_ALL_POST_SUCCESS,
    ADMIN_GET_ALL_POST_FAIL
} from '../constants/adminConstants'

export const adminGetAllPostsReducers = (state = {}, action) => {
    switch (action.type) {
        case ADMIN_GET_ALL_POST_REQUEST:
            return { loading: true }

        case ADMIN_GET_ALL_POST_SUCCESS:
            return { loading: false, allAdminPostData: action.payload.data }

        case ADMIN_GET_ALL_POST_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const adminGenerateImageReducers = (state = {}, action) => {
    switch (action.type) {
        case ADMIN_GENERATE_IMAGE_REQUEST:
            return { loading: true }

        case ADMIN_GENERATE_IMAGE_SUCCESS:
            return { loading: false, generateImageData: action.payload }

        case ADMIN_GENERATE_IMAGE_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const adminPostUploadReducers = (state = {}, action) => {
    switch (action.type) {
        case ADMIN_POST_UPLOAD_REQUEST:
            return { loading: true }

        case ADMIN_POST_UPLOAD_SUCCESS:
            return { loading: false }

        case ADMIN_POST_UPLOAD_FAIL:
            return { loading: false, error: action.payload }

        case ADMIN_POST_EDIT_REQUEST:
            return { loading: true }

        case ADMIN_POST_EDIT_SUCCESS:
            return { loading: false }

        case ADMIN_POST_EDIT_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}