export const ADMIN_GENERATE_IMAGE_REQUEST = 'ADMIN_GENERATE_IMAGE_REQUEST'
export const ADMIN_GENERATE_IMAGE_SUCCESS = 'ADMIN_GENERATE_IMAGE_SUCCESS'
export const ADMIN_GENERATE_IMAGE_FAIL = 'ADMIN_GENERATE_IMAGE_FAIL'

export const ADMIN_POST_UPLOAD_REQUEST = 'ADMIN_POST_UPLOAD_REQUEST'
export const ADMIN_POST_UPLOAD_SUCCESS = 'ADMIN_POST_UPLOAD_SUCCESS'
export const ADMIN_POST_UPLOAD_FAIL = 'ADMIN_POST_UPLOAD_FAIL'

export const ADMIN_POST_EDIT_REQUEST = 'ADMIN_POST_EDIT_REQUEST'
export const ADMIN_POST_EDIT_SUCCESS = 'ADMIN_POST_EDIT_SUCCESS'
export const ADMIN_POST_EDIT_FAIL = 'ADMIN_POST_EDIT_FAIL'

export const ADMIN_GET_ALL_POST_REQUEST = 'ADMIN_GET_ALL_POST_REQUEST'
export const ADMIN_GET_ALL_POST_SUCCESS = 'ADMIN_GET_ALL_POST_SUCCESS'
export const ADMIN_GET_ALL_POST_FAIL = 'ADMIN_GET_ALL_POST_FAIL'