import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { img2ImgDelete } from '../actions/img2imgActions'
import { txt2ImgDelete } from '../actions/txt2imgActions'
import { upscalingDelete } from '../actions/upscalingActions'
import { multiPromptingDelete } from '../actions/multiPromptingActions'

import closeBtnImg from '../images/close-btn.svg'

function DeletePopup(props) {
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const dispatch = useDispatch()

    const handleDeleteAPI = async () => {
        const url = props.img2img ? 'img_2_img' : (
            props.upscaling ? 'upscaling' : (
              props.multiPrompting ? 'multi_prompting' : 'txt_2_img'
          )
        )
        const api_request = (props.data[props.selectedPromptId].image_name_list.length === 1 || props.deleteTarget === 0) ? (
            `${url}/delete_prompt/${props.data[props.selectedPromptId].id}`
        ) : (
            `${url}/delete_image/${props.data[props.selectedPromptId].id}`
        )

        if (props.img2img) {
          dispatch(img2ImgDelete(
            api_request,
            props.data[props.selectedPromptId].image_name_list[props.selectedImageId].id,
            userInfo.username,
            props.paginatorValue,
            props.itemsPerPage
          ))
        } else if (props.upscaling) {
          dispatch(upscalingDelete(
            api_request,
            props.data[props.selectedPromptId].image_name_list[props.selectedImageId].id,
            userInfo.username,
            props.paginatorValue,
            props.itemsPerPage
          ))
        } else if (props.multiPrompting) {
          dispatch(multiPromptingDelete(
            userInfo.token,
            api_request,
            props.data[props.selectedPromptId].image_name_list[props.selectedImageId].id,
            props.paginatorValue,
            props.itemsPerPage
          ))
        }
        else {
          dispatch(txt2ImgDelete(
            api_request,
            props.data[props.selectedPromptId].image_name_list[props.selectedImageId].id,
            userInfo.username,
            props.paginatorValue,
            props.itemsPerPage
          ))
        }
        

        props.setTrigger(false)
        props.setViewPopup(false);
        
    }

    return (props.trigger) ? (
        <div className='popup-color-picker-overlay' onClick={() => props.setTrigger(false)}>
          <div className='popup-deleter-content' onClick={(e) => e.stopPropagation()}>
            <div className='popup-downloader-header'>
              <div className="popup-downloader-title">Delete</div>

              <img src={closeBtnImg} alt='' style={{cursor: 'pointer'}} onClick={() => props.setTrigger(false)}/>
            </div>
            <div className='hr' style={{marginLeft: '16px', marginRight: '16px'}} />

            <div className='popup-deleter-content-txt'>
              Are you sure you want to delete the {(props.deleteTarget === 0 ? 'entire' : 'selected')} image?
            </div>

            <div className='popup-deleter-buttons'>
              <button style={{border: '1px solid #B4307D', color: '#B4307D', marginRight: '10px'}} onClick={() => props.setTrigger(false)}>
                Cancel
              </button>
  
              <button onClick={() => handleDeleteAPI()}>
                OK
              </button>
            </div>  
          </div>
        </div>
    ) : "";
}

export default DeletePopup
