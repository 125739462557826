export const IMG_2_IMG_GET_REQUEST = 'IMG_2_IMG_GET_REQUEST'
export const IMG_2_IMG_GET_SUCCESS = 'IMG_2_IMG_GET_SUCCESS'
export const IMG_2_IMG_GET_FAIL = 'IMG_2_IMG_GET_FAIL'

export const IMG_2_IMG_POST_REQUEST = 'IMG_2_IMG_POST_REQUEST'
export const IMG_2_IMG_POST_SUCCESS = 'IMG_2_IMG_POST_SUCCESS'
export const IMG_2_IMG_POST_FAIL = 'IMG_2_IMG_POST_FAIL'

export const IMG_2_IMG_DELETE_REQUEST = 'IMG_2_IMG_DELETE_REQUEST'
export const IMG_2_IMG_DELETE_SUCCESS = 'IMG_2_IMG_DELETE_SUCCESS'
export const IMG_2_IMG_DELETE_FAIL = 'IMG_2_IMG_DELETE_FAIL'