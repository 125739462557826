import {
    POST_GET_REQUEST,
    POST_GET_SUCCESS,
    POST_GET_FAIL,

    POST_ID_GET_REQUEST,
    POST_ID_GET_SUCCESS,
    POST_ID_GET_FAIL,    
    POST_SEARCH_REQUEST,
    POST_SEARCH_SUCCESS,
    POST_SEARCH_FAIL
} from '../constants/postConstants'

export const getPostsDataReducers = (state = { postData: [] }, action) => {
    switch (action.type) {
        case POST_GET_REQUEST:
            return { loading: true }

        case POST_GET_SUCCESS:
            return { loading: false, postData: action.payload }

        case POST_GET_FAIL:
            return { loading: false, error: action.payload }

        case POST_SEARCH_REQUEST:
            return { loading: true }
        
        case POST_SEARCH_SUCCESS:
            return { loading: false, postData: action.payload}
        
        case POST_SEARCH_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}

export const getPostWithIdDataReducers = (state = { postDataWithId: [] }, action) => {
    switch (action.type) {
        case POST_ID_GET_REQUEST:
            return { loading: true }

        case POST_ID_GET_SUCCESS:
            return { loading: false, postDataWithId: action.payload.data }

        case POST_ID_GET_FAIL:
            return { loading: false, error: action.payload }

        default:
            return state
    }
}
